import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IContact } from '@calaosoft/osapp/model/contacts/IContact';
import { ContactsService } from '@calaosoft/osapp/services/contacts.service';

@Injectable()
export class CanEditContactGuard {

	//#region METHODS

	constructor(private readonly isvcContacts: ContactsService) { }

	public canActivate(poNext: ActivatedRouteSnapshot): boolean {
		return this.isvcContacts.checkEditPermission({ _id: poNext.params.contactId } as IContact);
	}

	//#endregion

}
