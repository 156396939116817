export enum EFileError {

	directoryAlreadyExist = "directoryAlreadyExist",
	directoryNotFound = "directoryNotFound",
	fileAlreadyExist = "fileAlreadyExist",
	fileNotFound = "fileNotFound",
	other = "other",
	copyTo = "copyTo",
	remove = "remove",
	noFileErrorCode = "noFileErrorCode"
}