<div class="container {{(observableIsLoading.value$ | async) ? 'loading-container':''}}">
	<ng-container *ngIf="!(observableIsLoading.value$ | async); else loading">
		<ng-container *ngIf="!(observableIsSubscribe.value$ | async); else unsubscribe">
			<div>
				<h2>Connecter ma boîte mail</h2>
				<p>
					Voulez-vous autoriser CalaoTrade à analyser vos emails et à importer ceux concernant vos clients dans les
					messages de l'application ?
				</p>
			</div>

			<div class="buttons">
				<ion-button fill="clear" color="medium" shape="round" (click)="onStopButtonClick()">
					Annuler
				</ion-button>
				<ion-button class="validate-btn" color="primary" shape="round" (click)="onAuthorizeButtonClickAsync()">
					Ok
				</ion-button>
			</div>
		</ng-container>
	</ng-container>
</div>

<ng-template #unsubscribe>
	<div>
		<h2>Déconnecter ma boîte mail</h2>
		<p>
			Vous avez autorisé CalaoTrade à analyser vos emails.
			<br> Mettre fin à cet abonnement et révoquer cette autorisation ?
		</p>
	</div>
	<div class="buttons">
		<ion-button fill="clear" color="medium" shape="round" (click)="onStopButtonClick()">
			Annuler
		</ion-button>
		<ion-button class="validate-btn" color="primary" shape="round" (click)="onCancelAuthorizeButtonClick()">
			Ok
		</ion-button>
	</div>
</ng-template>

<ng-template #loading>
	<div>
		<ion-spinner color="primary"></ion-spinner>
	</div>
</ng-template>