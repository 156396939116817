import "@angular/compiler";
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Route, Routes } from "@angular/router";
import { entitiesSharedRoute } from "@calaosoft/osapp/modules/entities/entities-routing.module";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppModule } from './app/app.module';
import { activitySharedRoute } from "./app/tab-bar/activity/activity-shared-routing.module";
import { businessesSharedRoute } from "./app/tab-bar/businesses/businesses-shared-routing.module";
import { contactsSharedRoute } from "./app/tab-bar/contacts/contacts-shared-routing.module";
import { conversationsSharedRoute } from "./app/tab-bar/conversations/conversations-shared-routing.module";
import { eventDetailSharedRoute } from "./app/tab-bar/events/event-detail-routing.module";
import { lateSharedRoute } from "./app/tab-bar/late/late-routing.module";
import { reminderDetailSharedRoute } from "./app/tab-bar/reminders/reminders-detail-routing.module";
import { sitesSharedRoute } from "./app/tab-bar/sites/sites-shared-routing.module";
import { taskDetailSharedRoute } from "./app/tab-bar/tasks/task-detail-routing.module";
import { todoSharedRoute } from "./app/tab-bar/todo/todo-routing.module";
import { environment } from './environments/environment';

defineCustomElements(window);

if (environment.production) {
	enableProdMode();
	console.debug = () => { };
}

platformBrowserDynamic().bootstrapModule(AppModule);

export function getSharedRoutes(psBaseRouteToExclude: string): Routes {
	return [
		businessesSharedRoute,
		contactsSharedRoute,
		eventDetailSharedRoute,
		taskDetailSharedRoute,
		reminderDetailSharedRoute,
		entitiesSharedRoute,
		conversationsSharedRoute,
		sitesSharedRoute,
		lateSharedRoute,
		todoSharedRoute,
		conversationsSharedRoute,
		activitySharedRoute
	].filter((poRoute: Route) => poRoute.path !== psBaseRouteToExclude);
}