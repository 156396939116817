import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { firstValueFrom, switchMap } from 'rxjs';
import { IEntityAction } from '../../../../../entities/models/ientity-action';
import { EntitiesService } from '../../../../../entities/services/entities.service';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { PermissionsService } from '../../../../../permissions/services/permissions.service';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IEntityActionButtonParams } from './models/ientity-action-button-params';

@Component({
	selector: 'calao-entity-action-button',
	templateUrl: './entity-action-button.component.html',
	styleUrls: ['./entity-action-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityActionButtonComponent extends FieldBase<void> {

	//#region PROPERTIES

	public readonly observableAction = new ObservableProperty<IEntityAction>();
	public readonly observableHasPermission = new ObservableProperty<boolean>(false);
	public readonly observableIcon = new ObservableProperty<string>();
	public readonly observableLabel = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private isvcPermissions: PermissionsService,
		private isvcEntities: EntitiesService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		const loParams: IEntityActionButtonParams = this.to.data;
		const loAction: IEntityAction = loParams.action
		if (loAction) {
			this.observableAction.value = loAction;
			this.observableIcon.value = loAction.icon;
			this.observableLabel.value = loAction.label;
			this.observableHasPermission.value = this.isvcPermissions.evaluatePermission(loAction.permissions.scope, loAction.permissions.permissions);
		}
	}

	public execActionAsync(): Promise<boolean> {
		return firstValueFrom(this.observableAction.value$.pipe(
			switchMap((poAction: IEntityAction) => this.isvcEntities.execEntityAction$(poAction))
		));
	}

	//#endregion METHODS

}
