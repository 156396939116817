import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InAppBrowser, UrlEvent } from '@capgo/inappbrowser';
import { catchError, delay, map, mergeMap, Observable, of, Subject } from 'rxjs';
import { ArrayHelper } from '../helpers/arrayHelper';
import { DateHelper } from '../helpers/dateHelper';
import { NumberHelper } from '../helpers/numberHelper';
import { UserHelper } from '../helpers/user.helper';
import { ConfigData } from '../model/config/ConfigData';
import { AuthenticatedRequestOptionBuilder } from '../modules/api/models/authenticated-request-option-builder';
import { DestroyableServiceBase } from '../modules/services/models/destroyable-service-base';
import { secure } from '../modules/utils/rxjs/operators/secure';
import { PlatformService } from './platform.service';
import { WorkspaceService } from './workspace.service';

interface InteropCache {
	[key: number]: InteropInfo;
}

interface InteropInfo {
	scopes: string[];
	systemId: string;
	redirectUri: string;
}

interface AuthInfo {
	mailbox?: {
		scopes: string[];
	};
	authorization: {
		url: string;
		scopes: string[];
	};
}

interface SubscriptionsInfo {
	[systemType: string]: {
		ids: string[];
	};
}

@Injectable({ providedIn: "root" })
export class InteropService extends DestroyableServiceBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "INTER.S::";
	private static readonly C_LOCAL_STORAGE_CACHE_KEY = "InteropServiceCache";

	private moCache: InteropCache = this.getCacheFromLocalStorage();

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcWorkspace: WorkspaceService,
		private readonly ioHttpClient: HttpClient,
		private readonly isvcPlatform: PlatformService
	) {
		super();
	}

	private getCacheFromLocalStorage(): InteropCache {
		let loCache: InteropCache;
		try {
			loCache = JSON.parse(localStorage.getItem(InteropService.C_LOCAL_STORAGE_CACHE_KEY)) ?? {};
		} catch {
			loCache = {};
		}
		const lnDateMoins1H = DateHelper.addHours(new Date, -1).getTime();
		Object.keys(loCache).forEach((lsKey: string) => {
			if (!NumberHelper.isStringNumber(lsKey) || +lsKey < lnDateMoins1H) {
				delete loCache[lsKey];
			}
		});
		localStorage.setItem(InteropService.C_LOCAL_STORAGE_CACHE_KEY, JSON.stringify(loCache));
		return loCache;
	}

	private addCacheNewAuth(paScopes: string[], psActualUrl: string, systemId: string): number {
		const lnDate = new Date().getTime();
		this.moCache[lnDate] = {
			scopes: paScopes,
			systemId: systemId,
			redirectUri: psActualUrl
		};
		localStorage.setItem(InteropService.C_LOCAL_STORAGE_CACHE_KEY, JSON.stringify(this.moCache));
		return lnDate;
	}

	private getRedirectUrl(): string {
		if (this.isvcPlatform.isMobileApp) {
			return "http://localhost";
		}
		return window.location.origin + window.location.pathname;
	}

	//todo : Migrer le code dans le BrowserService.
	private openWebViewForAuth$(psUrl: string, psRedirectUrl: string): Subject<boolean> {
		const loSubject = new Subject<boolean>();
		InAppBrowser.openWebView({ url: psUrl, title: "Authentification externe" });
		InAppBrowser.removeAllListeners();

		InAppBrowser.addListener("urlChangeEvent", (poEvent: UrlEvent) => {
			if (poEvent.url.startsWith(psRedirectUrl)) {
				InAppBrowser.removeAllListeners();
				InAppBrowser.close();

				const loUrlSearchParams: URLSearchParams = new URL(poEvent.url).searchParams;
				const lsCode: string | null = loUrlSearchParams.get("code");
				const lsState: string | null = loUrlSearchParams.get("state");

				if (!lsCode || !lsState) {
					console.error(`${InteropService.C_LOG_ID} Can not get 'state' or 'code' in webview's return authorization with url '${poEvent.url}'.`);
					loSubject.next(false);
					loSubject.complete();
				}
				else
					this.createAuth$(lsCode, lsState).subscribe(loSubject);
			}
		});

		InAppBrowser.addListener("closeEvent", () => {
			InAppBrowser.removeAllListeners();
			loSubject.next(false);
			loSubject.complete();
		});

		return loSubject;
	}

	public hasSubscribe$(psSystem: string, psSystemType: string): Observable<boolean> {
		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}security/users/${UserHelper.getUserGuid(UserHelper.getUserId())}/systems/${psSystem}/subscriptions?workspaceId=${this.isvcWorkspace.getCurrentWorkspaceId()}`;
		return this.ioHttpClient.get<SubscriptionsInfo>(lsApiUrl, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).pipe(
			map((poSubscriptionsInfo: SubscriptionsInfo) => ArrayHelper.hasElements(poSubscriptionsInfo[psSystemType]?.ids))
		);
	}

	public getAuthInfo$(psSystem: string): Observable<AuthInfo> {
		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}security/users/${UserHelper.getUserGuid(UserHelper.getUserId())}/systems/${psSystem}/authorizations?workspaceId=${this.isvcWorkspace.getCurrentWorkspaceId()}`;
		return this.ioHttpClient.get<AuthInfo>(lsApiUrl, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions());
	}

	public demandAuth$(psSystem: string, paSystemType: string[]): Observable<boolean> {
		return this.getAuthInfo$(psSystem).pipe(
			catchError((poError: Error) => {
				console.error(`${InteropService.C_LOG_ID} DemandAuth à échoué.`, poError);
				return of(false);
			}),
			mergeMap((poAuthInfo: AuthInfo) => {
				let laScopesNeeded: string[] = [];

				paSystemType.forEach((psSystemType: string) => {
					if (Object.keys(poAuthInfo).includes(psSystemType)) {
						laScopesNeeded = laScopesNeeded.concat(poAuthInfo[psSystemType].scopes ?? []);
					} else {
						console.error(`${InteropService.C_LOG_ID} ${psSystemType} inexistant pour le systeme ${psSystem}. Impossible de récupérer les scopes nécessaires.`);
					}
				});
				laScopesNeeded = ArrayHelper.unique(laScopesNeeded);

				if (laScopesNeeded.every((psScope) => poAuthInfo.authorization.scopes.includes(psScope))) {
					console.warn(`${InteropService.C_LOG_ID} Tout les scopes voulu sont déjà autorisé pour le systeme ${psSystem}.`);
					//TODO Gerer cas quand on aura séparé les appels n8n entre l'auth et les subscriptions.
				}

				const lsRedirectUrl = this.getRedirectUrl();
				const lsState = this.addCacheNewAuth(laScopesNeeded, lsRedirectUrl, psSystem);
				const lsScopesEncoded = encodeURIComponent(laScopesNeeded.join(" "));
				const lsUrl = `${poAuthInfo.authorization.url}&redirect_uri=${lsRedirectUrl}&scope=${lsScopesEncoded}&state=${lsState}`;

				if (this.isvcPlatform.isMobileApp) {
					return this.openWebViewForAuth$(lsUrl, lsRedirectUrl);
				}
				window.location.assign(lsUrl);
				return of(false).pipe(delay(500));
			}),
		);
	}

	public createAuth$(psCode: string, psKey: string): Observable<boolean> {
		const loInteropInfo: InteropInfo = this.moCache[psKey];
		if (!loInteropInfo) {
			console.error(`${InteropService.C_LOG_ID} ${psKey} impossible à retrouver dans l'InteropCache.`);
			return of(false);
		}

		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}n8n/systems/${loInteropInfo.systemId}/subscribe`;
		const loBody = {
			...loInteropInfo,
			code: psCode,
			workspaceId: this.isvcWorkspace.getCurrentWorkspaceId(),
			userId: UserHelper.getUserGuid(UserHelper.getUserId())
		};

		return this.ioHttpClient.post(lsApiUrl, loBody, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).pipe(
			map(_ => true),
			catchError((poError: Error) => {
				console.error(`${InteropService.C_LOG_ID} Envoi Auth à n8n à échoué.`, poError);
				return of(false);
			}),
			secure(this)
		);
	}

	public deleteAuth$(psSystem: string): Observable<boolean> {
		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}n8n/systems/${psSystem}/unsubscribe`;
		const loBody = {
			workspaceId: this.isvcWorkspace.getCurrentWorkspaceId(),
			userId: UserHelper.getUserGuid(UserHelper.getUserId())
		};

		return this.ioHttpClient.post(lsApiUrl, loBody, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).pipe(
			map(_ => true),
			catchError((poError: Error) => {
				console.error(`${InteropService.C_LOG_ID} Envoi delete Auth à n8n à échoué.`, poError);
				return of(false);
			})
		);
	}

	//#endregion

}