import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, defer, of } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { UserHelper } from '../../../../helpers/user.helper';
import { IPopoverItemParams } from '../../../../model/popover/IPopoverItemParams';
import { PopoverService } from '../../../../services/popover.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { BaseEventOccurrence } from '../../models/base-event-occurrence';
import { EEventParticipationStatus } from '../../models/eevent-participation-status';
import { IEventParticipantStatus } from '../../models/ievent-participant-status';
import { IItemDescriptionLine } from '../../models/iitem-description-line';
import { ILinkedEntityIcon } from '../../models/ilinked-entity-icon';
import { CalendarEventsParticipationService } from '../../services/calendar-events-participation.service';
import { CalendarEventsService } from '../../services/calendar-events.service';

@Component({
	selector: 'calao-event-item',
	templateUrl: './event-item.component.html',
	styleUrls: ['./event-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventItemComponent extends DestroyableComponentBase {

	//#region FIELDS

	/** Événement lors clic sur l'édition. */
	@Output("onEditClicked") private readonly moEditClickedEvent = new EventEmitter<BaseEventOccurrence>();
	public readonly moObservablePopOverItemsParams = new ObservableArray<IPopoverItemParams>(this.getPopOverItemsParams());

	//#endregion

	//#region PROPERTIES

	/** Evènement à afficher */
	@Input() public event?: BaseEventOccurrence;
	@ObserveProperty<EventItemComponent>({ sourcePropertyKey: "event" })
	/** Evènement à afficher */
	public readonly observableEvent = new ObservableProperty<BaseEventOccurrence>();

	/** Couleur d'arrière plan de l'item */
	@Input() public contentColor?: string;
	@ObserveProperty<EventItemComponent>({ sourcePropertyKey: "contentColor" })
	/** Couleur d'arrière plan de l'item */
	public readonly observableContentColor = new ObservableProperty<string>();

	/** Couleur de la zone gauche de l'item */
	@Input() public startColor?: string;
	@ObserveProperty<EventItemComponent>({ sourcePropertyKey: "startColor" })
	/** Couleur de la zone gauche de l'item */
	public readonly observableStartColor = new ObservableProperty<string>();

	/** Icône du titre. */
	@Input() public titleIcon?: string;
	@ObserveProperty<EventItemComponent>({ sourcePropertyKey: "titleIcon" })
	public readonly observableTitleIcon = new ObservableProperty<string>();

	/** `true` pour afficher le lieu, sinon `false`. */
	@Input() public displayPlace?: boolean;
	@ObserveProperty<EventItemComponent>({ sourcePropertyKey: "displayPlace" })
	public readonly observableDisplayPlace = new ObservableProperty<boolean>(true);

	@Input() public lastDescriptionLine?: IItemDescriptionLine;
	@ObserveProperty<EventItemComponent>({ sourcePropertyKey: "lastDescriptionLine" })
	public readonly observableLastDescriptionLine = new ObservableProperty<IItemDescriptionLine>();

	@Input() public linkedEntitiesIcons?: ILinkedEntityIcon[];
	@ObserveProperty<EventItemComponent>({ sourcePropertyKey: "linkedEntitiesIcons" })
	public readonly observableLinkedEntitiesIcons = new ObservableProperty<ILinkedEntityIcon[]>();

	/** Icône associée à l'évènement */
	@Input() public icon$?: Observable<string>;

	/** Label à afficher sous l'icône */
	@Input() public typeLabel$?: Observable<string>;

	public readonly hasOptions$: Observable<boolean> = this.observableEvent.value$.pipe(
		switchMap((poEventOccurrence?: BaseEventOccurrence) => {
			if (poEventOccurrence) {
				return poEventOccurrence.canBeEdited$.pipe(
					map((pbCanBeEdited: boolean) => poEventOccurrence.canBeDeleted || pbCanBeEdited)
				);
			}
			else
				return of(false);
		}),
		secure(this)
	);

	/** `true` si l'utilisateur doit envoyer une réponse pour sa participation à l'évènement, sinon `false`. Est à `false` par défaut. */
	public readonly observableHasToRequestParticipation = new ObservableProperty<boolean>(false);

	//#endregion

	//#region METHODS

	public constructor(
		private readonly isvcCalendarEvent: CalendarEventsService,
		private readonly isvcParticipation: CalendarEventsParticipationService,
		private readonly isvcPopover: PopoverService
	) {
		super();
		this.observableHasToRequestParticipation.bind(this.observableEvent.value$.pipe(
			switchMap((poEvent: BaseEventOccurrence) => poEvent.hasToRequestParticipation$)
		), this);
	}

	@Queue<EventItemComponent, Parameters<EventItemComponent["deleteEvent$"]>, ReturnType<EventItemComponent["deleteEvent$"]>>({
		excludePendings: true
	})
	private deleteEvent$(): Observable<boolean> {
		return defer(() => this.isvcCalendarEvent.askForDeletetionAsync(this.event.event)).pipe(
			filter((pbDelete: boolean) => pbDelete),
			mergeMap(() => this.isvcCalendarEvent.deleteEventAsync(this.event.event))
		);
	}

	public async confirmParticipationAsync(poEvent: Event): Promise<void> {
		poEvent.stopPropagation();
		return this.isvcParticipation.acceptAsync(this.observableEvent.value)
			.then(() => {
				this.observableHasToRequestParticipation.value = this.getHasToRequestparticipation();
			});
	}

	public async rejectParticipationAsync(poEvent: Event): Promise<void> {
		poEvent.stopPropagation();
		return this.isvcParticipation.rejectAsync(this.observableEvent.value)
			.then(() => {
				this.observableHasToRequestParticipation.value = this.getHasToRequestparticipation();
			});
	}

	private getHasToRequestparticipation(): boolean {
		return !this.event.participantsStatus.some((poEventParticipationStatus: IEventParticipantStatus) => poEventParticipationStatus.participantId === UserHelper.getUserContactId() &&
			(poEventParticipationStatus.status === EEventParticipationStatus.accepted || poEventParticipationStatus.status === EEventParticipationStatus.denied));
	}

	public async presentPopOverAsync(poEvent: MouseEvent): Promise<void> {
		this.presentPopOver$(poEvent).subscribe();
	}

	@Queue<EventItemComponent, Parameters<EventItemComponent["presentPopOver$"]>, ReturnType<EventItemComponent["presentPopOver$"]>>({
		excludePendings: true
	})
	private presentPopOver$(poEvent: MouseEvent): Observable<HTMLIonPopoverElement> {
		return defer(() => this.isvcPopover.showPopoverAsync(this.moObservablePopOverItemsParams, poEvent));
	}

	private getPopOverItemsParams(): IPopoverItemParams[] {
		return [
			{
				action: () => this.navigateToEdit$(),
				id: "creation",
				color: "primary",
				icon: "create",
				title: "Éditer"
			},
			{
				action: () => this.onDeleteClicked$(),
				id: "deletion",
				color: "danger",
				icon: "trash",
				title: "Supprimer définitivement"
			}
		];
	}

	private navigateToEdit$(): Observable<boolean> {
		if (this.observableEvent.value) {
			this.moEditClickedEvent.emit(this.event)
			return of(true);
		}
		return of(false);
	}

	private onDeleteClicked$(): Observable<boolean> {
		return this.observableEvent.value ? this.deleteEvent$() : of(false);
	}

	//#endregion

}
