import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { ComponentBase } from "../../../helpers/ComponentBase";
import { EConversationType } from "../../../model/conversation/e-conversation-type";

@Component({
	selector: "calao-conversation-sub-type-toggle",
	templateUrl: './conversation-type-toggle.component.html',
	styleUrls: ['./conversation-type-toggle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationTypeToggleComponent extends ComponentBase {

	//#region FIELDS

	@Input() public isFill? : boolean;
	@Input() public conversationType? : EConversationType;

	//#endregion

	//#region METHODS

	constructor(
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	//#endregion

}