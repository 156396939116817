import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

const route: Route = {
	path: "reminders",
	children: [
		{
			path: ':reminderGuid/edit',
			loadChildren: () => import('./edit-reminder/edit-reminder.module').then(poModule => poModule.EditReminderPageModule)
		},
		{
			path: ':reminderGuid/occurrences/:occurrenceDate',
			loadChildren: () => import('./reminder-occurrence/reminder-occurrence.module').then(m => m.ReminderOccurrencePageModule)
		},
		{
			path: ':reminderGuid/occurrences/:occurrenceDate/edit',
			loadChildren: () => import('./reminder-occurrence-edit/reminder-occurrence-edit.module').then(m => m.ReminderOccurrenceEditPageModule)
		}
	]
};

@NgModule({
	imports: [RouterModule.forChild([route])],
	exports: [RouterModule]
})
export class ReminderDetailRoutingModule { }

export { route as reminderDetailSharedRoute };
