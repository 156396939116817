import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from '../../guards/authenticated.guard';

const route: Route = {
	path: "entities",
	children: [
		{
			path: ':entityDescGuid',
			children: [
				{
					path: 'new',
					loadChildren: () => import('./pages/entities/entity-edit/entity-edit.module').then(m => m.EntityEditPageModule),
					canActivate: [AuthenticatedGuard]
				},
				{
					path: ':entityGuid',
					loadChildren: () => import('./pages/entities/entity/entity.module').then(m => m.EntityPageModule),
					canActivate: [AuthenticatedGuard]
				},
				{
					path: ':entityGuid/edit',
					loadChildren: () => import('./pages/entities/entity-edit/entity-edit.module').then(m => m.EntityEditPageModule),
					canActivate: [AuthenticatedGuard]
				}
			]
		}
	]
};

@NgModule({
	imports: [RouterModule.forChild([route])],
	exports: [RouterModule],
})
export class EntitiesRoutingModule { }

export { route as entitiesSharedRoute };
