import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { ISliderFieldParams } from '../../../../model/forms/ISliderFieldParams';
import { FormsService } from '../../../../services/forms.service';

/** Champs contenant un slider. */
@Component({
	selector: 'deprecated-calao-slider-field',
	templateUrl: './sliderField.component.html',
	styleUrls: ['./sliderField.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderFieldComponent extends FieldBase implements OnInit { //todo : typer + params.

	/** Paramètres possibles pour le composant de formulaire Slider. */
	public params: ISliderFieldParams;

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	/** Endroit où initialiser le composant après sa création. */
	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.props.data;

		// Initialisation de la valeur de retour du slider (à la valeur minimale, soit 0 par défaut)
		if (!this.fieldValue)
			this.fieldValue = this.params.minValue;
	}

	public onModelChanged(poEvent: Event): void {
		const lnValue: number = (poEvent as CustomEvent).detail.value;
		if (lnValue !== this.fieldValue) {
			this.fieldValue = lnValue;
			this.markAsDirty();
		}
	}

	//#endregion
}