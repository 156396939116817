import { ObjectHelper } from "../../../../helpers/objectHelper";

export class FilesystemErrorBase extends Error {

	//#region PROPERTIES

	public readonly error: any;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poError: any, psMessage: string) {
		super(psMessage);
		// Nécessaire pour que le 'instanceof FilesystemErrorBase' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemErrorBase);

		this.error = poError;
	}

	//#endregion METHODS

}