import { ArrayHelper } from "@calaosoft/osapp/helpers/arrayHelper";
import { ContactHelper } from "@calaosoft/osapp/helpers/contactHelper";
import { EnumHelper } from "@calaosoft/osapp/helpers/enumHelper";
import { IdHelper } from "@calaosoft/osapp/helpers/idHelper";
import { Contact } from "@calaosoft/osapp/modules/contacts/models/contact";
import { Entity } from "@calaosoft/osapp/modules/entities/models/entity";
import { ObserveArray } from "@calaosoft/osapp/modules/observable/decorators/observe-array.decorator";
import { ObserveProperty } from "@calaosoft/osapp/modules/observable/decorators/observe-property.decorator";
import { ObservableArray } from "@calaosoft/osapp/modules/observable/models/observable-array";
import { ObservableProperty } from "@calaosoft/osapp/modules/observable/models/observable-property";
import { ModelMatch } from "@calaosoft/osapp/modules/utils/models/decorators/model-match.decorator";
import { Exclude } from "class-transformer";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { C_PREFIX_BUSINESS } from "../../../app/app.constants";
import { EBusinessRelationship } from "./ebusiness-relationship";
import { EBusinessState } from "./ebusiness-state";
import { EProspectState } from "./eprospect-state";
import { ETranslatedBusinessState } from "./etranslated-business-state";
import { ETranslatedCustomerRelationship } from "./etranslated-customer-relationship";
import { ETranslatedCustomerTag } from "./etranslated-customer-tag";
import { ETranslatedProspectState } from "./etranslated-prospect-state";
import { IBusiness } from "./ibusiness";

@ModelMatch((poBusiness?: IBusiness) => IdHelper.hasPrefixId(poBusiness?._id ?? "", C_PREFIX_BUSINESS), Entity)
export class Business extends Contact implements IBusiness {

	//#region PROPERTIES

	/** @implements */
	public name: string;
	@ObserveProperty<Business>({ sourcePropertyKey: "name" })
	public readonly observableName = new ObservableProperty<string>(undefined, (psName: string) => this.lastName = psName);

	/** @implements */
	public tradingName?: string;

	/** @implements */
	public businessCode?: string;

	@Exclude()
	public override lastName?: string;

	public tags?: string[];

	public contacts?: string[];

	/** @implements */
	public CAHT?: number;

	public override get city(): string {
		if (!this.msCity)
			this.msCity = this.rawAddress;

		return this.msCity;
	}

	public override set city(psCity: string) {
		this.msCity = psCity;
	}

	/** @implements */
	public customerRelationship: EBusinessRelationship;
	@ObserveProperty<Business>({ sourcePropertyKey: "customerRelationship" })
	public readonly observableCustomerRelationship = new ObservableProperty<EBusinessRelationship>();

	/** @implements */
	public priority: number;
	@ObserveProperty<Business>({ sourcePropertyKey: "priority" })
	public readonly observablePriority = new ObservableProperty<number>();

	/** Doc propriété. */
	public linkedContacts: Contact[];
	@ObserveArray<Business>("linkedContacts")
	public readonly observableLinkedContacts = new ObservableArray<Contact>();

	/** @implements */
	public prospectState: EProspectState;
	@ObserveProperty<Business>({ sourcePropertyKey: "prospectState" })
	public readonly observableProspectState = new ObservableProperty<EProspectState>();

	/** @implements */
	public customerState: EBusinessState;
	@ObserveProperty<Business>({ sourcePropertyKey: "customerState" })
	public readonly observableCustomerState = new ObservableProperty<EBusinessState>();

	/** @implements */
	public isPro: boolean;
	@ObserveProperty<Business>({ sourcePropertyKey: "isPro" })
	public readonly observableIsPro = new ObservableProperty<boolean>();

	public get typesLabels(): string[] {
		return this.getTypesLabels();
	}

	public static get types(): string[] {
		const laCustomerRelationshipTypes: string[] = EnumHelper.getValues(ETranslatedCustomerRelationship);
		const laCustomerTagTypes: string[] = EnumHelper.getValues(ETranslatedCustomerTag);

		return [...laCustomerRelationshipTypes, ...laCustomerTagTypes];
	}

	/** @implements */
	public rawAddress: string;
	@ObserveProperty<Business>({ sourcePropertyKey: "rawAddress" })
	public readonly observableRawAddress = new ObservableProperty<string>();

	//#endregion

	//#region METHODS

	private getTypesLabels(): string[] {
		const laTypes: string[] = [];
		if (this.customerRelationship && this.customerRelationship !== EBusinessRelationship.none)
			laTypes.push(ETranslatedCustomerRelationship[this.customerRelationship]);
		if (ArrayHelper.hasElements(this.tags)) {
			this.tags.forEach((psTag: string) => {
				const lsTranslatedTag: string | undefined = ETranslatedCustomerTag[psTag];

				if (lsTranslatedTag)
					laTypes.push(lsTranslatedTag);
			});
		}

		return laTypes;
	}

	public getBusinessInfoLabel$(): Observable<string | undefined> {
		return this.observableCustomerRelationship.value$.pipe(
			switchMap((peCustomerRelationship: EBusinessRelationship) => {
				switch (peCustomerRelationship) {
					case EBusinessRelationship.customer:
						return this.observableCustomerState.value$.pipe(
							map((peBusinessState: EBusinessState) => `${ETranslatedCustomerRelationship[peCustomerRelationship]} - ${ETranslatedBusinessState[peBusinessState]}`)
						);
					case EBusinessRelationship.lead:
						return this.observableProspectState.value$.pipe(
							map((peProspectState: EProspectState) => `${ETranslatedCustomerRelationship[peCustomerRelationship]} - ${ETranslatedProspectState[peProspectState]}`)
						);
					default:
						return of(undefined);
				}
			})
		);
	}

	public getLinkedContactsLabel$(): Observable<string | undefined> {
		return this.observableLinkedContacts.changes$.pipe(
			map((paContacts: Contact[]) => ArrayHelper.dynamicSortMultiple(
				ArrayHelper.unique(paContacts),
				["lastName", "firstName"]
			)
				.map((poContact: Contact) => ContactHelper.getCompleteFormattedName(poContact))
				.join(", ")
			)
		);
	}

	//#endregion

}
