<div class="edit-content">
	<ion-item class="title-conversation">
		<ion-label class="color-label" position="stacked">Titre</ion-label>
		<ion-input [(ngModel)]="title" class="message-textarea" placeholder="Tapez votre message" type="text"
			[readonly]="isMailConversation"></ion-input>
	</ion-item>

	<div>
		<ion-item lines="none" class="ion-no-padding dis-flex participant-item">
			<ion-label class="participant-label">
				Participant(s)
			</ion-label>
			<ion-button slot="end" (click)="openContactSelector()" size="small" class="marginLeft10"
				*ngIf="!isMailConversation">
				<ion-icon slot="icon-only" name="add" class="button-small button-solid"></ion-icon>
			</ion-button>
		</ion-item>
		<div class="dis-flex">

			<!-- Affichage des groupes. -->
			<ng-container *ngIf="groups?.length > 0">
				<ion-label class="subelement-label">
					Groupe(s)
				</ion-label>
				<ion-list class="width100pc">
					<ion-item *ngFor="let group of groups">
						<ion-avatar class="roundavatar marge-right">
							<ion-icon name="group" class="round"></ion-icon>
						</ion-avatar>
						<ion-label class="label-ellipsis">{{ group.name }} </ion-label>
						<ion-button color="danger" slot="end" (click)="remove(group)" *ngIf="!isMailConversation">
							<ion-icon slot="icon-only" name="close"></ion-icon>
						</ion-button>
					</ion-item>
				</ion-list>
			</ng-container>

			<!-- Affichage des contacts. -->
			<ion-label class="subelement-label">
				Contact(s)
			</ion-label>
			<ion-list class="width100pc" *ngIf="uiContacts?.length > 0">
				<ion-item *ngFor="let contact of uiContacts">
					<ion-avatar class="roundavatar marge-right">
						<ion-icon *ngIf="contact.groups" name="group" class="round"></ion-icon>
						<ion-icon *ngIf="!contact.groups" name="person" class="round"></ion-icon>
					</ion-avatar>
					<div class="flx-grow1 dis-flex flex-row">
						<div class="flex-col">
							<ion-label class="label-ellipsis">{{ contact.label }}</ion-label>
							<ion-label *ngIf="contact.groups" class="label-ellipsis groups-label">
								{{ contact.groups }}
							</ion-label>
						</div>
					</div>
					<ion-button [disabled]="contact.groups || contact._id === userContactId" color="danger" slot="end"
						(click)="remove(contact)" *ngIf="!isMailConversation">
						<ion-icon slot="icon-only" name="close"></ion-icon>
					</ion-button>
				</ion-item>
			</ion-list>

		</div>
	</div>
</div>