import { AfterContentChecked, Directive, ElementRef, OnDestroy } from '@angular/core';
import { BrowserService } from '../../../modules/browser/services/browser.service';

type TOnClicked = (poEvent: MouseEvent) => void;

@Directive({
	selector: "[redirectLinks]"
})
export class RedirectLinksDirective implements AfterContentChecked, OnDestroy {

	//#region FIELDS

	private static readonly C_CLICK_EVENT = "click";

	private readonly moOnClickedFunctionByAnchor = new Map<HTMLAnchorElement, TOnClicked>();

	private msLastInterceptedText = ""; // Empêcher des modifications multiples inutiles.

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly ioElementRef: ElementRef<HTMLElement>,
		private readonly isvcBrowser: BrowserService
	) { }

	// On utilise pas ngOnInit car le texte peut être dynamique.
	public ngAfterContentChecked(): void {
		const lsCurrentText: string = this.ioElementRef.nativeElement.innerText;

		if (this.msLastInterceptedText !== lsCurrentText) {
			this.msLastInterceptedText = lsCurrentText;

			// Ajouter des gestionnaires d'événements aux balises "<a>".
			this.setListeners(this.ioElementRef.nativeElement.querySelectorAll("a"));
		}
	}

	public ngOnDestroy(): void {
		// Supprime les écouteurs pour éviter les fuites mémoires.
		this.removeListeners();
	}

	private setListeners(paAnchors: NodeListOf<HTMLAnchorElement>): void {
		for (let i = 0; i < paAnchors.length; i++) {
			const loAnchor: HTMLAnchorElement = paAnchors.item(i);

			// Il est nécessaire d'avoir une référence sur la fonction pour supprimer l'écouteur.
			const lfOnClickedFunction = (poEvent: MouseEvent) => {
				poEvent.preventDefault(); // Empêcher le comportement par défaut (rechargement).
				this.isvcBrowser.openUrlAsync(loAnchor.innerText);
			};

			this.moOnClickedFunctionByAnchor.set(loAnchor, lfOnClickedFunction);
			loAnchor.addEventListener(RedirectLinksDirective.C_CLICK_EVENT, lfOnClickedFunction);
		}
	}

	private removeListeners(): void {
		this.moOnClickedFunctionByAnchor.forEach((pfFunction: TOnClicked, poAnchor: HTMLAnchorElement) =>
			poAnchor.removeEventListener(RedirectLinksDirective.C_CLICK_EVENT, pfFunction)
		);
	}

	//#endregion METHODS

}