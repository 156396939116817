import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IPopoverItemParams } from '../../model/popover/IPopoverItemParams';
import { DestroyableComponentBase } from '../../modules/utils/components/destroyable-component-base';
import { Queue } from '../../modules/utils/queue/decorators/queue.decorator';

@Component({
	selector: 'osapp-popover',
	templateUrl: './popover.component.html',
	styleUrls: ['./popover.component.scss']
})
export class PopoverComponent extends DestroyableComponentBase implements OnInit, OnDestroy {

	//#region PROPERTIES

	/** Tableau des paramètres pour les items du composant à montrer. */
	@Input() public items: IPopoverItemParams[];
	/** Identifiant du composant pour fermer sa popover et pas une autre. */
	@Input() public componentId: string;

	//#endregion

	//#region METHODS

	constructor(protected readonly ioPopoverCtrl: PopoverController) {
		super();
	}

	public ngOnInit(): void {
		if (!this.items)
			this.items = [];
	}

	public override ngOnDestroy(): void {
		this.close()
			.then(_ => super.ngOnDestroy());
	}

	/** Ferme la popover. */
	public close(): Promise<boolean> {
		return this.ioPopoverCtrl.getTop()
			.then((poPopover: HTMLIonPopoverElement) => poPopover ? this.ioPopoverCtrl.dismiss(undefined, undefined, this.componentId) : false);
	}

	/** Exécute l'action associée à l'objet passé en paramètre.
	 * @param poItem Item dont on veut exécuter l'action.
	 */
	public execute(poItem: IPopoverItemParams): void {
		this.executeAction$(poItem).subscribe();
	}

	@Queue<PopoverComponent, Parameters<PopoverComponent["executeAction$"]>, ReturnType<PopoverComponent["executeAction$"]>>({
		excludePendings: true
	})
	private executeAction$(poItem: IPopoverItemParams): Observable<any> {
		this.close();
		return poItem.action()
			.pipe(catchError(poError => { console.error("POPOV.C:: Erreur lors de l'exécution de l'action de l'item", poItem, " :", poError); return throwError(() => poError); }));
	}

	//#endregion

}