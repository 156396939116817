import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, distinctUntilChanged, map, scan } from 'rxjs/operators';
import { EImageClass } from '../../../../components/image/model/EImageClass';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DmsMediaResult } from '../../model/dms-media-result';
import { ImageDmsIds } from '../../model/image-dms-ids';
import { DmsService } from '../../services/dms.service';

@Component({
	selector: 'calao-dms-image',
	templateUrl: './dms-image.component.html',
	styleUrls: ['./dms-image.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DmsImageComponent extends ComponentBase implements OnDestroy {

	//#region FIELDS

	/** Sujet indiquant si le loader doit être affiché. */
	private readonly moShowLoaderSubject = new BehaviorSubject<boolean>(true);

	//#endregion FIELDS

	//#region PROPERTIES

	private moImage$: Observable<string | Blob | undefined> = of(undefined);
	/** Flux contenant l'image à afficher. */
	public get image$(): Observable<string | Blob | undefined> { return this.moImage$; }

	/** Flux indiquant si le loader doit être affiché. */
	public get showLoader$(): Observable<boolean> { return this.moShowLoaderSubject.asObservable(); }

	@Input() public alt?: string;

	private meImgClass: EImageClass = EImageClass.fullwidthImg;
	/** Classe à appliquer sur l'élément, 'EImageClass.fullwidthImg' par défaut. */
	public get imgClass(): EImageClass { return this.meImgClass; }
	@Input() public set imgClass(peValue: EImageClass) {
		if (peValue !== this.meImgClass) {
			this.meImgClass = peValue;
			this.detectChanges();
		}
	}

	private moImageDmsIds?: ImageDmsIds;
	/** Identifiants des images à afficher. */
	@Input() public set imageDmsIds(poImageDmsIds: ImageDmsIds | undefined | null) {
		if (poImageDmsIds !== this.moImageDmsIds) {
			this.moImageDmsIds = poImageDmsIds ?? undefined; // On veut pas la valeur "null".
			this.moImage$ = this.getImage$();
		}
	}

	/** Couleur de l'arrière-plan de la zone de l'image. */
	@Input() public backgroundColor?: string;
	@ObserveProperty<DmsImageComponent>({ sourcePropertyKey: "backgroundColor" })
	public readonly obsBackgroundColor = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(private readonly isvcDms: DmsService, poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moShowLoaderSubject.complete();
	}

	private getImage$(): Observable<string | Blob | undefined> {
		return this.moImageDmsIds?.changes$.pipe(
			distinctUntilChanged((poOldvalue?: ImageDmsIds, poNextValue?: ImageDmsIds) =>
				!!poOldvalue && poOldvalue.equals(poNextValue)
			),
			concatMap((poImageIds: ImageDmsIds) => {
				return this.isvcDms.getPrioritizedFile(poImageIds.mainId, poImageIds.alternativeIds)
					.pipe(
						map((poDmsMediaResult: DmsMediaResult) => {
							if (poDmsMediaResult.isPrimary)	// Si on a l'image principale, on retire le loader.
								this.updateShowLoaderSubject(false);

							return poDmsMediaResult.data;
						})
					);
			}),
			scan((poCurrent: string | Blob, poNext: string | Blob | undefined) => poNext ?? poCurrent, undefined)
		) ?? of(undefined);
	}

	private updateShowLoaderSubject(pbValue: boolean): void {
		this.moShowLoaderSubject.next(pbValue);
	}

	//#endregion METHODS

}