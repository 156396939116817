import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ConversationHelper } from '../../../helpers/ConversationHelper';
import { LifeCycleObserverComponentBase } from '../../../helpers/LifeCycleObserverComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EApplicationEventType } from '../../../model/application/EApplicationEventType';
import { IApplicationEvent } from '../../../model/application/IApplicationEvent';
import { UserData } from '../../../model/application/UserData';
import { EBarElementDock } from '../../../model/barElement/EBarElementDock';
import { EBarElementPosition } from '../../../model/barElement/EBarElementPosition';
import { IBarElement } from '../../../model/barElement/IBarElement';
import { ConfigData } from '../../../model/config/ConfigData';
import { EContactsType } from '../../../model/contacts/EContactsType';
import { IContact } from '../../../model/contacts/IContact';
import { IGroup } from '../../../model/contacts/IGroup';
import { IGroupMember } from '../../../model/contacts/IGroupMember';
import { Group } from '../../../model/contacts/group';
import { IConversation } from '../../../model/conversation/IConversation';
import { IOpenConversationOptions } from '../../../model/conversation/IOpenConversationOptions';
import { IParticipant } from '../../../model/conversation/IParticipant';
import { EConversationType } from '../../../model/conversation/e-conversation-type';
import { ELifeCycleEvent } from '../../../model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '../../../model/lifeCycle/ILifeCycleEvent';
import { EContactsPickerSort } from '../../../modules/contacts/components/contacts-picker-modal/econtacts-picker-sort';
import { IContactsPickerParams } from '../../../modules/contacts/models/contacts-picker/icontacts-picker-params';
import { Conversation } from '../../../modules/conversations/model/conversation';
import { PageManagerService } from '../../../modules/routing/services/pageManager.service';
import { ContactNamePipe } from '../../../pipes/contactName.pipe';
import { ContactsService } from '../../../services/contacts.service';
import { ConversationService } from '../../../services/conversation.service';
import { GroupsService } from '../../../services/groups.service';
import { DynamicPageComponent } from '../../dynamicPage/dynamicPage.component';

interface IContactData {
	label: string,
	groups?: string,
	_id: string
}

@Component({
	selector: 'calao-conversation-edit',
	templateUrl: './conversationEdit.component.html',
	styleUrls: ['./conversationEdit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationEditComponent extends LifeCycleObserverComponentBase implements OnInit {

	//#region FIELDS
	private static readonly C_LOG_ID = "CONV.EDIT.C::";

	/** Tableau des contacts et groupes avant les modifications. */
	private maContactsAndGroupsBefore: Array<IContact | IGroup>;
	/** Conversation à modifier. */
	private moConversation: Conversation;
	/** Tableau des contacts et groupes sélectionnés comme participants pour la conversation. */
	private maSelectedParticipants: Array<IGroup | IContact>;
	/** Tableau des anciens contacts et groupes sélectionnés comme participants pour la conversation. */
	private maOldSelectedParticipants: Array<IGroup | IContact>;
	/** Titre de base de la conversation. */
	private msOldTitle: string;

	//#endregion

	//#region PROPERTIES

	/** Listes des contacts sélectionnés de la conversation. */
	public contacts: Array<IContact>;
	/** Liste des groupes sélectionnés de la conversation. */
	public groups: Array<Group>;
	/** Titre de la conversation. */
	public title?: string;
	/** Données utilisées pour afficher les informations des contacts dans l'UI. */
	public uiContacts: Array<IContactData>;
	/**	Identifiant du l'utilisateur courant. */
	public readonly userContactId: string = UserHelper.getUserContactId();

	private mbIsValidated = false;
	/** 'true' si le bouton de validation a été cliqué */
	public get isValidated(): boolean { return this.mbIsValidated; }

	public get canSave(): boolean {
		return ArrayHelper.hasElements(this.contacts) || ArrayHelper.hasElements(this.groups);
	}

	public get isMailConversation(): boolean {
		return this.moConversation.conversationType == EConversationType.mail;
	}

	//#endregion

	//#region METHODS

	constructor(
		private isvcContacts: ContactsService,
		private isvcGroups: GroupsService,
		/** Service de conversation */
		private isvcConversation: ConversationService,
		private isvcPageManager: PageManagerService,
		private ioRoute: ActivatedRoute,
		private ioRouter: Router,
		private ioContactNamePipe: ContactNamePipe,
		poParentPage: DynamicPageComponent<ComponentBase>,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poParentPage, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		this.moConversation = this.ioRoute.snapshot.data.conversation;
		this.msOldTitle = this.title = this.moConversation.title ?? "";

		this.loadContactsAndGroupsFromIds(this.moConversation.participants.map((poParticipant: IParticipant<IContact | IGroup>) => poParticipant.participantId))
			.pipe(
				mergeMap(_ => {
					if (!ArrayHelper.hasElements(this.contacts)) {
						this.contacts = [];
						return of(undefined);
					}
					else { //On ajoute l'utilisateur courant à la liste des contacts s'il n'en fait pas déjà partie.
						return this.isvcContacts.getContact(this.userContactId)
							.pipe(
								tap((poCurrentUser?: IContact) => {
									if (poCurrentUser)
										ArrayHelper.pushIfNotPresent(this.contacts, poCurrentUser, (poContact: IContact) => poContact._id === this.userContactId);
								})
							);
					}
				}),
				tap(_ => {
					// On ajoute tous les groupes et on ajoute tous les contacts qui sont présents en tant que participant, les autres sont issus des groupes.
					this.maSelectedParticipants = [
						...this.groups,
						...this.contacts.filter((poContact: IContact) =>
							this.moConversation.participants.some((poParticipant: IParticipant<IGroup | IContact>) => poParticipant.participantId === poContact._id)
						)
					];
					this.maOldSelectedParticipants = [...this.maSelectedParticipants];
					this.maContactsAndGroupsBefore = Array.from(this.maSelectedParticipants);
				}),
				mergeMap(_ => this.updateContactsData())
			)
			.subscribe();
	}

	protected override onLifeCycleEvent(poValue: IApplicationEvent): void {
		if (poValue.type === EApplicationEventType.LifeCycleEvent) {

			switch ((poValue as ILifeCycleEvent).data.value) {

				case ELifeCycleEvent.viewWillEnter:
					this.detectChanges();
					break;
				case ELifeCycleEvent.viewDidLeave:
					// On réinitialise le booléen pour savoir si le bouton de validation a été cliqué, au cas où la page n'est pas détruite et qu'on revient dessus.
					this.mbIsValidated = false;
					break;
			}
		}
	}

	/** Met à jour les informations à afficher à l'utilisateur. Charge les contacts de tous les groupes sélectionnés. */
	private updateContactsData(): Observable<Map<string, IContact[]>> {
		// Affiche tous les contacts sélectionnés.
		this.uiContacts = this.contacts.map((poContact: IContact) => this.createContactData(poContact));

		// Récupère les contacts des groupes sélectionnés.
		return this.isvcGroups.getGroupContacts(this.groups)
			.pipe(
				tap((paGroupsContacts: Map<string, IContact[]>) => {
					this.groups.forEach((poGroup: IGroup) => {
						// Pour chaque groupe, on met à jour ses contacts.
						if (paGroupsContacts.has(poGroup._id)) {
							paGroupsContacts.get(poGroup._id)!.forEach((poContact: IContact) => {
								const lnIndex: number = this.uiContacts.findIndex((poContactData: IContactData) => poContactData._id === poContact._id);

								if (lnIndex !== -1) // On l'ajoute au contactData existant.
									this.uiContacts[lnIndex].groups = this.uiContacts[lnIndex].groups ? `${this.uiContacts[lnIndex].groups}, ${poGroup.name}` : poGroup.name;
								else // On créé un nouveau contactData.
									this.uiContacts.push(this.createContactData(poContact, poGroup));
							});
						}
					});

					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			);
	}

	/** Retourne un objet `ContactData` par défaut */
	private createContactData(poContact: IContact, poGroup?: IGroup): IContactData {
		return { label: this.ioContactNamePipe.transform(poContact), groups: poGroup ? poGroup.name : undefined, _id: poContact._id };
	}

	/** Charge tous les groupes, les contacts, et les contacts des groupes. Les assigne à `this.contacts` et `this.groups`. */
	private loadContactsAndGroupsFromIds(paParticipantsIds: Array<string>): Observable<[IGroup[], IContact[]]> {
		const laGroupsIds: string[] = [];
		const laContactsIds: string[] = [];

		// Dissocie les ids des groupes de ceux des contacts.
		paParticipantsIds.forEach((psId: string) => {
			if (ContactsService.isContact(psId))
				laContactsIds.push(psId);
			else if (GroupsService.isGroup(psId))
				laGroupsIds.push(psId);
			else
				console.error(`${ConversationEditComponent.C_LOG_ID}Type Inconnu :`, psId);
		});

		const loGetGroups$: Observable<Group[]> = this.isvcGroups.getDisplayableGroups(laGroupsIds).pipe(tap((paGroups: Group[]) => this.groups = paGroups));
		const loGetContacts$: Observable<IContact[]> = this.getContacts(laContactsIds, laGroupsIds);

		return forkJoin([loGetGroups$, loGetContacts$]);
	}

	/** Récupère et assigne les contacts (y compris les contacts des groupes). */
	private getContacts(paContactsIds: string[], paGroupIds: string[]): Observable<IContact[]> {
		return this.isvcContacts.getContactsByIds(paContactsIds)
			.pipe(
				tap((paContacts: IContact[]) => this.contacts = paContacts),
				mergeMap(_ => this.isvcGroups.getGroupContacts(paGroupIds)),
				tap((poContactsByGroupId: Map<string, IContact[]>) => {
					paGroupIds.forEach((psGroupId: string) => this.contacts.push(...(poContactsByGroupId.get(psGroupId) ?? [])));
					this.contacts = ArrayHelper.unique(this.contacts, (poContact: IContact) => poContact._id);
				}),
				mapTo(this.contacts)
			);
	}

	/** Affichage de la bonne toolbar en fonction du nombre de participants. */
	private updateToolbar(): void {
		if (this.isMailConversation){
			return;
		}
		const laToolbarElements: Array<IBarElement> = [ // Structure de config de la toolbar qui valide la création de la conversation.
			{
				id: "circle",
				component: "fabButton",
				dock: EBarElementDock.bottom,
				position: EBarElementPosition.right,
				background: "#1BC14D",
				icon: "checkmark",
				color: "#fff",
				onTap: () => this.createOrUpdateConversation(),
				options: { isDisable: !this.maSelectedParticipants || this.maSelectedParticipants.length < 2 }
			}
		];

		this.getParentToolbar()?.init(laToolbarElements, this.getInstanceId());
	}

	/** Crée ou met à jour une conversation puis route vers la page précédente ou la conversation. */
	public createOrUpdateConversation(): void {
		this.mbIsValidated = true;
		let loAction$: Observable<void>;

		if (this.moConversation) {
			this.moConversation.title = this.title?.trim();
			// Met à jour la conversation et route.
			loAction$ = this.isvcConversation.updateConversation(this.moConversation, this.maSelectedParticipants, this.maContactsAndGroupsBefore)
				.pipe(
					mergeMap(_ => {
						// Si on peut retourner en arrière on le fait, sinon on se déplace sur la conversation modifié.
						if (this.ioRouter.navigated)
							return this.isvcPageManager.goBack(); // Utile si on est une modale.
						else // TODO La conversation sur laquelle on va router va avoir un bouton de retour qui pointera vers le mode edit.
							return of(this.isvcConversation.routeToConversation(this.moConversation));
					})
				);
		}
		else {
			const loOptions: IOpenConversationOptions = {
				title: this.title,
				members: this.maSelectedParticipants
			};

			if (UserData.current) {
				loAction$ = this.isvcConversation.createOrOpenConversation(ContactsService.getContactIdFromUserId(UserData.current.name), loOptions)
					.pipe(map((poResult: IConversation) => { this.isvcConversation.routeToConversation(poResult); }));
			}
			else
				loAction$ = throwError(() => new Error('No current user.'));
		}

		loAction$.pipe(
			catchError((poError: any) => {
				this.mbIsValidated = false;
				console.error(`${ConversationEditComponent.C_LOG_ID}The creation or the update of the conversation "${this.moConversation._id}" met a problem.`, poError);
				return of(EMPTY);
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	/** Affiche la modale de sélection des contacts et mets à jour l'UI après modification. */
	public openContactSelector(): void {
		const loContactsSelectorParams: IContactsPickerParams = {
			excludeCurrentUser: true,
			hasSearchbox: true,
			preSelectedIds: this.maSelectedParticipants.map((poMember: IGroupMember) => poMember._id),
			type: EContactsType.contactsAndGroups,
			disableItemFunction: (poContact: IContact) => !ConversationHelper.isParticipantEligible(poContact),
			hideAllSelectionButton: ConfigData.conversation?.hideAllSelectionButton,
			sort: EContactsPickerSort.byPreSelectedParticipants,
			min: 1,
			defaultTab: ConfigData.conversation?.defaultConversationTab ?? 0
		};

		this.isvcContacts.openContactsSelectorAsModal(loContactsSelectorParams)
			.pipe(
				filter((paMembers: Array<IContact | IGroup>) => !!paMembers),
				tap((paMembers: Array<IContact | IGroup>) => {
					this.maSelectedParticipants = paMembers;
					this.contacts = paMembers.filter((poMember: IContact | IGroup) => ContactsService.isContact(poMember._id)) as IContact[];
					this.groups = paMembers.filter((poMember: IContact | Group) => GroupsService.isGroup(poMember._id)) as Group[];
				}),
				mergeMap((paMembers: Array<IContact | IGroup>) => // Récupère les contacts des groupes et des contacts à partir de leurs ids.
					this.loadContactsAndGroupsFromIds(paMembers.map((poValue: IContact | IGroup) => poValue._id))
				),
				mergeMap(_ => this.updateContactsData())
			)
			.subscribe();
	}

	/** Supprime le groupe cliqué.
	 * @param poGroup Groupe à supprimer.
	 */
	public remove(poGroup: IGroup): void;
	/** Supprime le contact cliqué.
	 * @param poContactOrGroup Contact à supprimer.
	 */
	public remove(poContact: IContactData): void;
	public remove(poContactOrGroup: IContactData | IGroup): void {
		ArrayHelper.removeElementByFinder(this.maSelectedParticipants, (poItem: IGroup | IContact) => poItem._id === poContactOrGroup._id);

		if (ContactsService.isContact(poContactOrGroup._id)) {
			if ((poContactOrGroup as IContactData).groups)
				console.error(`${ConversationEditComponent.C_LOG_ID}On ne doit pas pouvoir supprimer un contact qui est dans un groupe.`);
			else {
				ArrayHelper.removeElementById(this.contacts, poContactOrGroup._id);
				// Supprime le contact de l'interface utilisateur.
				this.uiContacts = this.uiContacts.filter((poContactData: IContactData) => poContactData._id !== poContactOrGroup._id);
			}
		}
		else if (GroupsService.isGroup(poContactOrGroup._id)) {
			this.isvcGroups.getGroupContacts(poContactOrGroup._id)
				.pipe(
					map((paContacts: IContact[]) => {
						const laFilteredGroupContacts: IContact[] =
							paContacts.filter((poContact: IContact) => this.contacts.some((poItem: IContact) => poItem._id === poContact._id));
						this.maSelectedParticipants = ArrayHelper.unique(this.maSelectedParticipants.concat(laFilteredGroupContacts));

						ArrayHelper.removeElementById(this.groups, poContactOrGroup._id);
					}),
					mergeMap(() => this.updateContactsData()) // Met à jour l'interface.
				)
				.subscribe();
		}
		else
			console.error(`${ConversationEditComponent.C_LOG_ID}Impossible de supprimer, objet non reconnu.`, poContactOrGroup);

		this.detectChanges();
	}

	public override detectChanges(): void {
		this.updateToolbar();
		super.detectChanges();
	}

	/** Retourne 'true' s'il y a eu des modifications, 'false' sinon. */
	public hasChanges(): boolean {
		const lbParticipantsChanged = !ArrayHelper.areArraysEqual(this.maOldSelectedParticipants, this.maSelectedParticipants,
			(poOldParticipant: IContact | IGroup, poNewParticipant: IContact | IGroup) => poOldParticipant._id === poNewParticipant._id);
		const lbTitleChanged: boolean = this.msOldTitle !== this.title;

		return lbParticipantsChanged || lbTitleChanged;
	}

	//#endregion
}
