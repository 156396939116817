import { Exclude, Transform, TransformFnParams } from "class-transformer";
import { map, Observable } from "rxjs";
import { ArrayHelper } from "../../../helpers/arrayHelper";
import { ObjectHelper } from "../../../helpers/objectHelper";
import { StoreDocumentHelper } from "../../../helpers/storeDocumentHelper";
import { EPrefix } from "../../../model/EPrefix";
import { IIndexedObject } from "../../../model/IIndexedObject";
import { ILastChange } from "../../hooks/models/ilast-change";
import { ObserveArray } from "../../observable/decorators/observe-array.decorator";
import { ObservableArray } from "../../observable/models/observable-array";
import { ObservableProperty } from "../../observable/models/observable-property";
import { StoreDocument } from "../../store/model/store-document";
import { ModelResolver } from "../../utils/models/model-resolver";
import { EEntityLinkType } from "./eentity-link-type";
import { EntityLink } from "./entity-link";
import { EntityLinkEntity } from "./entity-link-entity";
import { IEntity } from "./ientity";
import { IEntityMeta } from "./ientity-meta";
import { StateMachine } from "./state-machine";

export abstract class Entity extends StoreDocument implements IEntity {

	//#region PROPERTIES

	@Exclude()
	public observableIsValid?: ObservableProperty<boolean> = new ObservableProperty<boolean>();

	public lastChange?: ILastChange | undefined;

	public meta?: IEntityMeta;

	@Exclude()
	public links: EntityLink[];
	@ObserveArray<Entity>("links")
	public readonly observableLinks = new ObservableArray<EntityLink>([]);

	@Exclude()
	private moLinkedEntitiesById = new Map<string, Entity>();
	@Exclude()
	private maLinkedEntities: Entity[] = [];
	/**  */
	public get linkedEntities(): Entity[] { return this.maLinkedEntities; }
	public set linkedEntities(paLinkedEntities: Entity[]) {
		if (paLinkedEntities !== this.maLinkedEntities) {
			this.maLinkedEntities = paLinkedEntities;
			this.moLinkedEntitiesById =
				ArrayHelper.groupByUnique(this.maLinkedEntities, (poEntity: Entity) => poEntity._id);
		}
	}

	@Transform((poParams: TransformFnParams) =>
		poParams.obj[poParams.key] =
		StoreDocumentHelper.hasRevision(poParams.obj) ? poParams.value ?? new Date : new Date,
		{ toPlainOnly: true }
	)
	public createDate?: Date | string | null = null;

	@Exclude()
	public stateMachines?: IIndexedObject<StateMachine>;
	@Exclude()
	public themeColor?: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: IEntity) {
		super(poData);

		this.links = [];
	}

	public hasLinks(peEntityLinkType?: EEntityLinkType, psLinkedEntityPrefix?: string, pbLogical?: boolean): boolean {
		return this.links.some((poEntityLink: EntityLink) =>
			poEntityLink.hasLink(peEntityLinkType, psLinkedEntityPrefix, this._id) &&
			(!ObjectHelper.isDefined(pbLogical) || pbLogical === poEntityLink.logical)
		)
	}

	public getLinkedEntities(peEntityLinkType?: EEntityLinkType, peLinkedEntityPrefix?: EPrefix): Entity[] {
		const laEntities: Entity[] = [];

		this.links.forEach((poLink: EntityLink) => {
			poLink.getLinkedEntityLinkEntities(peEntityLinkType, peLinkedEntityPrefix, this._id)
				.forEach((poEntityLinkEntity: EntityLinkEntity) => {
					const loEntity: Entity | undefined = this.moLinkedEntitiesById.get(poEntityLinkEntity.id);

					if (loEntity)
						laEntities.push(loEntity);
				});
		});

		return laEntities;
	}

	public getLinkedEntities$(peEntityLinkType?: EEntityLinkType, peLinkedEntityPrefix?: EPrefix): Observable<Entity[]> {
		return this.observableLinks.changes$.pipe(map(_ => this.getLinkedEntities(peEntityLinkType, peLinkedEntityPrefix)));
	}

	public getLinkedEntitiesIds(peEntityLinkType?: EEntityLinkType, peLinkedEntityPrefix?: EPrefix): string[] {
		return this.getLinkedEntities(peEntityLinkType, peLinkedEntityPrefix).map((poEntity: Entity) => poEntity._id);
	}

	public clone(): this {
		const loClonedEntity: this = ModelResolver.toClass(Entity, ModelResolver.toPlain(this)) as this;

		loClonedEntity.links = [...this.links];
		loClonedEntity.linkedEntities = [...this.linkedEntities];

		return loClonedEntity;
	}

	//#endregion METHODS

}
