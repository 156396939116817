import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from '@calaosoft/osapp/guards/authenticated.guard';
import { C_ACTIVITY_BASE_ROUTE } from '../../app.constants';


const route: Route = {
	path: C_ACTIVITY_BASE_ROUTE,
	canActivate: [AuthenticatedGuard],
	children: [
		{
			path: 'new',
			loadChildren: () => import('./activity-creation/activity-creation.module').then(m => m.ActivityCreationPageModule)
		}
	]
};

@NgModule({
	imports: [RouterModule.forChild([route])],
	exports: [RouterModule]
})
export class ActivitySharedRoutingModule { }

export { route as activitySharedRoute };
