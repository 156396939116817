import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigurableRemoveCredentialsGuard } from './guards/parametrable-remove-credentials.guard';
import { RemoveCredentialsGuard } from './guards/remove-credentials.guard';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [
		RemoveCredentialsGuard,
		ConfigurableRemoveCredentialsGuard
	]
})
export class SecurityModule { }