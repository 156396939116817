import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldBase } from '../../../../../model/forms/FieldBase';
import { FormsService } from '../../../../../services/forms.service';

/** Champs contenant une image. */
@Component({
	selector: 'deprecated-calao-multi-input-field',
	templateUrl: 'multiInputField.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiInputFieldComponent extends FieldBase<string[]> implements OnInit { //todo: params.

	//#region PROPERTIES

	public newNumber?: string;

	//#endregion

	//#region METHODS

	constructor(psvcForm: FormsService, poChangeDetectorRef: ChangeDetectorRef) {
		super(psvcForm, poChangeDetectorRef);
	}

	/** Endroit où initialiser le composant après sa création. */
	public override ngOnInit(): void {
		super.ngOnInit();

		this.fieldValue = ["06 45 78 95 84", "06 07 17 89 66", "06 85 96 32 16"];
	}

	public onAdd(): void {
		this.fieldValue?.push(this.newNumber);
		this.newNumber = "";
		this.markAsDirty();
		this.detectChanges();
	}

	public onRemove(pnIndex: number): void {
		this.fieldValue?.splice(pnIndex, 1);
		this.detectChanges();
	}

	//#endregion
}