import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { DynamicPageComponent } from '@calaosoft/osapp/components/dynamicPage/dynamicPage.component';
import { AuthenticatedGuard } from '@calaosoft/osapp/guards/authenticated.guard';
import { CanDeactivateGuard } from '@calaosoft/osapp/guards/canDeactivate.guard';
import { PageInfo } from '@calaosoft/osapp/model/PageInfo';
import { HasPermissionGuard } from '@calaosoft/osapp/modules/permissions/guards/has-permission.guard';
import { EPermissionScopes } from '@calaosoft/osapp/modules/permissions/models/epermission-scopes';
import { IPermission } from '@calaosoft/osapp/modules/permissions/models/ipermission';
import { SiteResolver } from '@calaosoft/osapp/modules/sites/site.resolver';
import { C_SITES_BASE_ROUTE, C_SMALL_SCREEN_DISPLAY_MAX_WIDTH } from '../../app.constants';

const C_SITES_READ_PERMISSIONS: IPermission[] = [{ permission: EPermissionScopes.parameters, type: "read" }];
const C_SITES_CREATE_PERMISSIONS: IPermission[] = [{ permission: EPermissionScopes.parameters, type: "create" }];
const C_SITES_EDIT_PERMISSIONS: IPermission[] = [{ permission: EPermissionScopes.parameters, type: "edit" }];

const SITE_PAGE_INFO = new PageInfo({
	hasConversationsButton: true,
	conversationsButtonDisappearAtWidthPx: C_SMALL_SCREEN_DISPLAY_MAX_WIDTH,
	menuButtonDisappearAtWidthPx: C_SMALL_SCREEN_DISPLAY_MAX_WIDTH,
	title: "Détails du site",
	componentName: "site-details",
	hasMenuButton: true,
	hasSyncButton: true,
	hasHomeButton: false,
	tabBarStyle: true
});


const route: Route = {
	path: C_SITES_BASE_ROUTE,
	children: [
		{
			path: 'new',
			component: DynamicPageComponent,
			data: {
				pageInfo: SITE_PAGE_INFO,
				permissions: C_SITES_CREATE_PERMISSIONS
			},
			canActivate: [AuthenticatedGuard],
			canDeactivate: [CanDeactivateGuard]
		},
		{
			path: ':siteId',
			component: DynamicPageComponent,
			data: {
				pageInfo: SITE_PAGE_INFO,
				permissions: C_SITES_READ_PERMISSIONS
			},
			resolve: { model: SiteResolver },
			canActivate: [AuthenticatedGuard, HasPermissionGuard],
			canDeactivate: [CanDeactivateGuard]
		},
		{
			path: ':siteId/edit',
			component: DynamicPageComponent,
			data: {
				pageInfo: SITE_PAGE_INFO,
				permissions: C_SITES_EDIT_PERMISSIONS
			},
			resolve: { model: SiteResolver },
			canActivate: [AuthenticatedGuard, HasPermissionGuard],
			canDeactivate: [CanDeactivateGuard]
		}
	]
};

@NgModule({
	imports: [RouterModule.forChild([route])],
	exports: [RouterModule]
})
export class SitesSharedRoutingModule { }

export { route as sitesSharedRoute };
