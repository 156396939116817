import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs';
import { ObserveProperty } from '../../../modules/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../modules/observable/models/observable-property';
import { IRange } from '../../../modules/utils/models/models/irange';
import { secure } from '../../../modules/utils/rxjs/operators/secure';
import { DestroyableComponentBase } from '../../utils/components/destroyable-component-base';
import { ENumericRangePickerDisplay } from '../models/enumeric-range-picker-display';

@Component({
	selector: 'calao-numeric-range-picker',
	templateUrl: './numeric-range-picker.component.html',
	styleUrls: ['./numeric-range-picker.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumericRangePickerComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	/** Événement levé lors du changement de plage de valeurs. */
	@Output("onRangeChanged") private readonly moRangeChangedEvent = new EventEmitter<IRange<number>>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Mode d'affichage du composant (slider ou inputs). */
	@Input() public display?: ENumericRangePickerDisplay;
	@ObserveProperty<NumericRangePickerComponent>({ sourcePropertyKey: "display" })
	public readonly observableDisplay = new ObservableProperty<ENumericRangePickerDisplay>(ENumericRangePickerDisplay.slider);

	/** Plage de valeurs passée par le composant parent. */
	@Input() public model?: IRange<number>;
	@ObserveProperty<NumericRangePickerComponent>({ sourcePropertyKey: "model" })
	public readonly observableModel = new ObservableProperty<IRange<number>>();

	/** Libellé valeur minimale de la plage. */
	@Input() public minLabel?: number;
	@ObserveProperty<NumericRangePickerComponent>({ sourcePropertyKey: "minLabel" })
	public readonly observableMinLabel = new ObservableProperty<number>(0);

	/** Libellé valeur maximale de la plage.  */
	@Input() public maxLabel?: number;
	@ObserveProperty<NumericRangePickerComponent>({ sourcePropertyKey: "maxLabel" })
	public readonly observableMaxLabel = new ObservableProperty<number>(100);

	/** Nombre de pas pour chaque modification. (1 => augmente/diminue la valeur de 1 en 1, 5 => augmente/diminue la valeur de 5 en 5). */
	@Input() public step?: number;
	@ObserveProperty<NumericRangePickerComponent>({ sourcePropertyKey: "step" })
	public readonly observableStep = new ObservableProperty<number>(1);

	/** Valeur minimale de la plage. */
	public readonly observableMinValue = new ObservableProperty<number | undefined>();

	/** Valeur maximale de la plage. */
	public readonly observableMaxValue = new ObservableProperty<number | undefined>();

	public ENumberRangePickerDisplay = ENumericRangePickerDisplay;

	//#endregion PROPERTIES

	//#region METHODS

	constructor() {
		super();
	}

	public ngOnInit(): void {
		this.observableModel.value$.pipe(
			tap((poRange: IRange<number>) => {
				this.observableMinValue.value = poRange?.from ?? this.observableMinLabel.value;
				this.observableMaxValue.value = poRange?.to ?? this.observableMaxLabel.value;
			}),
			secure(this)
		).subscribe();
	}

	/** Déclenche l'émission de l'évènement de changement de plage de valeurs avec la nouvelle valeur mini. */
	public updateMinRange(poEvent: number | Event): void {
		let lnValue: number;

		if (poEvent instanceof Event)
			lnValue = new Number((poEvent.target as HTMLInputElement).value).valueOf();
		else
			lnValue = poEvent;

		this.observableMinValue.value = lnValue;
		this.moRangeChangedEvent.emit({ from: lnValue > 0 ? lnValue : undefined, to: this.observableMaxValue.value });
	}

	/** Déclenche l'émission de l'évènement de changement de plage de valeurs avec la nouvelle valeur maxi. */
	public updateMaxRange(poEvent: number | Event): void {
		let lnValue: number;

		if (poEvent instanceof Event)
			lnValue = new Number((poEvent.target as HTMLInputElement).value).valueOf();
		else
			lnValue = poEvent;

		this.moRangeChangedEvent.emit({ from: this.observableMinValue.value, to: lnValue > 0 ? lnValue : undefined });
	}

	//#endregion METHODS
}
