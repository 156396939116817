import { EEntityLinkType } from "./eentity-link-type";
import { IEntityLinkEntity } from "./ientity-link-entity";

export class EntityLinkEntity implements IEntityLinkEntity {

	//#region PROPERTIES

	/** @implements */
	public readonly id: string;
	/** @implements */
	public readonly type?: EEntityLinkType;
	/** @implements */
	public readonly databaseId: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: IEntityLinkEntity) {
		this.id = poData?.id ?? "";
		this.databaseId = poData?.databaseId ?? "";
		this.type = poData?.type;
	}

	public equals(poEntity: EntityLinkEntity): boolean {
		return poEntity.databaseId === this.databaseId && poEntity.id === this.id && poEntity.type === this.type;
	}

	//#endregion METHODS

}
