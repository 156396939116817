import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { catchError, tap, throwError } from "rxjs";
import { ModalComponentBase } from "../../../modules/modal";
import { ObservableProperty } from "../../../modules/observable/models/observable-property";
import { secure } from "../../../modules/utils/rxjs/operators/secure";
import { ShowMessageParamsPopup } from "../../../services/interfaces/ShowMessageParamsPopup";
import { ShowMessageParamsToast } from "../../../services/interfaces/ShowMessageParamsToast";
import { InteropService } from "../../../services/interop.service";
import { PlatformService } from "../../../services/platform.service";
import { UiMessageService } from "../../../services/uiMessage.service";

@Component({
	selector: "mailbox-modal",
	templateUrl: "./mailbox-modal.component.html",
	styleUrls: ["./mailbox-modal.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailboxModalComponent extends ModalComponentBase<any> implements OnInit {
	//#region FIELDS

	private readonly C_SYSTEM_TYPE = "mailbox";

	//#endregion

	//#region PROPERTIES

	public readonly observableIsLoading = new ObservableProperty<boolean>(true);

	public readonly observableIsSubscribe = new ObservableProperty<boolean>(false);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcInterop: InteropService,
		private readonly isvcUiMessage: UiMessageService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public override ngOnInit(): void {
		this.isvcInterop.hasSubscribe$(this.getMailboxSystem(), this.C_SYSTEM_TYPE).pipe(
			tap((pbIsSubscribe: boolean) => {
				this.observableIsSubscribe.value = pbIsSubscribe;
				this.observableIsLoading.value = false;
			}),
			catchError((poError) => {
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
					header: "Erreur",
					message: "Impossible de retrouver l'état de connexion de votre boîte mail, recommencer plus tard.",
				}));
				return throwError(() => poError);
			}),
			secure(this)
		).subscribe();
	}

	public onStopButtonClick(): void {
		this.close();
	}

	public async onAuthorizeButtonClickAsync(): Promise<void> {
		this.observableIsLoading.value = true;
		this.isvcInterop.demandAuth$(this.getMailboxSystem(), [this.C_SYSTEM_TYPE]).pipe(
			tap((pbResult: boolean) => {
				this.observableIsLoading.value = false;
				if (pbResult) {
					this.observableIsSubscribe.value = true;
					this.isvcUiMessage.showMessage(new ShowMessageParamsToast({
						message: "Votre boîte mail est maintenant connectée.",
						color: "dark",
						duration: 5000
					}));
					this.close();
				} else {
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
						header: "Erreur",
						message: "Votre boîte mail n'a pas pu être autorisée, recommencer plus tard.",
					}));
				}
			}),
			secure(this)
		).subscribe();
	}

	public onCancelAuthorizeButtonClick(): void {
		this.observableIsLoading.value = true;
		this.isvcInterop.deleteAuth$(this.getMailboxSystem()).pipe(
			tap(() => this.observableIsLoading.value = false),
			tap((pbCreated: boolean) => {
				if (pbCreated) {
					this.isvcUiMessage.showMessage(new ShowMessageParamsToast({
						message: "Votre boîte mail est maintenant déconnectée.",
						color: "dark",
						duration: 5000
					}));
					this.observableIsSubscribe.value = false;
					this.close();
				} else {
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
						header: "Erreur",
						message: "Votre boîte mail n'a pas pu être déconnectée, recommencer plus tard.",
					}));
				}
			}),
			secure(this)
		).subscribe();
	}

	public getMailboxSystem(): string {
		//TODO récupérer le systeme via la permission conversation.interop.mailbox
		return "msgraph";
	}

	//#endregion
}
