import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { IPopoverItemParams } from '@calaosoft/osapp/model/popover/IPopoverItemParams';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp/modules/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { Queue } from '@calaosoft/osapp/modules/utils/queue/decorators/queue.decorator';
import { secure } from '@calaosoft/osapp/modules/utils/rxjs/operators/secure';
import { PopoverService } from '@calaosoft/osapp/services/popover.service';
import { EMPTY, Observable, defer, of } from 'rxjs';
import { filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { EReminderStatus } from '../../models/ereminder-status';
import { ReminderOccurrence } from '../../models/reminder-occurrence';
import { TradeEventsService } from '../../services/trade-events.service';

@Component({
	selector: 'trade-reminder-item',
	templateUrl: './reminder-item.component.html',
	styleUrls: ['./reminder-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReminderItemComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "RMD.ITM.C::";
	public readonly moObservablePopOverItemsParams = new ObservableArray<IPopoverItemParams>(this.getPopOverItemsParams());

	//#endregion FIELDS

	//#region PROPERTIES

	/** Occurrence de l'évènement. */
	@Input() public item?: ReminderOccurrence;
	@ObserveProperty<ReminderItemComponent>({ sourcePropertyKey: "item" })
	public readonly observableItem = new ObservableProperty<ReminderOccurrence>();

	/** Couleur de fond de la partie gauche de l'item. */
	public readonly observableItemStartBackgroundColor = new ObservableProperty<string>("var(--ion-color-reminder-tint)");

	/** Couleur de l'icône dans la partie gauche de l'item. */
	public readonly observableItemStartIconColor = new ObservableProperty<string>("var(--ion-colo-dark)");

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcTradeEvents: TradeEventsService,
		private readonly isvcPopover: PopoverService
	) {
		super();
	}

	public ngOnInit(): void {
		this.observableItem.value$.pipe(
			tap((poReminder: ReminderOccurrence) => {
				const loValidationPopOverParams: IPopoverItemParams = {
					action: () => this.onFinishClicked$(),
					id: "validation",
					color: "success",
					icon: "checkmark",
					title: "Terminer"
				};
				const loRepeatPopOverParams: IPopoverItemParams = {
					action: () => this.onRepeatClicked$(),
					id: "repeat",
					color: "primary",
					icon: "notifications",
					title: "Rappel dans 15min"
				};
				const loReschedulePopOverParams: IPopoverItemParams = {
					action: () => this.onRescheduleClicked$(),
					id: "reschedule",
					color: "primary",
					icon: "refresh",
					title: "Reporter"
				};

				const laPopOverItemsParams: IPopoverItemParams[] = [...this.moObservablePopOverItemsParams];
				let lbHasValidationPopOverParams = false;
				let lbHasRepeatPopOverParams = false;
				let lbHasReschedulePopOverParams = false;
				laPopOverItemsParams.forEach((poPopOverParam: IPopoverItemParams) => {
					if (!lbHasValidationPopOverParams)
						lbHasValidationPopOverParams = poPopOverParam.id === loValidationPopOverParams.id;
					if (!lbHasRepeatPopOverParams)
						lbHasRepeatPopOverParams = poPopOverParam.id === loRepeatPopOverParams.id;
					if (!lbHasReschedulePopOverParams)
						lbHasReschedulePopOverParams = poPopOverParam.id === loReschedulePopOverParams.id;
				});

				if (!lbHasValidationPopOverParams && poReminder.status !== EReminderStatus.done)
					laPopOverItemsParams.push(loValidationPopOverParams);
				if (!lbHasRepeatPopOverParams && poReminder.status !== EReminderStatus.done && DateHelper.compareTwoDates(poReminder.startDate, new Date) <= 0)
					laPopOverItemsParams.unshift(loRepeatPopOverParams);
				if (!lbHasReschedulePopOverParams && poReminder.status !== EReminderStatus.done)
					laPopOverItemsParams.unshift(loReschedulePopOverParams);

				this.moObservablePopOverItemsParams.resetArray([...laPopOverItemsParams]);
			}),
			secure(this)
		).subscribe();
	}

	public onReminderClicked(): void {
		if (this.observableItem.value)
			this.isvcTradeEvents.routeToViewEventAsync(this.observableItem.value);
	}

	private getPopOverItemsParams(): IPopoverItemParams[] {
		return [
			{
				action: () => this.navigateToEdit$(),
				id: "creation",
				color: "primary",
				icon: "create",
				title: "Éditer"
			},
			{
				action: () => this.onDeleteClicked$(),
				id: "deletion",
				color: "danger",
				icon: "trash",
				title: "Supprimer définitivement"
			}
		];
	}

	public async presentPopOverAsync(poEvent: MouseEvent): Promise<void> {
		this.presentPopOver$(poEvent).subscribe();
	}

	@Queue<ReminderItemComponent, Parameters<ReminderItemComponent["presentPopOver$"]>, ReturnType<ReminderItemComponent["presentPopOver$"]>>({
		excludePendings: true
	})
	private presentPopOver$(poEvent: MouseEvent): Observable<HTMLIonPopoverElement> {
		return defer(() => this.isvcPopover.showPopoverAsync(this.moObservablePopOverItemsParams, poEvent));
	}

	private navigateToEdit$(): Observable<boolean> {
		return this.observableItem.value ? defer(() => this.navigateToEditAsync(this.observableItem.value)) : of(false);
	}

	private navigateToEditAsync(poReminderOccurrence: ReminderOccurrence): Promise<boolean> {
		return this.isvcTradeEvents.routeToEditReminderAsync(poReminderOccurrence);
	}

	private onDeleteClicked$(): Observable<void> {
		return this.observableItem.value ? this.deleteReminder$() : of();
	}

	@Queue<ReminderItemComponent, Parameters<ReminderItemComponent["deleteReminder$"]>, ReturnType<ReminderItemComponent["deleteReminder$"]>>({
		excludePendings: true
	})
	private deleteReminder$(): Observable<void> {
		if (this.item) {
			return defer(() => this.isvcTradeEvents.askForDeletetionAsync(this.observableItem.value.event))
				.pipe(
					takeUntil(this.destroyed$),
					filter((poResponse: boolean) => !!poResponse),
					mergeMap(() => this.isvcTradeEvents.deleteTaskAsync(this.observableItem.value.event))
				);
		}
		else {
			console.warn(`${ReminderItemComponent.C_LOG_ID}reminderOccurrence is not defined !`);
			return EMPTY;
		}
	}

	private onFinishClicked$(): Observable<void> {
		return this.observableItem.value ? this.endReminder$() : of();
	}

	@Queue<ReminderItemComponent, Parameters<ReminderItemComponent["endReminder$"]>, ReturnType<ReminderItemComponent["endReminder$"]>>({
		excludePendings: true
	})
	private endReminder$(): Observable<void> {
		return defer(() => this.isvcTradeEvents.showEndReminderPopupAsync())
			.pipe(
				takeUntil(this.destroyed$),
				filter((poResponse: boolean) => !!poResponse),
				mergeMap(() => {
					if (this.item)
						return this.isvcTradeEvents.endOccurrenceAsync(this.observableItem.value);
					else {
						console.warn(`${ReminderItemComponent.C_LOG_ID}reminderOccurrence is not defined !`);
						return EMPTY;
					}
				})
			);
	}

	private onRescheduleClicked$(): Observable<void> {
		return this.observableItem.value ? this.rescheduleReminder$(this.observableItem.value) : of();
	}

	@Queue<ReminderItemComponent, Parameters<ReminderItemComponent["rescheduleReminder$"]>, ReturnType<ReminderItemComponent["rescheduleReminder$"]>>({
		excludePendings: true
	})
	private rescheduleReminder$(poReminderOccurrence: ReminderOccurrence): Observable<void> {
		return this.isvcTradeEvents.rescheduleReminder$(poReminderOccurrence).pipe(takeUntil(this.destroyed$));
	}

	private onRepeatClicked$(): Observable<void> {
		return this.observableItem.value ? this.repeatReminder$(this.observableItem.value) : of();
	}

	@Queue<ReminderItemComponent, Parameters<ReminderItemComponent["repeatReminder$"]>, ReturnType<ReminderItemComponent["repeatReminder$"]>>({
		excludePendings: true
	})
	private repeatReminder$(poReminderOccurrence: ReminderOccurrence): Observable<void> {
		return this.isvcTradeEvents.repeatReminder$(poReminderOccurrence, 15).pipe(takeUntil(this.destroyed$));
	}

	//#endregion METHODS

}
