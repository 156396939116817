<ng-container [ngSwitch]="conversationType">
	<ng-container *ngSwitchCase="'chat'">
		<ion-button [fill]="isFill ? 'solid' : 'outline'" shape="round">
			<ion-icon slot="start" name="chatbubbles"></ion-icon>
			Chat
		</ion-button>
	</ng-container>
	<ng-container *ngSwitchCase="'mail'">
		<ion-button [fill]="isFill ? 'solid' : 'outline'" shape="round">
			<ion-icon slot="start" name="mail-sharp"></ion-icon>
			Mail
		</ion-button>
	</ng-container>
	<ng-container *ngSwitchDefault>
		<ion-button [fill]="isFill ? 'solid' : 'outline'" shape="round">
			<ion-icon slot="start" name="help"></ion-icon>
			Unknown
		</ion-button>
	</ng-container>
</ng-container>