import { AfterContentChecked, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
	selector: '[linkify]'
})
export class LinkifyDirective implements AfterContentChecked {

	//#region FIELDS

	private mbHasBeenLinked = false; // Empêcher des modifications multiples inutiles

	//#endregion FIELDS

	//#region METHODS

	constructor(private readonly ioElement: ElementRef<HTMLElement>, private readonly isvcRenderer: Renderer2) { }

	// On utilise pas ngOnInit car le texte peut être dynamique.
	public ngAfterContentChecked(): void {
		if (!this.mbHasBeenLinked && this.ioElement.nativeElement.innerHTML) {
			this.mbHasBeenLinked = true; // Marquer comme traité pour éviter les re-traitements
			this.replaceTextByCliquableLinks();
		}
	}

	private replaceTextByCliquableLinks(): void {
		const lsLinkedText: string = this.ioElement.nativeElement.innerHTML.replace(
			/(https?:\/\/[^\s]+)/g,
			(psUrl: string): string => `<a href="${psUrl}" target="_self">${psUrl}</a>`
		);

		// Met à jour le contenu HTML de l'élément.
		this.isvcRenderer.setProperty(this.ioElement.nativeElement, "innerHTML", lsLinkedText);
	}

	//#endregion METHODS

}