<div *ngIf="observableInitialized.value$ | async" class="center">
	<ion-datetime #mobileTimePicker *ngIf="!!params" [value]="params.value" [min]="params.min" [max]="params.max"
		presentation="time" locale="fr-FR">
		<ion-buttons slot="buttons">
			<ion-button color="danger" (click)="close()">
				{{ params.cancelText }}</ion-button>
			<ion-button color="primary" (click)="onTimeChangedAsync(mobileTimePicker)">
				{{ params.doneText }}
			</ion-button>
		</ion-buttons>
	</ion-datetime>
</div>