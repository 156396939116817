import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { PlatformService } from '../../../services/platform.service';

@Component({ template: "", selector: "calao-bottom-sheet-base" })
export abstract class BottomSheetComponentBase<P, R> extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	/** Abonnement au bouton natif 'back' Android. */
	private moBackButtonSubscription?: Subscription;

	//#endregion

	//#region PROPERTIES

	/** Paramètres du composant. */
	public readonly params?: P;

	//#endregion

	//#region METHODS

	/** Mettre dans le composant fils : `@Inject(MAT_BOTTOM_SHEET_DATA) poParams: P`. */
	constructor(
		protected ioMatBottomSheet: MatBottomSheet,
		private isvcPlatform: PlatformService,
		poChangeDetector?: ChangeDetectorRef,
		@Inject(undefined) poParams?: P, // Obligé de mettre un @Inject pour éviter une erreur de compilation 'No suitable injection token for parameter'
	) {
		super(poChangeDetector);

		this.params = poParams as P;
	}

	public ngOnInit(): void {
		// Permet de fermer des modales une à une si plusieurs sont instanciées en même temps et non pas toutes les fermer en faisant un seul back natif.
		this.moBackButtonSubscription = this.isvcPlatform.getBackButtonSubscription(() => this.close());
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moBackButtonSubscription?.unsubscribe();
	}

	/** Permet de fermer la modale en passant un résultat (il faut s'abonner à la méthode d'instance `MatBottomSheetRef.afterDismissed()`).
	 * @param poData Donnée à retourner au parent lors de la fermeture du composant.
	 */
	public close(poData?: R): void {
		this.ioMatBottomSheet.dismiss(poData);
	}

	//#endregion

}