import { Injectable, Type } from '@angular/core';
import { Observable, Subject, defer, interval, merge, of, race, throwError, timer } from 'rxjs';
import { catchError, concatMap, filter, finalize, last, map, mapTo, mergeMap, repeatWhen, retryWhen, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { MapHelper } from '../helpers/mapHelper';
import { NumberHelper } from '../helpers/numberHelper';
import { ObjectHelper } from '../helpers/objectHelper';
import { StringHelper } from '../helpers/stringHelper';
import { EPrefix } from '../model/EPrefix';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { EBackgroundTaskEventStatus } from '../model/backgroundTask/EBackgroundTaskEventStatus';
import { IBackgroundTaskEvent } from '../model/backgroundTask/IBackgroundTaskEvent';
import { ITaskParams } from '../model/backgroundTask/taskParams/ITaskParams';
import { EConfigFlag } from '../model/config/EConfigFlag';
import { IFlag } from '../model/flag/IFlag';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { EStoreFlag } from '../model/store/EStoreFlag';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { EventParticipantAddTask } from '../modules/calendar-events/tasks/event-participant-add-task';
import { EventParticipantRemoveTask } from '../modules/calendar-events/tasks/event-participant-remove-task';
import { EventParticipantUpdateTask } from '../modules/calendar-events/tasks/event-participant-update-task';
import { DeleteDmsFileTask } from '../modules/dms/tasks/DeleteDmsFileTask';
import { DownloadDmsFileTask } from '../modules/dms/tasks/DownloadDmsFileTask';
import { SyncDmsTask } from '../modules/dms/tasks/SyncDmsTask';
import { UploadDmsFileTask } from '../modules/dms/tasks/UploadDmsFileTask';
import { EFlag } from '../modules/flags/models/EFlag';
import { ObservableArray } from '../modules/observable/models/observable-array';
import { ObservableMap } from '../modules/observable/models/observable-map';
import { SavePurchaseTask } from '../modules/purchase/tasks/save-purchase-task';
import { ConvSyncTask } from './backgroundTask/ConvSyncTask';
import { DatabaseReplicateTask } from './backgroundTask/DatabaseReplicateTask';
import { DbSyncTask } from './backgroundTask/DbSyncTask';
import { DbToLocalTask } from './backgroundTask/DbToLocalTask';
import { FlushLogTask } from './backgroundTask/FlushLogTask';
import { GroupSaveLinksTask } from './backgroundTask/GroupSaveLinksTask';
import { HeartbeatTask } from './backgroundTask/HeartbeatTask';
import { NotifInitTask } from './backgroundTask/NotifInitTask';
import { NotificationOpenConversationTask } from './backgroundTask/NotificationOpenConversationTask';
import { TaskBase } from './backgroundTask/TaskBase';
import { TaskDescriptor } from './backgroundTask/TaskDescriptor';
import { UpdateTask } from './backgroundTask/UpdateTask';
import { FlagService } from './flag.service';
import { PlatformService } from './platform.service';
import { Store } from './store.service';

/** Service qui gère les tâches de fond. */
@Injectable({ providedIn: "root" })
export class BackgroundTaskService {

	//#region FIELDS

	/** Intervalle de temps en ms avant de réexécuter une tâche échouée. */
	private static readonly C_DEFAULT_RETRY_INTERVAL_MS = 30000;
	private static readonly C_LOG_ID = "BTS.S::";

	public static readonly C_INIT_TASK_ID = "initBts";

	/** Tableau des tâches à exécuter en arrière-plan de l'application, indexées par identifiant de tâche. */
	private readonly moObservableTasksById = new ObservableMap<string, TaskBase>();
	/** Tableau des tâches à reexécuter. */
	private readonly moObservableReexecuteTasksById = new ObservableMap<string, TaskBase>();
	/** Tableau des tâches async actives. */
	private readonly maObservableAsyncTasksRunning = new ObservableArray<string>();
	/** Sujet pour l'envoi d'événement. */
	private readonly moEventSubject = new Subject<IBackgroundTaskEvent>();
	/** Sujet pour l'ajout de tâches. */
	private readonly moAddTaskSubject = new Subject<TaskDescriptor>();
	/** Permet de maper des noms de classes à celles-ci. */
	private readonly moTaskByClassName: { [id: string]: Type<TaskBase> } = {
		SyncDmsTask: SyncDmsTask,
		DbSyncTask: DbSyncTask,
		HeartbeatTask: HeartbeatTask,
		ConvSyncTask: ConvSyncTask,
		DownloadDmsFileTask: DownloadDmsFileTask,
		UploadDmsFileTask: UploadDmsFileTask,
		DeleteDmsFileTask: DeleteDmsFileTask,
		FlushLogTask: FlushLogTask,
		NotificationOpenConversationTask: NotificationOpenConversationTask,
		UpdateTask: UpdateTask,
		DatabaseReplicateTask: DatabaseReplicateTask,
		NotifInitTask: NotifInitTask,
		SavePurchaseTask: SavePurchaseTask,
		GroupSaveLinksTask: GroupSaveLinksTask,
		DbToLocalTask: DbToLocalTask,
		EventParticipantAddTask: EventParticipantAddTask,
		EventParticipantRemoveTask: EventParticipantRemoveTask,
		EventParticipantUpdateTask: EventParticipantUpdateTask
	};

	/** Indique si la config est prête. */
	private mbIsConfigReady = false;
	private msApplicationDbId: string;

	//#endregion

	//#region PROPERTIES

	/** Observable pour l'ajout de tâches. */
	private get addTask$(): Observable<TaskDescriptor> { return this.moAddTaskSubject.asObservable(); }

	/** Observation du flag d'arrêt du BTS, le flux se ferme lorsque le flag passe à `true`. */
	private get stopBTS$(): Observable<true> {
		return this.isvcFlag.waitForFlag(EFlag.stopBackgroundTaskService, true);
	}

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en base de données. */
		private readonly isvcStore: Store,
		private readonly isvcFlag: FlagService,
		private readonly isvcPlatform: PlatformService
	) {
		this.init();
	}

	/** Renvoie un observable des tâches en attente. */
	public getWaitingTasks$(): Observable<TaskBase[]> {
		return this.moObservableTasksById.changes$.pipe(
			switchMap((poMap: Map<string, TaskBase>) => {
				return this.maObservableAsyncTasksRunning.changes$.pipe(
					map((paRunningTaskIds: string[]) => {
						return MapHelper.valuesToArray(poMap)
							.filter((poTask: TaskBase) => !paRunningTaskIds.includes(poTask.descriptor.id));
					})
				);
			})
		);
	}

	/** Renvoie un observable des tâches en cours d'exécution. */
	public getRunningTasks$(): Observable<TaskBase[]> {
		return this.moObservableTasksById.changes$.pipe(
			switchMap((poMap: Map<string, TaskBase>) => {
				return this.maObservableAsyncTasksRunning.changes$.pipe(
					map((paRunningTaskIds: string[]) => {
						return MapHelper.valuesToArray(poMap)
							.filter((poTask: TaskBase) => paRunningTaskIds.includes(poTask.descriptor.id));
					})
				);
			})
		);
	}

	/** Renvoie les tâches en cours d'exécution ou en attente. */
	public getTasks(): TaskBase[] {
		return MapHelper.valuesToArray(this.moObservableTasksById);
	}

	public getTasks$(): Observable<TaskBase[]> {
		return this.moObservableTasksById.changes$.pipe(
			map((poMap: Map<string, TaskBase>) => MapHelper.valuesToArray(poMap))
		);
	}

	/** Ajoute une tâche au tableau des tâches qu'il faut exécuter en arrière-plan de l'application.
	 * @param poTaskDesc Tâche à ajouter au tableau des tâches qu'il faut exécuter en arrière-plan de l'application.
	 */
	public addTask<T extends ITaskParams>(poTaskDesc: TaskDescriptor<T>): void {
		this.moAddTaskSubject.next(poTaskDesc);
	}

	private onAddTask(): Observable<boolean> {
		return this.addTask$
			.pipe(
				concatMap((poTaskDesc: TaskDescriptor) => {
					const loTaskType: Type<TaskBase> = this.moTaskByClassName[poTaskDesc.taskType];
					const loTask: TaskBase = new loTaskType(poTaskDesc);

					if (isNaN(poTaskDesc.retryInterval))
						poTaskDesc.retryInterval = BackgroundTaskService.C_DEFAULT_RETRY_INTERVAL_MS;

					if (!this.moObservableTasksById.has(poTaskDesc.id))
						return this.runNewTask(loTask);
					else if (this.maObservableAsyncTasksRunning.indexOf(poTaskDesc.id) >= 0)
						return this.reRunTask(poTaskDesc, loTaskType);
					else
						return this.removeTaskById(poTaskDesc.id).pipe(mergeMap(_ => this.add(loTask)));
				}),
				takeUntil(this.stopBTS$)
			);
	}

	/** Lance une nouvelle tâche, retourne `true` si la tâche.
	 * @param poTaskDesc Descripteur de la tâche à exécuter.
	 * @param poTask Tâche à exécuter.
	 */
	private runNewTask(poTask: TaskBase): Observable<boolean> {
		let loWaitFlag$: Observable<boolean>;

		if (StringHelper.isBlank(this.msApplicationDbId) && poTask.descriptor.enableTaskPersistance)
			loWaitFlag$ = this.isvcFlag.waitForFlag(EStoreFlag.DBInitialized, true);
		else
			loWaitFlag$ = of(null);

		return loWaitFlag$
			.pipe(
				mergeMap(_ => this.add(poTask)),
				catchError(poError => { // On ne laisse pas remonter l'erreur pour ne pas faire crasher le BTS.
					console.error(`${BackgroundTaskService.C_LOG_ID}Task ${poTask.descriptor.id} add failed.`, poError);
					return of(false);
				})
			);
	}

	/** Lance la ré-exécution d'une tâche et retourne `true`.
	 * @param poTaskDesc Descripteur de la tâche à ré-exécuter.
	 * @param poTaskType Type de la tâche à ré-exécuter.
	 */
	private reRunTask(poTaskDesc: TaskDescriptor, poTaskType: Type<TaskBase>): Observable<true> {
		console.log(`${BackgroundTaskService.C_LOG_ID}Task ${poTaskDesc.id} added to the tasks to be re-run.`);

		// On stocke des états dans une tâche à réexécuter donc on fait un clone pour ne pas avoir de problèmes de références.
		this.moObservableReexecuteTasksById.set(poTaskDesc.id, new poTaskType(poTaskDesc));

		return of(true);
	}

	private add(poTask: TaskBase): Observable<true> {
		let loSaveOrUpdate$: Observable<IStoreDataResponse>;

		if (poTask.descriptor.enableTaskPersistance && StringHelper.isBlank(poTask.descriptor._rev))
			loSaveOrUpdate$ = this.saveOrUpdTask(poTask.descriptor);
		else
			loSaveOrUpdate$ = of(null);

		return loSaveOrUpdate$
			.pipe(
				tap(_ => this.addAndStartExecution(poTask)),
				mapTo(true)
			);
	}

	/** Ajoute la tâche dans la file et lance l'exécution.
	 * @param loTask Tâche à ajouter.
	 */
	private addAndStartExecution(loTask: TaskBase): void {
		console.debug(`${BackgroundTaskService.C_LOG_ID}Task ${loTask.descriptor.id} saved and added to the tasks to be performed.`);

		this.moObservableTasksById.set(loTask.descriptor.id, loTask);

		if (this.mbIsConfigReady)
			this.execTask(loTask);
	}

	/** Supprime toutes les tâches du tableau des tâches. */
	public clearTasks(): void {
		this.moObservableTasksById.clear();
	}

	/** Logique après qu'une tâche exécutée ne se soit pas déroulée correctement.
	 * @param poError Erreur survenue lors de l'exécution de la tâche.
	 * @param psTaskId Id de la tâche qui a échoué.
	 */
	private onExecTaskError(poError: any, psTaskId: string): Observable<never> {
		console.error(`${BackgroundTaskService.C_LOG_ID}Task ${psTaskId} failed.`, poError);

		const loEvent: IBackgroundTaskEvent = {
			type: EApplicationEventType.BackgroundTaskEvent,
			createDate: new Date(),
			data: { taskId: psTaskId }
		};
		this.raiseBackgroundTaskEvent(loEvent);

		return this.removeTaskById(psTaskId)
			.pipe(catchError(poOtherError => { console.error(BackgroundTaskService.C_LOG_ID, poOtherError); return throwError(() => poOtherError); }))
			.pipe(
				mergeMap(_ => {
					console.warn(`${BackgroundTaskService.C_LOG_ID}Task ${psTaskId} failed and won't be retried (removed).`);
					return throwError(() => poError);
				}),
				finalize(() => ArrayHelper.removeElement(this.maObservableAsyncTasksRunning, psTaskId))
			);
	}

	/** Récupère le descripteur de tâche à partir de l'id d'une tâche (undefined si tâche non trouvée).
	 * @param psTaskId Identifiant de la tâche dont il faut retourner le descripteur.
	 */
	private getTaskDescriptor(psTaskId: string): TaskDescriptor {
		return this.moObservableTasksById.get(psTaskId)?.descriptor;
	}

	/** Exécute les différentes tâches du tableau des tâches de façon parallèle. */
	private execTasks(): void {
		let lbHasElements = false;

		this.moObservableTasksById.forEach((poTask: TaskBase, psId: string) => {
			lbHasElements = true;

			if (this.maObservableAsyncTasksRunning.indexOf(psId) === -1)
				this.execTask(poTask);
		});

		if (!lbHasElements) {
			const loEvent: IBackgroundTaskEvent = {
				type: EApplicationEventType.BackgroundTaskEvent,
				createDate: new Date(),
				data: { taskStatus: EBackgroundTaskEventStatus.Finished }
			};

			this.raiseBackgroundTaskEvent(loEvent);
		}
	}

	/** Exécute une tâche.
	 * @param poTask Tâche à exécuter.
	 */
	private execTask(poTask: TaskBase): void {
		const loEvent: IBackgroundTaskEvent = {
			type: EApplicationEventType.BackgroundTaskEvent,
			createDate: new Date(),
			data: { taskId: poTask.descriptor.id }
		};

		let loTask$: Observable<any> = this.createBaseTask(poTask, loEvent);

		if (NumberHelper.isValidStrictPositive(poTask.descriptor.replayInterval))
			loTask$ = this.defineReplayStrategy(loTask$, poTask);
		if (poTask.descriptor.intervalRepetition)
			loTask$ = this.defineRepeatStrategy(loTask$, poTask);

		poTask.descriptor.subscription = loTask$
			.pipe(
				catchError(poError => this.onExecTaskError(poError, poTask.descriptor.id)),
				mergeMap(poResult => this.onExecTaskSuccess(poTask.descriptor.id, poResult)),
				takeUntil(this.stopBTS$)
			)
			.subscribe();
	}

	private createBaseTask(poTask: TaskBase, poEvent: IBackgroundTaskEvent): Observable<any> {
		return defer(() => {
			let loWaitForExec$: Observable<boolean> = of(true);

			// Stratégie d'attente d'autres flags.
			if (ArrayHelper.hasElements(poTask.descriptor.execAfter)) {
				poTask.descriptor.execAfter.forEach((poExecAfterFlag: IFlag) => {
					loWaitForExec$ = merge( // Merge tous les observables que l'on souhaite attendre.
						loWaitForExec$,
						this.isvcFlag.waitForFlag(poExecAfterFlag.key, poExecAfterFlag.value)
					);
				});

				// Complétion de l'observable quand tous les flags ont la bonne valeurs.
				loWaitForExec$ = loWaitForExec$.pipe(last());
			}

			return loWaitForExec$;
		})
			.pipe(
				tap(_ => {
					console.debug(`${BackgroundTaskService.C_LOG_ID}Task ${poTask.descriptor.id} starting.`);
					this.maObservableAsyncTasksRunning.push(poTask.descriptor.id);
					this.raiseBackgroundTaskEvent(poEvent);
				}),
				mergeMap(_ => poTask.run$()),
				last(), // Plante si pas de résultat
				retryWhen((poErrors$: Observable<any>) => {
					ArrayHelper.removeElement(this.maObservableAsyncTasksRunning, poTask.descriptor.id);
					return poTask.retryStrategy(poErrors$);
				}),
				finalize(() => ArrayHelper.removeElement(this.maObservableAsyncTasksRunning, poTask.descriptor.id))
			);
	}

	private defineRepeatStrategy(poTask$: Observable<any>, poTask: TaskBase): Observable<any> {
		return defer(() => {
			// Si on a un paramétrage pour le mode background, alors on doit écouter les changements d'état.
			if (ObjectHelper.isDefined(poTask.descriptor.backgroundIntervalRepetition)) {
				return merge(
					this.isvcPlatform.onPause$.pipe(mapTo(false)),
					this.isvcPlatform.onResume$.pipe(mapTo(true))
				).pipe(startWith(true));
			}
			return of(true);
		}).pipe(
			switchMap((pbForeground: boolean) => {
				return poTask$
					.pipe( // On définit la routine de répétition pour les tâches cycliques.
						repeatWhen(poResult$ => // Observable qui notifie lors de chaque "complete".
							poResult$.pipe(switchMap((_, pnIndex: number) =>
								race(
									poTask.restart$.pipe(take(1)),
									timer(this.getInterval(
										pbForeground ?
											poTask.descriptor.intervalRepetition :
											(poTask.descriptor.backgroundIntervalRepetition ?? poTask.descriptor.intervalRepetition),
										pnIndex
									))
								).pipe(filter(() => !poTask.isRunning)) // On filtre pour éviter de restart une tâche en cours.
							)) // On vient définir l'observable qui notifie lors de la fin de l'intervalle.
						)
					);
			})
		);
	}

	private getInterval(poIntervalRepetition: number | number[], pnIndex: number): number {
		if (poIntervalRepetition instanceof Array)
			return poIntervalRepetition[pnIndex] ?? ArrayHelper.getLastElement(poIntervalRepetition);
		return poIntervalRepetition;
	}

	private defineReplayStrategy(poTask$: Observable<any>, poTask: TaskBase): Observable<any> {
		return interval(poTask.descriptor.replayInterval) // On force la réexécution
			.pipe(
				startWith(0),
				switchMap(() => poTask$)
			);
	}

	/** Logique après qu'une tâche se soit correctement déroulée : suppression de la tâche si elle n'est pas cyclique sinon, mettre à jour les dates de la tâche.
	 * @params psTaskId Id de la tâche réussie.
	 * @params poResult Résultat de la tâche.
	 */
	private onExecTaskSuccess(psTaskId: string, poResult: any): Observable<TaskDescriptor> {
		const loTaskDescriptor: TaskDescriptor = this.getTaskDescriptor(psTaskId);
		let loSuccess$: Observable<TaskDescriptor>;

		if (loTaskDescriptor) {
			if (ObjectHelper.isDefined(loTaskDescriptor.intervalRepetition) || NumberHelper.isValidStrictPositive(loTaskDescriptor.replayInterval)) {
				loTaskDescriptor.lastExec = new Date();
				loSuccess$ = of(loTaskDescriptor);
			}
			else {
				const loEvent: IBackgroundTaskEvent = {
					type: EApplicationEventType.BackgroundTaskEvent,
					createDate: new Date(),
					data: {
						taskId: psTaskId,
						result: poResult || {}
					}
				};
				this.raiseBackgroundTaskEvent(loEvent);

				loSuccess$ = this.removeTaskById(psTaskId)
					.pipe(
						tap(_ => {
							if (this.moObservableReexecuteTasksById.has(psTaskId)) {
								this.addTask(this.moObservableReexecuteTasksById.get(psTaskId).descriptor);
								this.moObservableReexecuteTasksById.delete(psTaskId);
							}
						})
					);
			}
		}
		else
			loSuccess$ = of(null);

		return loSuccess$
			.pipe(
				tap(_ => {
					console.debug(`${BackgroundTaskService.C_LOG_ID}Task ${loTaskDescriptor.id} executed.`);
					ArrayHelper.removeElement(this.maObservableAsyncTasksRunning, psTaskId);
				})
			);
	}

	/** Initialise le service. Récupère et exécute les tâches persistées en base de données. */
	private init(): void {
		this.isvcFlag.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(tap(_ => this.initAddTasksFromAppStorage()))
			.subscribe();

		this.isvcFlag.waitForFlag(EConfigFlag.StaticConfigReady, true)
			.pipe(
				tap(
					_ => {
						this.mbIsConfigReady = true;
						this.execTasks(); // Démarre le BTS.
					},
					poError => console.error(`${BackgroundTaskService.C_LOG_ID}Init failed.`, poError)
				)
			)
			.subscribe();

		this.onAddTask().subscribe();
	}

	/** Ajoute les tâches stockées dans l'appStorage aux tâches à exécuter. Si la base de données appStorage est initialisée, renvoie 'true', 'false' sinon. */
	private initAddTasksFromAppStorage(): void {
		this.msApplicationDbId = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage));

		const loParams: IDataSource = {
			databaseId: this.msApplicationDbId,
			viewParams: {
				startkey: EPrefix.task,
				endkey: EPrefix.task + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			}
		};

		this.isvcStore.get<TaskDescriptor>(loParams)
			.pipe(
				tap(
					(paResults: TaskDescriptor[]) => {
						const loEvent: IBackgroundTaskEvent = {
							type: EApplicationEventType.BackgroundTaskEvent,
							createDate: new Date(),
							data: { taskId: BackgroundTaskService.C_INIT_TASK_ID }
						};

						if (ArrayHelper.hasElements(paResults))
							paResults.forEach((poTask: TaskDescriptor) => this.addTask(poTask));

						console.debug(`${BackgroundTaskService.C_LOG_ID}Loading of saved tasks performed.`);
						this.raiseBackgroundTaskEvent(loEvent);
					},
					poError => console.error(`${BackgroundTaskService.C_LOG_ID}Error when loading saved tasks.`, poError)
				)
			)
			.subscribe();
	}

	/** Lève un événement du bts.
	 * @param poEvent Événement à lever.
	 */
	private raiseBackgroundTaskEvent(poEvent: IBackgroundTaskEvent): void {
		this.moEventSubject.next(poEvent);
	}

	/** Supprime une tâche du tableau des tâches et la retourne si celle-ci a été supprimée, retourne 'null' si non supprimée.
	 * @param psTaskId Id de la tâche qu'il faut supprimer du tableau des tâches.
	 */
	public removeTaskById<T extends ITaskParams>(psTaskId: string): Observable<TaskDescriptor<T>> {
		const loTaskDescriptor: TaskDescriptor<T> = this.getTaskDescriptor(psTaskId) as TaskDescriptor<T>;

		if (loTaskDescriptor) {
			return defer(() => loTaskDescriptor.enableTaskPersistance ? this.isvcStore.delete(loTaskDescriptor, this.msApplicationDbId) : of(null))
				.pipe(
					catchError(poError => {
						console.error(`${BackgroundTaskService.C_LOG_ID}Task ${psTaskId} deletion failed.`, poError);
						return throwError(() => poError);
					}),
					map(_ => this.removeTaskFromStack(loTaskDescriptor)),
					finalize(() => {
						if (loTaskDescriptor.subscription && !loTaskDescriptor.subscription.closed)
							loTaskDescriptor.subscription.unsubscribe(); // On stoppe l'execution de cette tâche si elle est lancée ou en attente de retry
					})
				);
		}
		else
			return of(null);
	}

	/** Supprime un élément de la pile.
	 * @param poRemoveElement Élément à supprimer.
	 */
	private removeTaskFromStack<T>(poRemoveElement: TaskDescriptor<T>): TaskDescriptor<T> {
		ArrayHelper.removeElement(this.maObservableAsyncTasksRunning, poRemoveElement.id);
		console.debug(`${BackgroundTaskService.C_LOG_ID}Task ${poRemoveElement.id} deleted.`);
		return this.moObservableTasksById.delete(poRemoveElement.id) ? poRemoveElement : null;
	}

	/** Permet de sauvegarder ou de mettre à jour une tâche.
	 * @param poTaskDesc Descripteur de tâche à persister.
	 */
	private saveOrUpdTask(poTaskDesc: TaskDescriptor): Observable<IStoreDataResponse> {
		return this.isvcStore.put<TaskDescriptor>(poTaskDesc, this.msApplicationDbId, true)
			.pipe(catchError(poError => { console.error(`${BackgroundTaskService.C_LOG_ID}Error when saving the ${poTaskDesc.id} task.`, poError); return throwError(() => poError); }));
	}

	/** Permet de s'abonner aux événements des backgroundtasks.
	 * @param pfNext Fonction appelée lors du next => function(poResult: IBackgroundTaskEvent).
	 * @param pfError Fonction appelée lors du error => function(poError: Any).
	 * @param pfComplete Fonction appelée lors du complete.
	 */
	public subscribe(pfNext: Function, pfError?: Function, pfComplete?: Function): void {
		this.moEventSubject.asObservable()
			.subscribe(
				(poResult: IBackgroundTaskEvent) => pfNext(poResult),
				poError => pfError(poError),
				() => pfComplete()
			);
	}

	/** Ajoute un nouveau type de tâche que le service pourra manipuler
	 * @param psId Identifiant du type de tâche à ajouter aux possibles tâches à créer, doit être identique à `poType` pour fonctionner.
	 * @param poType Type de la nouvelle tâche possible à créer.
	 * @returns `true` si l'ajout s'est bien passé, `false` si ce type de tâche existait déjà (on conserve l'ancien type).
	 */
	public addTaskType<T extends TaskBase>(psId: string, poType: Type<T>): boolean {
		if (this.moTaskByClassName[psId])
			return false;
		else {
			this.moTaskByClassName[psId] = poType;
			return true;
		}
	}

	private filterTasksByIds(paTasks: TaskBase[], paIds: string[]): TaskBase[] {
		return paTasks.filter((poTask: TaskBase) => paIds.includes(poTask.descriptor.id));
	}

	public getTasksByIds(paIds: string[]): TaskBase[] {
		return this.filterTasksByIds(this.getTasks(), paIds);
	}

	public getTasksByIds$(paIds: string[]): Observable<TaskBase[]> {
		return this.getTasks$().pipe(
			map((paTasks: TaskBase[]) => this.filterTasksByIds(paTasks, paIds))
		);
	}

	//#endregion

}