import { Injectable } from '@angular/core';
import { defer, EMPTY, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { FileHelper } from '../../../helpers/fileHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EApplicationEventType } from '../../../model/application/EApplicationEventType';
import { ConfigData } from '../../../model/config/ConfigData';
import { EPrefix } from '../../../model/EPrefix';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { EStoreFlag } from '../../../model/store/EStoreFlag';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { ApplicationService } from '../../../services/application.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { PlatformService } from '../../../services/platform.service';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { EFileError } from '../../filesystem/models/efile-error';
import { FilesystemCreateError } from '../../filesystem/models/errors/filesystem-create-error';
import { FilesystemGetFileUriError } from '../../filesystem/models/errors/filesystem-get-file-uri-error';
import { FilesystemRemoveError } from '../../filesystem/models/errors/filesystem-remove-error';
import { FilesystemService } from '../../filesystem/services/filesystem.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { Queue } from '../../utils/queue/decorators/queue.decorator';
import { EDmsImageQuality } from '../EDmsImageQuality';
import { DmsFile } from '../model/DmsFile';
import { IDms } from '../model/IDms';
import { IDmsProgressEvent } from '../model/idms-progress-event';
import { IDmsData } from '../model/IDmsData';
import { IDmsEvent } from '../model/IDmsEvent';
import { IDmsEventData } from '../model/IDmsEventData';
import { IDmsMeta } from '../model/IDmsMeta';
import { DmsMetaService } from './dms-meta.service';

/** Service de gestion des fichiers sur le système de fichier en mode local uniquement. */
@Injectable({ providedIn: "root" })
export class LocalDmsService extends DestroyableServiceBase implements IDms {

	//#region FIELDS

	/** "DMS" */
	public static readonly C_DMS = "DMS";
	/** Identifiant de log pour le service. */
	private static readonly C_LOG_ID = "LDMS.S::";

	/** Identifiant de la base de données du DMS. */
	private C_DMS_DATABASE_ID: string;

	/** Sujet pour l'envoi d'événement. */
	private moEventSubject = new Subject<IDmsEvent>();

	//#endregion

	//#region METHODS

	constructor(
		/** Service des requêtes en base de données. */
		private isvcStore: Store,
		private readonly isvcFilesystem: FilesystemService,
		/** Service de gestion des popups et toasts. */
		private isvcUiMessage: UiMessageService,
		private isvcPlatorm: PlatformService,
		psvcApplication: ApplicationService,
		private isvcDmsMeta: DmsMetaService
	) {
		super();
		this.init(psvcApplication);
	}

	/** @implements */
	public execUploads(paPendings: IStoreDocument[], pbHasToContinueIfFail: boolean): Observable<string[]> {
		return of([]);
	}

	/** @implements */
	public getPendingDocs(pePrefix: EPrefix, pbLive: boolean): Observable<IStoreDocument[]> {
		return of([]);
	}

	/** Initialisation du service qui se sert de données de config.
	 * @param psvcApplication Service de gestion de l'application.
	 */
	private init(psvcApplication: ApplicationService): void {
		psvcApplication.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(
				tap(_ => this.C_DMS_DATABASE_ID = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.dmsStorage))),
				mergeMap(_ => {
					this.initPaths();

					if (this.isvcPlatorm.isMobileApp) {
						return this.initDirectoryCreation(ConfigData.environment.dms.applicationPath)
							.pipe(mergeMap(__ => this.initDirectoryCreation(ConfigData.environment.dms.publicPath)));
					}
					else {
						console.warn(`${LocalDmsService.C_LOG_ID}Local DMS initialization failed: unsupported "${this.isvcPlatorm.model}" model / "${this.isvcPlatorm.platform}" platform.`);
						return EMPTY;
					}
				}),
				tap(_ => this.raiseReadyEvent())
			)
			.subscribe();
	}

	/** Initialise les chemins du dms local et retourne l'objet contenant ces chemins, `undefined` si pas de dms local. */
	private initPaths(): void {
		if (this.isvcPlatorm.isMobileApp) {
			ConfigData.environment.dms.applicationPath = `${LocalDmsService.C_DMS}/`;
			ConfigData.environment.dms.publicPath = `${ConfigData.appInfo.appName}/`;
		}
	}

	/** Crée un répertoire du DMS lors de l'initialisation du service.
	 * @param psPath Chemin d'accès jusqu'au dossier qu'on veut créer.
	 */
	private initDirectoryCreation(psPath?: string): Observable<void> {
		return defer(() => {
			if (StringHelper.isBlank(psPath))
				return EMPTY;
			return this.isvcFilesystem.createDirectoryAsync(psPath)
		})
			.pipe(
				catchError((poError: FilesystemCreateError) => {
					if (poError.errorCode !== EFileError.directoryAlreadyExist) {
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: `Erreur lors de la création du dossier ${psPath}.`, header: "Erreur" }));

						console.error(`${LocalDmsService.C_LOG_ID}Erreur création répertoire '${psPath}' :`, poError);
						return throwError(() => poError);
					}
					else
						return of(undefined);
				})
			);
	}

	/** Envoie un événement pour notifier que le dmsLocal est prêt. */
	private raiseReadyEvent(): void {
		this.moEventSubject.next(this.createIDmsEvent({ isDmsReady: true }));
	}

	/** Crée et retourne un événement du DMS.
	 * @param poData Données de l'événement.
	 */
	private createIDmsEvent(poData: IDmsEventData): IDmsEvent {
		return {
			type: EApplicationEventType.DmsEvent,
			createDate: new Date(),
			data: poData
		};
	}

	/** Supprime le dossier qui contient le fichier portant le même identifiant.
	 * @param psId Identifiant du fichier à supprimer.
	 */
	public delete(psId: string): Observable<boolean> {
		return defer(() => this.isvcFilesystem.removeAsync(`${ConfigData.environment.dms.applicationPath}${psId}`))
			.pipe(
				catchError((poError: FilesystemGetFileUriError | FilesystemRemoveError) => {
					switch (poError.error.errorCode) {
						case EFileError.directoryNotFound: // Si dossier non trouvé car déjà supprimé ou jamais téléchargé.
						case EFileError.fileNotFound: // Si fichier non trouvé car déjà supprimé ou jamais téléchargé.
							return of(true); // On continue normalement.

						default:
							return throwError(() => poError); // Sinon on lève une erreur.
					}
				}),
				mergeMap(_ => this.isvcStore.delete(psId, this.C_DMS_DATABASE_ID, undefined, false)),
				map((poResult: IStoreDataResponse) => poResult.ok)
			);
	}

	/** Récupération d'un fichier et ses méta stockés en base de données à partir d'un identifiant.
	 * @param psId Identifiant du fichier à récupérer.
	 * @param pnQuality Pas pris en compte.
	 * @param pfOnProgress Pas pris en compte.
	 */
	public get(psId: string, pnQuality: number = EDmsImageQuality.natural, pfOnProgress?: (poEvent: IDmsProgressEvent) => void): Observable<IDmsData> {
		return this.getMeta(psId)
			.pipe(
				catchError(poError => throwError(() => poError)),
				map((poResult: IDmsMeta) => {
					const loDmsFile: DmsFile = poResult ? new DmsFile(poResult) : null;
					return { file: loDmsFile, meta: poResult } as IDmsData;
				})
			);
	}

	/** Récupération d'un document de méta du DMS à partir de son identifiant, `null` si non trouvé.
	 * @param psId Identifiant du DmsMeta à récupérer.
	 */
	public getMeta(psId: string): Observable<IDmsMeta> {
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.dmsStorage),
			viewParams: {
				key: psId,
				include_docs: true
			}
		};

		return this.isvcStore.getOne<IDmsMeta>(loParams, false)
			.pipe(catchError(poError => { console.error(`${LocalDmsService.C_LOG_ID}Erreur récupération document dmsMeta ${psId} : `, poError); return throwError(() => poError); }));
	}

	/** Récupération d'un tableau de document de méta du DMS à partir d'un tableau d'identifiants, `null` si non trouvé.
	 * @param paIds Tableau d'identifiants du DmsMeta à récupérer.
	 */
	public getMetas(paIds: string[]): Observable<IDmsMeta[]> {
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.dmsStorage),
			viewParams: {
				keys: paIds,
				include_docs: true
			}
		};

		return this.isvcStore.get<IDmsMeta>(loParams)
			.pipe(tap(_ => { }, poError => console.error(`${LocalDmsService.C_LOG_ID}Erreur récupération tableau documents dmsMeta ${paIds.join(", ")} : `, poError)));
	}

	/** Enregistre un objet DmsFile dans le dossier DMS du système de fichiers.
	 * @param poData Objet contenant le fichier sous forme de Blob ou base64 ainsi que son nom, sa taille, son mimeType.
	 * @param poMeta Méta du fichier à enregistrer.
	 * @param pfOnProgress Pas pris en compte.
	 */
	@Queue<LocalDmsService, Parameters<LocalDmsService["save"]>, ReturnType<LocalDmsService["save"]>>({ idBuilder: (__, poMeta: IDmsMeta) => poMeta._id })
	public save(poData: DmsFile, poMeta: IDmsMeta, pfOnProgress?: (poEvent: IDmsProgressEvent) => void): Observable<IDmsMeta> {
		return defer(() => this.saveFile(this.getFilePath(poMeta), poData, poMeta))
			.pipe(
				catchError((poError: any) => {
					console.error(`${LocalDmsService.C_LOG_ID}Erreur d'enregistrement du fichier ${poMeta.name} :`, poError);
					return throwError(() => poError);
				})
			);
	}

	public getFilePath(poMeta: IDmsMeta): string {
		const lsFileExtension: string | undefined = FileHelper.getFileExtensionFromFileName(poMeta.name);
		const lsFileName: string = lsFileExtension ? `${poMeta._id}.${lsFileExtension}` : poMeta._id;
		return `${ConfigData.environment.dms.applicationPath}${poMeta._id}/${lsFileName}`;
	}

	/** Enregistre un fichier base64 et son méta associé sur le système de fichiers.
	 * @param psResultPath Chemin vers le dossier nouvellement créé.
	 * @param poData Blob ou objet contenant le fichier sous forme de base64 ainsi que son nom, sa taille, son mimeType.
	 * @param poDmsMeta Objet méta associé au fichier à enregistrer.
	 */
	private saveFile(psResultPath: string, poData: DmsFile, poDmsMeta: IDmsMeta): Observable<IDmsMeta> {
		return defer(() => { poDmsMeta.saved = false; return of(null); })
			.pipe(
				mergeMap(_ => this.isvcDmsMeta.saveLocalDocumentMeta(poDmsMeta, this.C_DMS_DATABASE_ID)),
				mergeMap(_ => this.isvcFilesystem.createFileAsync(psResultPath, poData.File, undefined, true)),
				tap((psPath: string) => {
					poData.Path = psPath;
					poDmsMeta.saved = true;
				}),
				mergeMap(_ => this.isvcDmsMeta.saveLocalDocumentMeta(poDmsMeta, this.C_DMS_DATABASE_ID)),
				tap((poResult: IDmsMeta) => {
					console.debug(`${LocalDmsService.C_LOG_ID}metadocument '${poResult._id}' SAVED`);
					this.moEventSubject.next(this.createIDmsEvent({ fileId: poResult._id, dmsFile: new DmsFile(poData.File, poDmsMeta), dmsMeta: poResult }));
				})
			);
	}

	/** Permet de s'abonner aux événements du LocalDmsService.
	 * @param pfNext Fonction appelée lors du next => function(poResult: IDmsEvent).
	 * @param pfError Fonction appelée lors du error => function(poError: Any).
	 */
	public subscribe(pfNext: Function, pfError?: Function): void {
		this.moEventSubject.asObservable()
			.subscribe(
				(poResult: IDmsEvent) => pfNext(poResult),
				poError => pfError(poError)
			);
	}

	/** Récupère toutes les données des fichiers présents dans le système de fichiers de l'appareil.
	 * @param pbLive Indique si on fait une récupération continue ou non, `false` par défaut.
	 */
	public getLocalMetaDataFiles(pbLive: boolean = false): Observable<IDmsMeta[]> {
		const loDatasouce: IDataSource = {
			databaseId: this.C_DMS_DATABASE_ID,
			viewParams: {
				startkey: EPrefix.dms,
				endkey: `${EPrefix.dms}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
			live: pbLive
		};

		return this.isvcStore.get<IDmsMeta>(loDatasouce)
			.pipe(
				mergeMap((paLocalMetaDocs: IDmsMeta[]) => from(paLocalMetaDocs).pipe(
					mergeMap(async (poMeta: IDmsMeta) => { // Le mergeMap imbriqué oblique la fermeture du flux lorsque l'array est parcourue intégralement.
						if (await this.isvcFilesystem.existsAsync(this.getFilePath(poMeta))) // Teste l'existence du fichier sur l'appareil
							return poMeta;
						else
							return undefined;
					}),
					filter((poMeta?: IDmsMeta) => !!poMeta), // Conserve uniquement les fichiers existants
					toArray()
				))
			);
	}

	//#endregion
}
