import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogOutGuard } from '@calaosoft/osapp/guards/log-out.guard';
import { NotAuthenticatedGuard } from '@calaosoft/osapp/guards/not-authenticated.guard';
import { PageInfo } from '@calaosoft/osapp/model/PageInfo';
import { CanActivateGuardsScheduler } from '@calaosoft/osapp/modules/routing/models/can-activate-guards-scheduler';
import { ConfigurableRemoveCredentialsGuard } from '@calaosoft/osapp/modules/security/guards/parametrable-remove-credentials.guard';
import { RemoveCredentialsGuard } from '@calaosoft/osapp/modules/security/guards/remove-credentials.guard';

const RESET_PASSWORD_REQUEST_PAGE_INFO = new PageInfo({
	hasBackButton: true,
	hasHomeButton: false
});

const RESET_PASSWORD_PAGE_INFO = new PageInfo({
	hasBackButton: true,
	hasHomeButton: false
});

export const routes: Routes = [
	{
		path: 'authenticator',
		loadChildren: () => import('./authentication/authentication-page.module').then(poModule => poModule.AuthenticationPageModule),
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: 'disconnect',
		loadChildren: () => import('./authentication/authentication-page.module').then(poModule => poModule.AuthenticationPageModule),
		//! On doit séquentialiser et appeler `LogOutGuard` en second car sinon on navigue directement et les autres gardiens ne sont pas activés.
		canActivate: [CanActivateGuardsScheduler.schedule([ConfigurableRemoveCredentialsGuard, LogOutGuard])]
	},
	{
		path: 'invalidate-session',
		loadChildren: () => import('@calaosoft/osapp/modules/authentication/authentication.module').then(poModule => poModule.AuthenticationModule),
		//! On doit séquentialiser et appeler `LogOutGuard` en second car sinon on navigue directement et les autres gardiens ne sont pas activés.
		canActivate: [CanActivateGuardsScheduler.schedule([RemoveCredentialsGuard, LogOutGuard])]
	},
	{
		path: 'signup',
		loadChildren: () => import('@calaosoft/osapp/modules/signup').then(poModule => poModule.SignupModule),
		canActivate: [NotAuthenticatedGuard]
	},

	{
		path: 'password',
		children: [
			{
				path: 'create-password',
				loadChildren: () => import('@calaosoft/osapp/modules/password/create-password/create-password.module').then(poModule => poModule.CreatePasswordPageModule)
			},
			{
				path: 'reset-password-request',
				loadChildren: () => import('@calaosoft/osapp/modules/password/reset-password-request-page/reset-password-request-page.module').then(poModule => poModule.ResetPasswordRequestPageModule),
				data: {
					pageInfo: RESET_PASSWORD_REQUEST_PAGE_INFO
				}
			},
			{
				path: 'reset-password',
				loadChildren: () => import('@calaosoft/osapp/modules/password/reset-password-page/reset-password-page.module').then(poModule => poModule.ResetPasswordPageModule),
				data: {
					pageInfo: RESET_PASSWORD_PAGE_INFO
				}
			}
		]
	},
	{
		path: '',
		loadChildren: () => import('./tab-bar/tab-bar.module').then(m => m.TabBarPageModule)
	},
	{
		path: 'guest-access/event',
		loadChildren: () => import('./guest-access/pages/guest-event-access.module').then(m => m.GuestAccessPageModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule { }