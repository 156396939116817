import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { plainToClass } from "class-transformer";
import { filter, firstValueFrom, from, map, mergeAll, mergeMap, Observable, of, switchMap, take, takeUntil, tap, toArray } from 'rxjs';
import { ContactsListComponent } from "../../../../../../components/contacts/contactsList/contactsList.component";
import { ArrayHelper } from "../../../../../../helpers/arrayHelper";
import { IdHelper } from "../../../../../../helpers/idHelper";
import { StringHelper } from "../../../../../../helpers/stringHelper";
import { UserData } from "../../../../../../model/application/UserData";
import { IContact } from "../../../../../../model/contacts/IContact";
import { IContactsSelectorParams } from "../../../../../../model/contacts/IContactsSelectorParams";
import { IGroup } from "../../../../../../model/contacts/IGroup";
import { IGroupMember } from "../../../../../../model/contacts/IGroupMember";
import { EPrefix } from "../../../../../../model/EPrefix";
import { EDatabaseRole } from "../../../../../../model/store/EDatabaseRole";
import { IDataSource } from "../../../../../../model/store/IDataSource";
import { IStoreDocument } from "../../../../../../model/store/IStoreDocument";
import { ContactsService } from "../../../../../../services/contacts.service";
import { EntityLinkService } from "../../../../../../services/entityLink.service";
import { GroupsService } from "../../../../../../services/groups.service";
import { Store } from "../../../../../../services/store.service";
import { Contact } from "../../../../../contacts/models/contact";
import { Entity } from "../../../../../entities/models/entity";
import { EntityLink } from "../../../../../entities/models/entity-link";
import { EntityLinkEntity } from "../../../../../entities/models/entity-link-entity";
import { EntitiesService } from "../../../../../entities/services/entities.service";
import { ISector } from "../../../../../sectors/models/isector";
import { ESelectorDisplayMode } from "../../../../../selector/selector/ESelectorDisplayMode";
import { secure } from "../../../../../utils/rxjs/operators/secure";

// TODO : Réintégrer ce composant dans Trade une fois que le problème de champ de formulaire spécifique à une application aura été réglé
// Dans ce cadre supprimer les fonctions private de cette classe et utiliser les services de Trade.
@Component({
	selector: 'sector-businesses-list',
	templateUrl: './sector-businesses-list.component.html',
	styleUrls: ['./sector-businesses-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorBusinessesListComponent extends ContactsListComponent {

	constructor(
		protected override readonly isvcEntities: EntitiesService,
		protected override readonly isvcContacts: ContactsService,
		public override ioRouter: Router,
		public isvcGroup: GroupsService,
		public isvcEntityLink: EntityLinkService,
		public ioChangeDetector: ChangeDetectorRef,
		private readonly ioActivatedRoute: ActivatedRoute,
		private readonly isvcStore: Store,
	) {
		super(isvcContacts, isvcEntities, ioRouter, isvcGroup, ioChangeDetector);
	}

	/** Ouvre le sélecteur de contacts. */
	public override selectContacts(): void {
		this.isvcEntityLink.getLinkedEntities(`${"sect_"}${this.params._modelId}`, "sctrz_" as EPrefix)
			.pipe(
				mergeMap(
					(poIStoreDoc: Entity[]) => {
						if (poIStoreDoc.length)
							return this.isvcEntityLink.getLinkedEntities(poIStoreDoc, "sect_" as EPrefix)
								.pipe(
									mergeMap((poMap: Map<string, IStoreDocument[]>) => from(poMap.get(poIStoreDoc[0]._id) ?? [])),
								)
						else
							return this.getSectorization$(
								IdHelper.buildId("sctrz_" as EPrefix, this.ioActivatedRoute.snapshot.params.entityGuid), false
							).pipe(
								take(1),
								filter((poSctrz: any | undefined) => !!poSctrz?.linkedSectors?.length),
								mergeMap((poSctrz: any) => from(poSctrz.linkedSectors ?? [])),
							)
					}
				),
				mergeMap((poStoreDocument: IStoreDocument) =>
					this.isvcEntityLink.getEntityLinks(poStoreDocument._id, this.params.contactsSelectorParams?.prefix ? [this.params.contactsSelectorParams.prefix] : [])
				),
				mergeAll(),
				toArray(),
				map((paEttLinks: Array<EntityLink>) => paEttLinks
					.map((poEttLink: EntityLink): string | undefined => poEttLink.entities
						.find((poEttLinkEtt: EntityLinkEntity) => this.params.contactsSelectorParams?.prefix && poEttLinkEtt.id.startsWith(this.params.contactsSelectorParams.prefix)
						)?.id
					)
				),
				filter((paIds: Array<string | undefined>) => !!paIds),
				map((paBizIds: string[]) => ArrayHelper.unique(paBizIds)),
				secure(this),
				mergeMap((paBizIds: string[]) => {

					const laOldContacts: IContact[] = this.getContactsFromContactsWithAvailability();

					const lsSiteId: string | undefined = this.params.contactsContainer?._id?.includes(EPrefix.group) ? (this.params.contactsContainer as ISector).siteId : undefined;
					let loContactsSelectorParams: IContactsSelectorParams;
					if (this.params._modelId)
						loContactsSelectorParams = {
							...this.params.contactsSelectorParams,
							preSelectedIds: laOldContacts.map((poContact: IContact) => poContact._id),
							disableItemFunction: (i) => {
								return paBizIds.includes(i._id);
							},
							siteIds: !StringHelper.isBlank(lsSiteId) ? [lsSiteId] : undefined
						};
					else
						loContactsSelectorParams = {
							...this.params.contactsSelectorParams,
							preSelectedIds: laOldContacts.map((poContact: IContact) => poContact._id),
							siteIds: !StringHelper.isBlank(lsSiteId) ? [lsSiteId] : undefined
						};
					let loGetContacts$: Observable<IGroupMember[]>;
					let loGetGroups$: Observable<IGroupMember[]> = of([]);

					if (loContactsSelectorParams.hasGroupSelector && loContactsSelectorParams.roles && loContactsSelectorParams.roles?.length > 0) {
						loGetGroups$ = this.isvcGroups.getGroupsByRoles(loContactsSelectorParams.roles)
							.pipe(
								tap((paGroups: IGroup[]) => {
									loContactsSelectorParams.groupFilterParams = {
										options: paGroups.map((poGroup: IGroup) => ({ label: poGroup.name, value: poGroup })),
										displayMode: ESelectorDisplayMode.tags
									};
								})
							);
					};

					// Si on est en multi-workspaces OU que l'utilisateur n'a pas de bases de données de workspaces.
					if (this.params.isMultiWorkspaces || (UserData.current && !ArrayHelper.hasElements(UserData.current.workspaceInfos)))
						loGetContacts$ = this.isvcContacts.openContactsSelectorAsModal(loContactsSelectorParams, this.params.pageTitle);
					else { // Cas mono-workspace.
						loGetContacts$ = this.isvcContacts.openContactsSelectorAsModalWithWorkspacePreSelection(
							loContactsSelectorParams,
							this.params.contactsContainer,
							this.params.pageTitle
						);
					}

					return loGetGroups$
						.pipe(
							mergeMap(() => loGetContacts$),
							filter((paContacts: IContact[]) => !!paContacts), // Ne prend en compte que si on a validé la sélection de contacts.
							mergeMap((paContacts: IContact[]) => {
								return this.setModel(paContacts);
							}),
							takeUntil(this.destroyed$)
						);
				})
			)
			.subscribe();
	}


	/** Réccupère une sectorisation en fonction de son id.
	* @param psSctrzId id de la sectorisation à reccupérer.
	*/
	private getSectorization$(psSctrzId: string, pbLive?: boolean): Observable<any | undefined> {
		const loDataSource: IDataSource<IStoreDocument> = {
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psSctrzId,
				include_docs: true
			},
			live: pbLive
		};
		return this.isvcStore.getOne<any>(loDataSource, false).pipe(
			switchMap((poSctrz: any | undefined) => {
				if (poSctrz) {
					return this.isvcEntityLink.getLinkedEntities([poSctrz], "sect_" as EPrefix, undefined, true)
						.pipe(mergeMap(_ => this.fillSectorsAsync(poSctrz)));
				}
				else
					return of(undefined);
			})
		);
	}


	/** Remplit les secteurs d'une sectorisation. */
	private async fillSectorsAsync(poSctrz: any): Promise<any> {
		poSctrz.linkedSectors = [];

		poSctrz.linkedSectors = await this.addLinksAsync(poSctrz.linkedSectors);

		return poSctrz;
	}


	/** Définit les liens des secteurs avec les entités.
	 * @param paSectors Liste des secteurs.
	 */
	public async addLinksAsync(paSectors: any[]): Promise<any[]> {
		const loLinksBySectorId: Map<string, IStoreDocument[]> = await this.getLinkedEntitiesAsync(paSectors, ["biz_" as EPrefix, EPrefix.contact]);
		paSectors.forEach((poSector: any) => {
			poSector.linkedContacts = plainToClass(
				Contact,
				loLinksBySectorId.get(poSector._id)?.filter((poDocument: IStoreDocument) =>
					poDocument._id.startsWith(EPrefix.contact)) ?? []
			);

			poSector.linkedBusinesses =
				loLinksBySectorId.get(poSector._id)?.filter((poDocument: IStoreDocument) =>
					poDocument._id.startsWith("biz_") ?? []
				);
		});
		return paSectors;
	}

	/** Récupère les entités liées à un secteur.
 * @param paSectors Secteurs à qui on va réccupérer les liens.
 * @param paPrefixes Prefix des types de lien voulus.
 * @returns Une promesse avec une map des entités liées par secteur.
 */
	private getLinkedEntitiesAsync(paSectors: any[], paPrefixes: EPrefix[]): Promise<Map<string, IStoreDocument[]>> {
		return firstValueFrom(this.isvcEntityLink.getLinkedEntities(paSectors.map((poSector: any) => poSector._id), paPrefixes));
	}


	//#endregion
}