import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { ISectorsListFieldParams } from '../../../../model/forms/ISectorsListFieldParams';
import { EntityLinkService } from '../../../../services/entityLink.service';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { ISector } from '../../models/isector';
import { SectorsService } from '../../services/sectors.service';

/** Composant pour lister des groupes avec une case à cocher. */
@Component({
	selector: "calao-form-sectors-list",
	templateUrl: './form-sectors-list.component.html',
	styleUrls: ['./form-sectors-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSectorsList extends DestroyableComponentBase {

	//#region PROPERTIES

	@Input() public params: ISectorsListFieldParams;

	private maSectors: ISector[] = [];
	@Input() public set sectors(paSectors: ISector[]) {
		if (!ArrayHelper.areArraysEqual(paSectors, this.maSectors)) {
			if (!(typeof ArrayHelper.getFirstElement(paSectors) === "string"))
				this.maSectors = paSectors;
		};
	}
	public get sectors(): ISector[] { return this.maSectors; }

	@Output("onSectorsChanged") public readonly sectorsChangeEventEmitter: EventEmitter<ISector[]> = new EventEmitter();

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcSectors: SectorsService,
		private readonly isvcEntityLinks: EntityLinkService,
		private readonly isvcUiMessage: UiMessageService
	) {
		super();
	}

	public routeToSector(poSector: ISector): void {
		this.isvcSectors.routeToSector(poSector);
	}

	public routeToSectorEdition(poSector: ISector): void {
		this.isvcSectors.routeToSectorEdition(poSector);
	}

	public onRemove(poSector: ISector): void {
		this.isvcEntityLinks.getLinkedEntityIds(poSector._id)
			.pipe(
				map((paLinkedIds: string[]) => paLinkedIds.length === 0),
				tap((pbCanRemove: boolean) => {
					if (pbCanRemove) {
						ArrayHelper.removeElement(this.sectors, poSector);
						this.sectorsChangeEventEmitter.next(this.sectors);
					}
					else
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Plusieurs contacts sont liés à ce secteur.", header: "Suppression interdite" }));
				})
			)
			.subscribe();
	}

	public createSector(): void {
		if (!StringHelper.isBlank(this.params.siteId))
			this.isvcSectors.routeToSectorCreation(this.params.siteId);
	}

	//#endregion

}