import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EMPTY, Observable, combineLatest, defer } from 'rxjs';
import { catchError, concatMap, finalize, map, scan, skip, takeUntil, takeWhile, tap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { Database } from '../../../../model/store/Database';
import { IUiResponse } from '../../../../model/uiMessage/IUiResponse';
import { ApplicationService } from '../../../../services/application.service';
import { BackgroundTaskService } from '../../../../services/backgroundTask.service';
import { TaskBase } from '../../../../services/backgroundTask/TaskBase';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { PlatformService } from '../../../../services/platform.service';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { ModalComponentBase } from '../../../modal/model/ModalComponentBase';
import { NoOnlineReliableNetworkError } from '../../../network/models/errors/NoOnlineReliableNetworkError';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { EDatabaseSyncStatus } from '../../../store/model/EDatabaseSyncStatus';
import { IResetDatabasesResult } from '../../../store/model/IResetDatabasesResult';
import { NoDatabaseLocalInstanceError } from '../../../store/model/errors/NoDatabaseLocalInstanceError';
import { NoDatabaseRemoteInstanceError } from '../../../store/model/errors/NoDatabaseRemoteInstanceError';
import { IPouchDBFailedToFetchError } from '../../../store/model/errors/ipouchdb-failed-to-fetch-error';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { afterSubscribe } from '../../../utils/rxjs/operators/after-subscribe';
import { minimumGap } from '../../../utils/rxjs/operators/minimum-gap';
import { secure } from '../../../utils/rxjs/operators/secure';
import { IDatabaseGroupingConfiguration } from '../../model/IDatabaseGroupingConfiguration';
import { IDatabaseSyncStatus } from '../../model/IDatabaseSyncStatus';
import { ResetDatabasesError } from '../../model/errors/ResetDatabasesError';
import { DatabaseSynchroService } from '../../services/database-synchro.service';
import { DatabasesSyncStatusModalDetailViewComponent } from '../databases-sync-status-modal-detail-view/databases-sync-status-modal-detail-view.component';

@Component({
	selector: 'calao-databases-sync-status-modal-main-view',
	templateUrl: './databases-sync-status-modal-main-view.component.html',
	styleUrls: ['./databases-sync-status-modal-main-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatabasesSyncStatusModalMainViewComponent extends ModalComponentBase<boolean> {

	//#region FIELDS

	private static readonly C_LOG_ID = "SYNCSTAT.C::";
	private static readonly C_DEFAULT_SYNC_TEXT = "Synchroniser";

	//#endregion

	//#region PROPERTIES

	public readonly detailComponent = DatabasesSyncStatusModalDetailViewComponent;

	public readonly databaseSyncStatuses$: Observable<IDatabaseSyncStatus[]>;

	private static readonly moSyncText = new ObservableProperty<string>(DatabasesSyncStatusModalMainViewComponent.C_DEFAULT_SYNC_TEXT);
	public syncText$: Observable<string | undefined> =
		DatabasesSyncStatusModalMainViewComponent.moSyncText.value$.pipe(secure(this));

	private static readonly moIsBusy = new ObservableProperty<boolean>();
	/** Indique si des actions sont en cours (pour bloquer les autres actions tant que l'actuelle n'est pas terminée). */
	public isBusy$: Observable<boolean | undefined> =
		DatabasesSyncStatusModalMainViewComponent.moIsBusy.value$.pipe(secure(this));

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcDatabaseSynchro: DatabaseSynchroService,
		private readonly isvcBackgroundTask: BackgroundTaskService,
		private readonly isvcUiMessage: UiMessageService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);

		this.databaseSyncStatuses$ = this.getDatabaseSyncStatusesFromConfigs$().pipe(takeUntil(this.destroyed$));
	}

	private getDatabaseSyncStatusesFromConfigs$(): Observable<IDatabaseSyncStatus[]> {
		return combineLatest([
			...this.isvcDatabaseSynchro.getDatabasesGroupingConfigs()
				.map((poConfig: IDatabaseGroupingConfiguration) => this.getDatabaseSyncStatusFromConfig$(poConfig)),
			this.getDatabaseSyncStatusFromDms$()
		]);
	}

	private getDatabaseSyncStatusFromConfig$(poConfig: IDatabaseGroupingConfiguration): Observable<IDatabaseSyncStatus> {
		return this.isvcDatabaseSynchro.getSyncStatus$(poConfig.roles)
			.pipe(
				map((peDatabasesSyncStatus: EDatabaseSyncStatus): IDatabaseSyncStatus => {
					return { title: poConfig.title, description: poConfig.description, status: peDatabasesSyncStatus };
				})
			);
	}

	private getDatabaseSyncStatusFromDms$(): Observable<IDatabaseSyncStatus> {
		return this.isvcDatabaseSynchro.getDmsSyncStatus$()
			.pipe(
				map((peDatabaseSyncStatus: EDatabaseSyncStatus): IDatabaseSyncStatus => {
					return { ...this.isvcDatabaseSynchro.getDmsSyncConfig(), status: peDatabaseSyncStatus };
				})
			);
	}

	//#region Synchronisations

	public syncDatabases(): void {
		this.synchronize$().subscribe();
	}

	@Queue({ paramsReducer: (_, paNewArgs: any[]) => paNewArgs })
	private synchronize$(): Observable<number[][]> {
		const laTasks: TaskBase[] = this.getSyncTasks();

		return defer(() => {
			DatabasesSyncStatusModalMainViewComponent.moIsBusy.value = true;
			return laTasks;
		}).pipe(
			concatMap((poTask: TaskBase, pnIndex: number) => {
				return this.processTask$(poTask, pnIndex, laTasks);
			}),
			toArray(),
			finalize(() => {
				DatabasesSyncStatusModalMainViewComponent.moSyncText.value = DatabasesSyncStatusModalMainViewComponent.C_DEFAULT_SYNC_TEXT;
				DatabasesSyncStatusModalMainViewComponent.moIsBusy.value = false;
			}),
			catchError((poError: NoOnlineReliableNetworkError | any) => {
				this.showSynchronizeError(poError);
				return EMPTY;
			})
		);
	}

	private processTask$(poTask: TaskBase, pnIndex: number, paTasks: TaskBase[]): Observable<number[]> {
		const lnSkip: number = poTask.isRunning ? 0 : 1; // Si la tâche est arrêtée, on va recevoir un évènement avec un avancement à 100% dès l'abonnement, donc on le skip.

		return poTask.progress$
			.pipe(
				afterSubscribe(() => poTask.sendRestartEvent()),
				skip(lnSkip),
				scan((paProgresses: number[], pnProgress: number) => {
					paProgresses.push(pnProgress);
					return paProgresses;
				}, []),
				minimumGap(500, true),
				tap((paProgresses: number[]) =>
					this.updateSyncText(ArrayHelper.getLastElement(paProgresses) ?? 1, pnIndex, paTasks.length)
				),
				takeWhile((paProgresses: number[]) => ArrayHelper.getLastElement(paProgresses) !== 1)
			);
	}

	private getSyncTasks(): TaskBase[] {
		const laDatabases: Database[] = this.isvcDatabaseSynchro.getGroupingConfigsDatabases();
		const laTaskIds: string[] = this.isvcDatabaseSynchro.getSyncTaskIds(laDatabases);

		return this.isvcBackgroundTask.getTasksByIds(laTaskIds);
	}

	private updateSyncText(pnProgress: number, pnIndex: number, pnTotal: number): void {
		DatabasesSyncStatusModalMainViewComponent.moSyncText.value = `${(((pnProgress + pnIndex) / pnTotal) * 100).toFixed(1)}%`;
	}

	private showSynchronizeError(poError: NoOnlineReliableNetworkError | any): void {
		let lsErrorMessage: string;

		if (poError instanceof NoOnlineReliableNetworkError)
			lsErrorMessage = poError.message;
		else
			lsErrorMessage = "La synchronisation des données n'a pu aboutir.<br/>Si le problème persiste, veuillez contacter le support technique.";

		console.error(`${DatabasesSyncStatusModalMainViewComponent.C_LOG_ID}Database synchro failed.`, poError);

		this.isvcUiMessage.showPopupMessage(
			new ShowMessageParamsPopup({ message: lsErrorMessage, header: "Erreur", buttons: [UiMessageService.createOkButton()] })
		);
	}

	//#endregion

	//#region Optimisations

	/** Optimise (Réinitialise) les bases de données d'espace de travail éligibles (réplication montante + téléchargement dump + suppression bdd locale + chargement dump). */
	public async resetWorkspaceDatabasesAsync(): Promise<void> {
		try {
			const loPopupResult: IUiResponse<boolean> = await this.showResetDatabasesPopupAsync();

			if (loPopupResult.response) { // Si l'utilisateur a confirmé la réinitialisation, on traite.
				const loResetResult: IResetDatabasesResult = await this.isvcDatabaseSynchro.resetDatabasesAsync();

				// Si au moins une base n'a pas été optimisée, on lève une erreur.
				if (loResetResult.successCount !== loResetResult.totalCount) {
					throw new ResetDatabasesError(
						`${loResetResult.failedCount} ${loResetResult.failedCount > 1 ? "bases de données n'ont pas pu être optimisées" : "base de données n'a pas pu être optimisée"}.<br/>Si le problème persiste, veuillez contacter le support technique.`
					);
				}
				else
					ApplicationService.reloadApp();
			}
			// Sinon on ne fait rien.
		}
		catch (poError: NoOnlineReliableNetworkError | NoDatabaseLocalInstanceError | NoDatabaseRemoteInstanceError | ResetDatabasesError | IPouchDBFailedToFetchError | any) {
			await this.showResetDatabasesFailedPopupAsync(poError);

			// On recharge l'app seulement si c'est pas un problème de connexion internet.
			if (!(poError instanceof NoOnlineReliableNetworkError))
				ApplicationService.reloadApp();
		}
	}

	/** Affiche la popup de réinitialisation des bases de données. */
	private showResetDatabasesPopupAsync(): Promise<IUiResponse<boolean>> {
		return this.isvcUiMessage.showPopupMessageAsync<boolean>(
			new ShowMessageParamsPopup({
				header: "Optimisation de vos données",
				message: `Nous allons mettre en sécurité et optimiser vos données : vous devez disposer d'une connexion internet fiable.<br/>Votre application sera momentanément indisponible et va ensuite redémarrer.<br/><br/>Voulez-vous continuer ?`,
				backdropDismiss: false,
				buttons: [
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Optimiser", handler: () => UiMessageService.getTruthyResponse() }
				]
			})
		);
	}

	/** Affiche une popup d'erreur à l'utilisateur pour l'informer de l'échec de réinitialisation des bases de données (partiel ou complet).
	 * @param poError Erreur survenue lors de la réinitialisation des bases de données.
	 */
	private showResetDatabasesFailedPopupAsync(poError: NoOnlineReliableNetworkError | NoDatabaseLocalInstanceError | NoDatabaseRemoteInstanceError | ResetDatabasesError |
		IPouchDBFailedToFetchError | any): Promise<IUiResponse<void>> {
		let lsErrorMessage: string;

		if (poError instanceof NoOnlineReliableNetworkError || poError instanceof NoDatabaseRemoteInstanceError || poError instanceof ResetDatabasesError)
			lsErrorMessage = poError.message;
		else {
			lsErrorMessage = "L'optimisation des données a échoué.<br/>";

			if ((poError as IPouchDBFailedToFetchError).message === "Failed to fetch") // À priori micro-coupure réseau qui donne cette erreur.
				lsErrorMessage += "Veuillez vous assurer d'avoir une connexion internet fiable.";
			else
				lsErrorMessage += "Si le problème persiste, veuillez contacter le support technique.";
		}

		console.error(`${DatabasesSyncStatusModalMainViewComponent.C_LOG_ID}Database reset operation failed.`, poError);

		return this.isvcUiMessage.showPopupMessageAsync(
			new ShowMessageParamsPopup({ message: lsErrorMessage, header: "Erreur", buttons: [UiMessageService.createOkButton()] })
		);
	}

	//#endregion Optimisations

	//#endregion

}