import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomIonSelectEvent } from '../../modules/ionic/models/icustom-ion-input-event';
import { DestroyableComponentBase } from '../../modules/utils/components/destroyable-component-base';

@Component({
	selector: "calao-slider",
	templateUrl: 'slider.component.html',
	styleUrls: ['./slider.component.scss']
})
export class SliderComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	@Output("modelChanged") private readonly moModelChangedEvent = new EventEmitter<number>();
	@Output("changed") private readonly moChangedEvent = new EventEmitter<number>();

	//#endregion

	//#region PROPERTIES

	public readonly C_DEBOUNCE_TIME_MS = 50;

	@Input() public defaultValue: number;
	@Input() public sliderData: any;


	private moModel: number;
	public get model(): number { return this.moModel; }
	@Input("model") public set model(pnValue: number) {
		this.moModel = pnValue;
		this.moModelChangedEvent.emit(this.model);
		this.moChangedEvent.emit(this.model);
	}

	public selectModelValues: Array<number>;

	//#endregion

	//#region METHODS

	constructor() { super(); }

	/** Endroit où initialiser le composant après sa création. */
	public ngOnInit(): void {
		this.selectModelValues = [];
		this.model = this.defaultValue;

		for (let lnIndex = this.sliderData.min; lnIndex <= this.sliderData.max; ++lnIndex) {
			this.selectModelValues.push(lnIndex);
		}
	}

	public decrement(): void {
		--this.model;
	}

	public increment(): void {
		++this.model;
	}

	/** Une modification de la valeur du slider a été réalisée à partir du select.
	 * @param poEvent.
	 */
	public onSelectChanged(poEvent: Event): void {
		this.model = +(poEvent as CustomIonSelectEvent<number>).detail.value;
	}

	/** Une modification de la valeur du slider a été réalisée.
	 * @param poRangeData Objet contenant la valeur qu'on doit assigner au modèle.
	 */
	public onSlideChanged(poRangeData: Event): void {
		if ((poRangeData as { _value?: number })._value)
			this.model = (poRangeData as { _value?: number })._value;
	}

	/** Raffraîchit le slider. */
	public refresh(): void {
		this.sliderData();
	}

	//#endregion
}