import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, from } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { IIndexedArray } from '../../model/IIndexedArray';
import { ILinkedItemsListParams } from '../../model/linkedItemsList/ILinkedItemsListParams';
import { Entity } from '../../modules/entities/models/entity';
import { EntitiesService } from '../../modules/entities/services/entities.service';
import { PatternsHelper } from '../../modules/utils/helpers/patterns.helper';
import { EntityLinkService } from '../../services/entityLink.service';
import { ShowMessageParamsPopup } from '../../services/interfaces/ShowMessageParamsPopup';
import { PatternResolverService } from '../../services/pattern-resolver.service';
import { UiMessageService } from '../../services/uiMessage.service';

@Component({
	selector: 'calao-linked-items-list',
	templateUrl: './linkedItemsList.component.html',
	styleUrls: ['./linkedItemsList.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkedItemsListComponent<T extends Entity> extends ComponentBase implements OnInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "LIL.C::";

	//#endregion

	//#region PROPERTIES

	/** Liens indexés par catégorie. */
	public linksByCategory: IIndexedArray<Array<Entity>>;

	private mbHasElements: boolean;
	public get hasElements(): boolean { return this.mbHasElements; }

	/** Paramètres du composant. */
	@Input() public params: ILinkedItemsListParams;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcEntities: EntitiesService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioModalCtrl: ModalController,
		private readonly isvcPatternResolver: PatternResolverService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		if (!this.params)
			this.params = {};

		if (StringHelper.isBlank(this.params.itemId))
			this.params.itemId = "";
		else if (typeof this.params.itemId === "string" && PatternsHelper.hasPattern(this.params.itemId))
			this.params.itemId = this.isvcPatternResolver.replaceDynParams(this.params.itemId, this.params.itemId);

		this.init().subscribe();
	}

	private init(): Observable<IIndexedArray<Entity[]>> {
		return this.fillItems()
			.pipe(
				tap(
					(poEntitiesByCategory: IIndexedArray<Entity[]>) => {
						console.debug(`${LinkedItemsListComponent.C_LOG_ID}Entity links successfully indexed:`, poEntitiesByCategory);
						this.detectChanges();
					},
					poError => {
						console.error(`${LinkedItemsListComponent.C_LOG_ID}Failed to fill linked entities list.`, poError);

						this.isvcUiMessage.showMessage(
							new ShowMessageParamsPopup({
								header: "Erreur",
								message: "Erreur lors de la récupération des informations liées.",
								buttons: [{ text: "OK" }]
							})
						);
					}
				),
				takeUntil(this.destroyed$)
			);
	}

	/** Remplis la liste des items en les indexant par préfixe. */
	private fillItems(): Observable<IIndexedArray<Entity[]>> {
		return this.isvcEntityLink.getLinkedEntities(this.params.itemId, this.params.linkedEntityPrefixes, undefined, true)
			.pipe(
				filter((paEntities: T[]) => this.mbHasElements = ArrayHelper.hasElements(paEntities)),
				map((paEntities: T[]) => this.indexLinks(paEntities))
			);
	}

	/** Permet d'indexer les items par leur préfixe. */
	private indexLinks(paLinks: T[]): IIndexedArray<Entity[]> {
		this.linksByCategory = {};

		paLinks.forEach((poItem: T) => {
			try {
				const lsCategory: string = this.isvcEntities.getEntityCategory(poItem).name;

				if (!this.linksByCategory[lsCategory])
					this.linksByCategory[lsCategory] = [];

				this.linksByCategory[lsCategory].push(poItem);
			}
			catch (poError) {
				// En cas d'erreur, on n'empêche pas l'affichage des entités liées.
				console.error(`${LinkedItemsListComponent.C_LOG_ID}Cannot display entity ${poItem._id}.`, poError);
			}
		});

		return this.linksByCategory;
	}

	/** Lance la navigation vers un item.
	 * @param poItem Item vers lequel naviguer.
	 */
	public goToItem(poItem: Entity): void {
		from(this.isvcEntities.navigateToEntityViewAsync(poItem))
			.pipe(
				mergeMap(_ => this.ioModalCtrl.dismiss()),
				tap(
					_ => { },
					poError => console.error(`${LinkedItemsListComponent.C_LOG_ID}Error to go to item`, poError)
				)
			)
			.subscribe();
	}

	public getName(poLink: Entity): string {
		return this.isvcEntities.getEntityName(poLink);
	}

	//#endregion

}