import { Component, Input, OnInit } from '@angular/core';
import { GuidHelper } from 'libs/osapp/src/helpers/guidHelper';
import { DestroyableComponentBase } from '../../../../modules/utils/components/destroyable-component-base';
import { IDynHostComponent } from '../../../dynHost/IDynHost.component';
import { IFormDynHostOptions } from '../../../dynHost/IFormDynHostOptions';

@Component({
	selector: 'deprecated-calao-dyn-host-wrapper',
	templateUrl: './dynHostWrapper.component.html'
})
export class DynHostWrapperComponent extends DestroyableComponentBase implements IDynHostComponent, OnInit {

	//#region PROPERTIES

	/** @implements */
	public instanceId: string;
	/** @implements */
	@Input() public params: IFormDynHostOptions;

	//#endregion

	//#region METHODS

	constructor() {
		super();
		this.instanceId = GuidHelper.newGuid();
	}

	public ngOnInit(): void {
		if (!this.params)
			this.params = {};
	}

	//#endregion

}