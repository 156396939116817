import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { ESortOrder } from '../../../../model/ESortOrder';
import { PlatformService } from '../../../../services/platform.service';
import { BottomSheetComponentBase } from '../../../bottom-sheet/models/BottomSheetComponentBase';
import { ISortSelectorBottomSheetParams } from '../../models/isort-selector-bottom-sheet-params';
import { ISortSelectorResult } from '../../models/isort-selector-result';

@Component({
	selector: 'calao-sort-selector-bottom-sheet',
	templateUrl: './sort-selector-bottom-sheet.component.html',
	styleUrls: ['./sort-selector-bottom-sheet.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortSelectorBottomSheetComponent<T = any>
	extends BottomSheetComponentBase<ISortSelectorBottomSheetParams<T>, ISortSelectorResult<T>> {

	//#region FIELDS

	private static readonly C_LOG_ID = "SRT.SLCTR.BTM.SHT.C::";

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly sortOrders = ESortOrder;

	//#endregion

	//#region METHODS

	constructor(
		poBottomSheet: MatBottomSheet,
		psvcPlatform: PlatformService,
		@Inject(MAT_BOTTOM_SHEET_DATA) poParams: ISortSelectorBottomSheetParams<T>
	) {
		super(poBottomSheet, psvcPlatform, undefined, poParams);

		if (!this.params)
			console.error(`${SortSelectorBottomSheetComponent.C_LOG_ID}Component's params not setted, ui can have some displaying issues !`);
	}

	public validate(poBy: T, peOrder: ESortOrder): void {
		this.close({ by: poBy, order: peOrder });
	}

	//#endregion

}