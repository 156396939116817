import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { IItemDescriptionLine } from '@calaosoft/osapp/modules/calendar-events/models/iitem-description-line';
import { ILinkedEntityIcon } from '@calaosoft/osapp/modules/calendar-events/models/ilinked-entity-icon';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { secure } from '@calaosoft/osapp/modules/utils/rxjs/operators/secure';
import { map } from 'rxjs/operators';

@Component({
	selector: 'trade-private-activity-item',
	templateUrl: './private-activity-item.component.html',
	styleUrls: ['./private-activity-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateActivityItemComponent extends DestroyableComponentBase implements OnInit {

	/** Evenement privé. */
	@Input() public item?: BaseEventOccurrence;
	@ObserveProperty<PrivateActivityItemComponent>({ sourcePropertyKey: "item" })
	public readonly observableItem = new ObservableProperty<BaseEventOccurrence>();

	@Input() public lastDescriptionLine?: IItemDescriptionLine;
	@ObserveProperty<PrivateActivityItemComponent>({ sourcePropertyKey: "lastDescriptionLine" })
	public readonly observableLastDescriptionLine = new ObservableProperty<IItemDescriptionLine>();

	@Input() public linkedEntitiesIcons?: ILinkedEntityIcon[];
	@ObserveProperty<PrivateActivityItemComponent>({ sourcePropertyKey: "linkedEntitiesIcons" })
	public readonly observableLinkedEntitiesIcons = new ObservableProperty<ILinkedEntityIcon[]>();

	/** Libellé du type d'évènement affiché dans la partie gauche de l'item. */
	public readonly observableTypelabel = new ObservableProperty<string>("Priv");

	/** Icône affiché dans la partie gauche de l'item. */
	public readonly observableIcon = new ObservableProperty<string>("lock-closed");

	/** Couleur de fond de la partie gauche de l'item. */
	public readonly observableStartColor = new ObservableProperty<string>("var(--ion-color-background)");

	/** Couleur de fond de la partie droite de l'item. */
	public readonly observableColor = new ObservableProperty<string>("var(--ion-color-primary-contrast)");

	//#region METHODS

	constructor() {
		super();
	}

	public ngOnInit(): void {
		this.observableItem.value$.pipe(
			map((poEvent: BaseEventOccurrence) => {
				poEvent.title = "Privé";
				return poEvent;
			}),
			secure(this)
		).subscribe();
	}

	//#endregion METHODS


}
