import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@calaosoft/osapp/guards/authenticated.guard';
import { ERouteUrlPart } from '@calaosoft/osapp/model/route/ERouteUrlPart';
import { C_SECTORIZATIONS_BASE_ROUTE } from '../../app/app.constants';
import { BusinessDistributionPage } from './pages/business-distribution/business-distribution.page';
import { SectorizationComparisonPage } from './pages/comparison/sectorization-comparison.page';
import { SectorizationPage } from './pages/sectorization/sectorization.page';
import { EMapUrlPart } from './sectors/models/emap-url-part';
import { ESectors } from './sectors/models/esectors';
import { SectorMapPage } from './sectors/pages/sector-map/sector-map.page';
import { SectorsMapPage } from './sectors/pages/sectors-map/sectors-map.page';

const routes: Routes = [
	{
		path: C_SECTORIZATIONS_BASE_ROUTE,
		canActivate: [AuthenticatedGuard],
		children: [
			{
				path: "",
				component: SectorizationPage,
			},
			{
				path: `:entityGuid/${ESectors.sectors}/${ERouteUrlPart.new}`,
				loadChildren: () => import('@calaosoft/osapp/modules/entities/pages/entities/entity-edit/entity-edit.module').then(m => m.EntityEditPageModule),
				data: {
					entityDescGuid: ESectors.sectors
				}
			},
			{
				path: `:entityGuid/${ESectors.sectors}/:entityGuid/${ERouteUrlPart.edit}`,
				loadChildren: () => import('@calaosoft/osapp/modules/entities/pages/entities/entity-edit/entity-edit.module').then(m => m.EntityEditPageModule),
				data: {
					entityDescGuid: ESectors.sectors
				}
			},
			{
				path: `:entityGuid/${ESectors.sectors}/${EMapUrlPart.map}`,
				component: SectorsMapPage,
			},
			{
				path: `:entityGuid/${ESectors.sectors}/:entityGuid`,
				loadChildren: () => import('@calaosoft/osapp/modules/entities/pages/entities/entity/entity.module').then(m => m.EntityPageModule),
				data: {
					entityDescGuid: ESectors.sectors
				}
			},
			{
				path: `:entityGuid/${ESectors.sectors}/:entityGuid/${EMapUrlPart.map}`,
				component: SectorMapPage,
			},
			{
				path: `:entityGuid`,
				component: SectorizationPage,
			},
			{
				path: `:entityGuid/compare/:entityGuid2`,
				component: SectorizationComparisonPage,
			},
			{
				path: `:entityGuid/changes`,
				component: BusinessDistributionPage,
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SectorizationsPageRoutingModule { }
