import { Directory } from "@capacitor/filesystem";
import { ObjectHelper } from "../../../../helpers/objectHelper";
import { FilesystemErrorBase } from "./filesystem-error-base";

export class FilesystemCopyError extends FilesystemErrorBase {

	constructor(
		poError: any, psFromPath: string, peFromDirectory: Directory, psToPath: string, peToDirectory: Directory
	) {
		super(poError, `Can not copy from '${psFromPath}' (directory '${peFromDirectory}') to '${psToPath}' (directory '${peToDirectory}').`);
		// Nécessaire pour que le 'instanceof FilesystemCopyError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemCopyError);
	}

}