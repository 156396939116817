import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

const route: Route = {
	path: 'late',
	loadChildren: () => import('./late-list/late-list.module').then(m => m.LateListPageModule),
};

@NgModule({
	imports: [RouterModule.forChild([route])],
	exports: [RouterModule],
})
export class LateRoutingModule { }

export { route as lateSharedRoute };
