export enum EPermissionScopes {
	contacts = "contacts",
	conversations = "conversations",
	groups = "groups",
	users = "users",
	sites = "sites",
	parameters = "parameters",
	events = "events",
	sectors = "sectors",
	documents = "documents",
	interoperabilite = "interop",
	browser = "browser"
}