import { Component } from "@angular/core";
import { ApplicationService } from '../../../services/application.service';
import { PlatformService } from '../../../services/platform.service';
import { BatteryService } from '../../battery/services/battery.service';
import { DestroyableComponentBase } from "./destroyable-component-base";

@Component({ template: "" })
export abstract class AppComponentBase extends DestroyableComponentBase {

	//#region METHODS

	constructor(
		psvcApplication: ApplicationService,
		psvcPlatform: PlatformService,
		psvcBattery: BatteryService
	) {
		super();
		psvcApplication.markAppStart();

		if (psvcPlatform.isMobileApp) {
			psvcBattery.logOnLowBattery();
			psvcApplication.logOnPause();
			psvcApplication.logOnResume();
		}
	}

	//#endregion

}