import { ObjectHelper } from "../../../../helpers/objectHelper";
import { FilesystemErrorBase } from "./filesystem-error-base";

export class FilesystemRenameError extends FilesystemErrorBase {

	constructor(poError: any, psFrom: string, psTo: string) {
		super(poError, `Can not rename from '${psFrom}' to '${psTo}'.`);
		// Nécessaire pour que le 'instanceof FilesystemRenameError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemRenameError);
	}

}