import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { combineLatest, defer, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { DynamicPageComponent } from '../components/dynamicPage/dynamicPage.component';
import { ArrayHelper } from '../helpers/arrayHelper';
import { AvatarHelper } from '../helpers/avatarHelper';
import { GuidHelper } from '../helpers/guidHelper';
import { MapHelper } from '../helpers/mapHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { UserData } from '../model/application/UserData';
import { ConfigData } from '../model/config/ConfigData';
import { Group } from '../model/contacts/group';
import { IContact } from '../model/contacts/IContact';
import { IGroup } from '../model/contacts/IGroup';
import { IGroupMember } from '../model/contacts/IGroupMember';
import { EPrefix } from '../model/EPrefix';
import { ESuffix } from '../model/ESuffix';
import { IFormParams } from '../model/forms/IFormParams';
import { IIndexedArray } from '../model/IIndexedArray';
import { ActivePageManager } from '../model/navigation/ActivePageManager';
import { PageInfo } from '../model/PageInfo';
import { EAvatarSize } from '../model/picture/EAvatarSize';
import { IAvatar } from '../model/picture/IAvatar';
import { PermissionMissingError } from '../model/security/errors/PermissionMissingError';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { IUiResponse } from '../model/uiMessage/IUiResponse';
import { IWorkspace } from '../model/workspaces/IWorkspace';
import { Contact } from '../modules/contacts/models/contact';
import { DmsFile } from '../modules/dms/model/DmsFile';
import { IDmsMeta } from '../modules/dms/model/IDmsMeta';
import { DmsService } from '../modules/dms/services/dms.service';
import { EntityModalComponent } from '../modules/entities/components/entity-modal/entity-modal.component';
import { EntityLink } from '../modules/entities/models/entity-link';
import { EntityLinkEntity } from '../modules/entities/models/entity-link-entity';
import { IEntityModalParams } from '../modules/entities/models/ientity-modal-params';
import { ModalService } from '../modules/modal/services/modal.service';
import { EPermissionScopes } from '../modules/permissions/models/epermission-scopes';
import { ICrudPermissionScope } from '../modules/permissions/models/icrud-permission-scope';
import { C_SECTORS_ROLE_ID, C_SUPER_ADMIN_ROLE_ID, PermissionsService } from '../modules/permissions/services/permissions.service';
import { ISector } from '../modules/sectors/models/isector';
import { IDataSourceRemoteChanges } from '../modules/store/model/IDataSourceRemoteChanges';
import { ContactsService } from './contacts.service';
import { EntityLinkService } from './entityLink.service';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from './interfaces/ShowMessageParamsToast';
import { Store } from './store.service';
import { UiMessageService } from './uiMessage.service';
import { WorkspaceService } from './workspace.service';

@Injectable({ providedIn: "root" })
export class GroupsService {

	//#region FIELDS

	/** Propriété de tri pour trier la liste des groupes dans un certain ordre ("name"). */
	private static readonly C_GROUP_PROPERTY_SORT = "name";
	private static readonly C_DEFAULT_GROUP_ICON = "group";
	private static readonly C_DEFAULT_SECTOR_ICON = "pin";

	/** Identifiant du descripteur de formulaire par défaut pour un groupe. */
	public static C_DEFAULT_GROUPES_ENTITY_DESC_GUID = "groups";

	/** Identifiant du descripteur de formulaire par défaut pour un secteur. */
	public static C_DEFAULT_SECTORS_ENTITY_DESC_GUID = "sectors";

	/** Identifiant du descripteur de formulaire par défaut pour un groupe. */
	public static C_DEFAULT_GROUPES_FORMDESC_ID = "formDesc_groups";
	public static C_GROUPES_EDIT_FORMDEF_ID = `group${ESuffix.edit}`;

	/** Identifiant du descripteur de formulaire par défaut pour un secteur. */
	public static C_DEFAULT_SECTORS_FORMDESC_ID = "formDesc_sectors";

	//#endregion

	//#region PROPERTIES

	public static readonly C_EXCLUDE_ROLE_IDS = ["sectors"];

	//#endregion

	//#region METHODS

	constructor(
		/** Service pour les requêtes sur base de données. */
		private isvcStore: Store,
		/** Service de gestion des contacts. */
		private isvcEntityLink: EntityLinkService,
		private isvcContacts: ContactsService,
		private isvcModal: ModalService,
		private isvcPermissions: PermissionsService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcDms: DmsService,
		private readonly ioModalCtrl: ModalController
	) { }

	private static isGroupMember(poIdOrModel: string | IGroupMember, pePrefix: EPrefix): boolean {
		const lsId: string = typeof poIdOrModel === "string" ? poIdOrModel : poIdOrModel._id;
		return !StringHelper.isBlank(lsId) && lsId.indexOf(pePrefix) === 0;
	}

	/** Construit un avatar à partir d'un groupe.
	 * @param poGroup
	 * @param peAvatarSize
	 */
	public static createGroupAvatar(poGroup?: IGroup, peAvatarSize: EAvatarSize = EAvatarSize.big): IAvatar {
		if (poGroup?.picture && (poGroup.picture.base64 || poGroup.picture.guid || poGroup.picture.url))
			return { ...AvatarHelper.createAvatarFromPicture(poGroup.picture, peAvatarSize), icon: poGroup.roles?.includes(C_SECTORS_ROLE_ID) ? GroupsService.C_DEFAULT_SECTOR_ICON : GroupsService.C_DEFAULT_GROUP_ICON };
		else
			return AvatarHelper.createAvatarFromIcon(poGroup?.roles?.includes(C_SECTORS_ROLE_ID) ? GroupsService.C_DEFAULT_SECTOR_ICON : GroupsService.C_DEFAULT_GROUP_ICON, peAvatarSize);
	}

	/** Retourne `true` si l'utilisateur peut manipuler un groupe
	 * (permission des groupes accordée, et si non renseignée alors permission des contacts accordée),
	 * `false` sinon.
	 * */
	private checkPermissionAsync(psKey: keyof ICrudPermissionScope): Promise<boolean> {
		return this.isvcPermissions.waitPermissionsAsync()
			.then(() => this.isvcPermissions.evaluatePermission([EPermissionScopes.contacts, EPermissionScopes.groups], psKey));
	}

	/** Retourne `true` si l'utilisateur a la permission pour lire un groupe, retourne une erreur de lecture de permission sinon. */
	private checkReadPermissionAsync(): Promise<true | never> {
		return this.checkPermissionAsync("read")
			.then((pbHasPermission: boolean) => {
				if (pbHasPermission)
					return true;
				else
					throw new PermissionMissingError(ContactsService.C_NO_READ_PERMISSION_MESSAGE);
			});
	}

	/** Ajoute un groupe en base de données ainsi que les documents de liens correspondant pour joindre les groupes et les contacts participants.
	 * Le groupe est automatiquement marqué comme étant un groupe "Utilisateur" et non "Système" grâce au marqueur isUserGroup.
	 * @param poGroup Nouveau groupe qu'il faut enregistrer en base pour finaliser sa création.
	 */
	public addGroup(poGroup: Group): Observable<boolean> {
		poGroup.isUserGroup = true;

		return defer(() => this.checkPermissionAsync("create"))
			.pipe(
				mergeMap((pbHasPermission: boolean) => {
					if (pbHasPermission)
						return this.isvcStore.put(poGroup).pipe(mergeMap(_ => this.saveLinks(poGroup)));
					else {
						return throwError(() => new PermissionMissingError(ContactsService.C_NO_CREATE_PERMISSION_MESSAGE))
							.pipe(
								tap(
									_ => { },
									poError => console.error(`GRP.S::Erreur put nouveau groupe "${poGroup._id}" : `, poError))
							);
					}
				})
			);
	}

	/** Supprime un groupe en base de données ainsi que les documents de liens correspondant pour joindre les groupes et les contacts participants.
	 * @param poGroup Groupe qu'il faut supprimer en base pour finaliser sa suppression.
	 */
	public deleteGroup(poGroup: IGroup): Observable<boolean> {
		return this.isvcEntityLink.ensureIsDeletableEntity(poGroup)
			.pipe(
				filter((pbResult: boolean) => pbResult),
				mergeMap(_ => this.checkPermissionAsync("delete")),
				mergeMap((pbHasPermission: boolean) => pbHasPermission ?
					this.isvcEntityLink.deleteEntityLinksById(poGroup._id) : throwError(() => new PermissionMissingError(ContactsService.C_NO_DELETE_PERMISSION_MESSAGE))
				),
				mergeMap((pbResult: boolean) => {
					if (pbResult) {
						return this.isvcStore.delete(poGroup)
							.pipe(
								catchError(poError => { console.error(`GRP.S::Erreur suppression groupe "${poGroup._id}" : `, poError); return throwError(() => poError); }),
								map((poResult: IStoreDataResponse) => poResult.ok),
								tap((pbContactDeleted: boolean) => {
									if (pbContactDeleted)
										this.isvcUiMessage.showToastMessage(new ShowMessageParamsToast({ message: `"${poGroup.name}" supprimé.`, color: "dark" }));
								})
							);
					}
					else
						return of(pbResult);
				})
			);
	}

	/** Modifie un groupe en base de données ainsi que les documents de liens correspondant (ajout ou suppression)
	 * pour joindre les groupes et les contacts participants.
	 * @param poGroup Groupe qu'il faut modifier en base.
	 * @param paOldContacts Liste des contacts préalables.
	 * @param paNewContacts Liste des contacts sélectionnés.
	 */
	public updateGroup(poGroup: Group, paOldContacts?: Array<Contact>, paNewContacts?: Array<Contact>): Observable<boolean> {
		const laContacts: IContact[] = [];

		if (ArrayHelper.hasElements(paOldContacts))
			laContacts.push(...ArrayHelper.getDifferences(paOldContacts, paNewContacts));

		if (ArrayHelper.hasElements(paNewContacts))
			laContacts.push(...ArrayHelper.getDifferences(paNewContacts, paOldContacts));

		let loDmsFile: DmsFile;
		let loDmsMeta: IDmsMeta;
		if (poGroup.picture?.file && poGroup.picture.alt) {
			loDmsFile = new DmsFile(poGroup.picture.file, poGroup.picture.alt);
			loDmsMeta = loDmsFile.createDmsMeta(poGroup?.picture.guid ?? GuidHelper.newGuid());
		}

		return combineLatest([this.checkPermissionAsync("edit"), this.isvcContacts.checkEditPermissionAsync(laContacts)])
			.pipe(
				mergeMap((paHasPermissions: [boolean, boolean]) => {
					if (paHasPermissions.every((pbHasPermission: boolean) => pbHasPermission)) {
						this.isvcContacts.updateContactsLinks(poGroup, paOldContacts, ArrayHelper.unique(paNewContacts));

						return this.isvcStore.put(poGroup)
							.pipe(
								tap(
									_ => { },
									poError => console.error(`GRP.S::Erreur update groupe "${poGroup._id}" : `, poError)
								),
								mergeMap(_ => loDmsFile ? this.isvcDms.save(loDmsFile, loDmsMeta) : of(undefined)),
								mergeMap(_ => this.saveLinks(poGroup))
							);
					}
					else
						return throwError(() => new PermissionMissingError(ContactsService.C_NO_EDIT_PERMISSION_MESSAGE));
				})
			);
	}

	/** Récupère un groupe grâce à son id.
	 * @param psGroupId Id du groupe.
	 */
	public getGroup(psGroupId: string, pbLive: boolean = false, poActivePageManager?: ActivePageManager): Observable<Group> {
		if (!StringHelper.isBlank(psGroupId)) {
			return defer(() => this.checkReadPermissionAsync())
				.pipe(
					mergeMap(_ => {
						const loDataSource: IDataSourceRemoteChanges = {
							databasesIds: this.isvcContacts.getContactsDatabaseIds(),
							viewParams: {
								key: psGroupId,
								include_docs: true
							},
							live: pbLive,
							remoteChanges: !!poActivePageManager,
							activePageManager: poActivePageManager,
							baseClass: Group
						};

						return this.isvcStore.getOne<Group>(loDataSource).pipe(
							switchMap((poGroup: Group) => this.isvcEntityLink.getLinkedEntities<Contact>(poGroup, EPrefix.contact, undefined, pbLive, false, poActivePageManager).pipe(
								map((paContacts: Contact[]) => {
									poGroup.contacts = paContacts ?? [];
									return poGroup;
								})
							))
						);
					}),
					tap(
						_ => { },
						poError => console.error(`GRP.S::Erreur de récupération du groupe '${psGroupId}' : `, poError)
					)
				);
		}
		else {
			const lsMessage = `'${psGroupId}' n'est pas un identifiant de groupe valide !`;
			console.error(`GRP.S:: ${lsMessage}`);
			return throwError(() => lsMessage);
		}
	}

	/** Récupère les identifiants de groupes auxquels l'utilisateur appartient. */
	public getUserGroupsIds(): Observable<string[]> {
		return defer(() => this.checkReadPermissionAsync())
			.pipe(
				mergeMap(_ => this.isvcEntityLink.getEntityLinks(ContactsService.getUserContactId(), [EPrefix.group])),
				map((paEntityLinks: EntityLink[]) => this.getLinkedGroupIds(paEntityLinks))
			);
	}

	/** Récupère les groupes auxquels l'utilisateur appartient.
	 * @throws PermissionMissingErrors
	 */
	public getUserGroups(psUserContactId: string = ContactsService.getUserContactId(), pbLive?: boolean): Observable<Group[]> {
		return defer(() => this.checkReadPermissionAsync())
			.pipe(mergeMap(_ => this.isvcEntityLink.getLinkedEntities<Group>(psUserContactId, EPrefix.group, undefined, pbLive)));
	}

	/** Récupère les chemins (database/id) des groupes auxquels l'utilisateur appartient. */
	public getUserGroupsPaths(): Observable<string[]> {
		return defer(() => this.checkReadPermissionAsync())
			.pipe(
				mergeMap(_ => this.isvcEntityLink.getEntityLinks(ContactsService.getUserContactId(), [EPrefix.group])),
				map((paEntityLinks: EntityLink[]) => {
					return paEntityLinks.map((poEntityLink: EntityLink) =>
						poEntityLink.getTargetEntitiesByTargetPrefix(EPrefix.group).map((poEntity: EntityLinkEntity) => StoreHelper.getDocumentPathFromIdDatabaseId(poEntity.id, poEntity.databaseId))
					).flat();
				})
			);
	}

	/** Récupère les identifiants de groupes auxquels un contact appartient. */
	public getContactGroupsIds(psContactId: string): Observable<string[]>;
	public getContactGroupsIds(poContact: IContact): Observable<string[]>;
	public getContactGroupsIds(poData: IContact | string): Observable<string[]> {
		return defer(() => this.checkReadPermissionAsync())
			.pipe(
				mergeMap(_ => this.isvcEntityLink.getEntityLinks(typeof poData === "string" ? poData : poData._id, [EPrefix.group])),
				map((paEntityLinks: EntityLink[]) => this.getLinkedGroupIds(paEntityLinks))
			);
	}

	private getLinkedGroupIds(paEntityLinks: EntityLink[]): string[] {
		return paEntityLinks.map((poEntityLink: EntityLink) =>
			poEntityLink.getTargetEntitiesByTargetPrefix(EPrefix.group).map((poEntity: EntityLinkEntity) => poEntity.id)
		).flat();
	}

	/** Récupère les groupes auxquels un contact appartient.
	 * @param poContact
	 * @param pbLive
	 */
	public getContactGroups(poContact: Contact, pbLive?: boolean): Observable<Group[]> {
		return defer(() => this.checkReadPermissionAsync()).pipe(mergeMap(_ => this.isvcEntityLink.getLinkedEntities<Group>(poContact, [EPrefix.group], undefined, pbLive)));
	}

	/** Récupère les groupes auxquels un contact appartient.
	 * @param poContact
	 */
	public getContactsGroups(paContactsIds: string[], pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Map<string, IGroup[]>>;
	public getContactsGroups(paContacts: IContact[], pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Map<string, IGroup[]>>;
	public getContactsGroups(paContacts: IContact[] | string[], pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Map<string, IGroup[]>> {
		return defer(() => this.checkReadPermissionAsync())
			.pipe(
				mergeMap(_ => {
					return this.isvcEntityLink.getLinkedEntities<Group>(
						(paContacts as any as string[]).map((poContact: IContact | string) => typeof poContact === "string" ? poContact : poContact._id),
						[EPrefix.group],
						undefined,
						pbLive,
						false,
						poActivePageManager
					);
				})
			);
	}

	/** Récupère les identifiants des groupes auxquels un contact appartient.
	 * @param poContact
	 */
	public getContactsGroupIds(paContactsIds: string[], pbLive?: boolean): Observable<Map<string, string[]>>;
	public getContactsGroupIds(paContacts: IContact[], pbLive?: boolean): Observable<Map<string, string[]>>;
	public getContactsGroupIds(paContacts: IContact[] | string[], pbLive?: boolean): Observable<Map<string, string[]>> {
		return defer(() => this.checkReadPermissionAsync())
			.pipe(
				mergeMap(_ => {
					const laIds: string[] = [];
					(paContacts as any as string[]).forEach((poContact: IContact | string) => {
						if (poContact) {
							if (typeof poContact === "string")
								laIds.push(poContact);
							else
								laIds.push(poContact._id);
						}
					});

					return this.isvcEntityLink.getLinkedEntityIds(
						laIds,
						[EPrefix.group],
						undefined,
						pbLive
					);
				})
			);
	}

	/** Récupère tous les groupes. */
	private getGroups(
		paGroupIdsOrLive?: string[] | boolean,
		pbLiveOrConflicts?: boolean,
		poConflictsOrActivePageManager?: boolean | ActivePageManager,
		poActivePageManager?: ActivePageManager
	): Observable<Group[]> {
		return defer(() => this.checkReadPermissionAsync())
			.pipe(
				mergeMap(_ => {
					const loDataSource: IDataSourceRemoteChanges = {
						databasesIds: this.isvcContacts.getContactsDatabaseIds(),
						viewParams: { include_docs: true, conflicts: typeof poConflictsOrActivePageManager === "boolean" ? poConflictsOrActivePageManager : false },
						live: pbLiveOrConflicts,
						remoteChanges: !!poActivePageManager,
						activePageManager: poActivePageManager,
						baseClass: Group
					};

					if (paGroupIdsOrLive instanceof Array) { // Si on a renseigné un tableau même vide, on se fie à ce tableau pour récupérer les groupes.
						if (!ArrayHelper.hasElements(paGroupIdsOrLive)) // Si il n'y a aucune clé, on retourne un tableau vide.
							return of([]);
						loDataSource.viewParams.keys = paGroupIdsOrLive;
					}
					else {
						loDataSource.viewParams.startkey = EPrefix.group;
						loDataSource.viewParams.endkey = EPrefix.group + Store.C_ANYTHING_CODE_ASCII;
						loDataSource.live = !!paGroupIdsOrLive;

						if (poConflictsOrActivePageManager instanceof ActivePageManager) {
							loDataSource.remoteChanges = !!poConflictsOrActivePageManager;
							loDataSource.activePageManager = poConflictsOrActivePageManager;
						}

						loDataSource.viewParams.conflicts = pbLiveOrConflicts;
					}

					return this.isvcStore.get(loDataSource);
				}),
				tap(
					(paResults: Group[]) => this.isvcContacts.sortMembers(paResults, GroupsService.C_GROUP_PROPERTY_SORT),
					poError => console.error(`GRP.S::Erreur récupération des groupes : `, poError)
				)
			);
	}

	/** Récupère tous les groupes pouvant être affichés. */
	public getDisplayableGroups(
		pbLive?: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Group[]>;
	/** Récupère tous les groupes pouvant être affichés dont les identifiants sont passés en paramètres.
	 * @param paGroupIds Tableau des identifiants de groupes à récupérer.
	 */
	public getDisplayableGroups(
		paGroupIds: string[],
		pbLive?: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Group[]>;
	public getDisplayableGroups(
		poData?: string[] | boolean,
		pbLiveOrConflicts?: boolean,
		poConflictsOrActivePageManager?: boolean | ActivePageManager,
		poActivePageManager?: ActivePageManager
	): Observable<Group[]> {
		return this.getGroups(poData, pbLiveOrConflicts, poConflictsOrActivePageManager, poActivePageManager)
			.pipe(
				mergeMap((paGroups: Group[]) => {
					return this.isvcWorkspace.getWorkspaceDocument(this.isvcWorkspace.getCurrentWorkspaceId()).pipe(
						map((poWorkspace: IWorkspace) => {
							if (ConfigData.contacts?.hideGroupsWithRoles || poWorkspace.hideGroupsWithRoles) {
								return paGroups.filter((poGroup: Group) =>
									this.isvcPermissions.hasRole(C_SUPER_ADMIN_ROLE_ID) ||
									!ArrayHelper.hasElements(poGroup.roles)
								);
							}

							return paGroups;
						})
					);
				})
			);
	}

	/** Récupère tous les contacts d'un groupe.
	 * @param psGroupId Identifiant du groupe dont on veut retrouver les contacts.
	 * @param paContactPrefixes Tableau des préfixes des contacts à récupérer, seulement `cont_` par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getGroupContacts(
		psGroupId: string,
		paContactPrefixes?: EPrefix[],
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Contact[]>;
	/** Récupère tous les contacts d'un groupe.
	 * @param poGroup Groupe dont on veut retrouver les contacts.
	 * @param paContactPrefixes Tableau des préfixes des contacts à récupérer, seulement `cont_` par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getGroupContacts(
		poGroup: Group,
		paContactPrefixes?: EPrefix[],
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Contact[]>;
	/** Récupère une map de tous les contacts d'un groupe en fonction de l'identifiant du groupe.
	 * @param paGroups Tableau des groupes dont on veut récupérer les contacts associés.
	 * @param paContactPrefixes Tableau des préfixes des contacts à récupérer, seulement `cont_` par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getGroupContacts(
		paGroups: Group[],
		paContactPrefixes?: EPrefix[],
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, Contact[]>>;
	/** Récupère une map de tous les contacts d'un groupe en fonction de l'identifiant du groupe.
	 * @param paGroupsIds Tableau des identifiants de groupes dont on veut récupérer les contacts associés.
	 * @param paContactPrefixes Tableau des préfixes des contacts à récupérer, seulement `cont_` par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getGroupContacts(
		paGroupsIds: string[],
		paContactPrefixes?: EPrefix[],
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, Contact[]>>;
	public getGroupContacts(
		poData: string | string[] | Group | Group[],
		paContactPrefixes = [EPrefix.contact],
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Contact[] | Map<string, Contact[]>> {
		return defer(() => this.isvcContacts.checkReadPermissionAsync())
			.pipe(
				mergeMap(_ => {
					if (!(poData instanceof Array)) {
						const lsGroupId: string = typeof poData === "string" ? poData : poData._id;
						return this.innerGetLinkedContactsByGroupId(lsGroupId, paContactPrefixes, pbLive, poActivePageManager);
					}
					else
						return this.innerGetLinkedContactsByGroups(poData, paContactPrefixes, pbLive, poActivePageManager);
				}),
				tap(
					_ => { },
					poError => console.error("GRP.S::Récupération des contacts liés échouée : ", poError)
				)
			);
	}

	/** Récupère un tableau contenant tous les membres des groupes donnés en paramètre.
	 * @param paGroupsIds Tableau des identifiants de groupes dont on veut récupérer les contacts associés.
	 */
	public getGroupContactsArray(paGroupsIds: string[]): Observable<IContact[]> {
		return this.getGroupContacts(paGroupsIds).pipe(map((poContacts: Map<string, IContact[]>) => ArrayHelper.unique(ArrayHelper.flat(MapHelper.valuesToArray(poContacts)))));
	}

	/** Récupère les groupes filtrés par rôles.
	 * @param paRoles Liste des rôles que doivent contenir les groupes.
	 * @param pbLive
	 */
	public getGroupsByRoles(paRoles?: string[], pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Group[]> {
		return this.getDisplayableGroups(pbLive, false, poActivePageManager).pipe(
			map((paGroups: Group[]) => paGroups.filter((poGroup: Group) => ArrayHelper.hasElements(ArrayHelper.intersection(paRoles, poGroup.roles ?? []))))
		);
	}

	/** Récupère tous les contacts liés à un groupe.
	 * @param psGroupId Identifiant du groupe dont on veut retrouver les documents de liens.
	 */
	public getGroupContactsIds(psGroupId: string, paPrefixes?: EPrefix[], pbLive?: boolean): Observable<string[]>;
	/** Récupère tous les contacts liés à un groupe.
	 * @param poGroup Groupe dont on veut retrouver les documents de liens.
	 */
	public getGroupContactsIds(poGroup: IGroup, paPrefixes?: EPrefix[], pbLive?: boolean): Observable<string[]>;
	/** Récupère tous les contacts liés à des groupes.
	 * @param paGroupsIds Identifiants de groupes dont on veut retrouver les documents de liens.
	 * @returns Objet indexé par identifiant de groupe contenant les contacts pour chaque groupe.
	 */
	public getGroupContactsIds(paGroupsIds: string[], paPrefixes?: EPrefix[], pbLive?: boolean): Observable<IIndexedArray<string[]>>;
	/** Récupère tous les contacts liés à des groupes.
	 * @param paGroups Groupes dont on veut retrouver les documents de liens.
	 * @returns Objet indexé par identifiant de groupe contenant les contacts pour chaque groupe.
	 */
	public getGroupContactsIds(paGroups: IGroup[], paPrefixes?: EPrefix[], pbLive?: boolean): Observable<IIndexedArray<string[]>>;
	public getGroupContactsIds(poData: string | IGroup | IGroup[] | string[], paPrefixes: EPrefix[] = [EPrefix.contact], pbLive?: boolean): Observable<string[] | IIndexedArray<string[]>> {
		return defer(() => this.isvcContacts.checkReadPermissionAsync())
			.pipe(
				mergeMap(_ => {
					if (!(poData instanceof Array))
						return this.innerGetLinkedContactsIdsByGroupId(typeof poData === "string" ? poData : poData._id, paPrefixes, pbLive);
					else
						return this.innerGetLinkedContactsIdsByGroups(poData, paPrefixes, pbLive);
				}),
				tap(
					_ => { },
					poError => console.error("GRP.S::Récupération des identifiants des contacts liés échouée : ", poError)
				)
			);
	}

	private innerGetLinkedContactsByGroups(
		paGroupsOrIds: Group[] | string[],
		paContactPrefixes?: EPrefix[],
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, Contact[]>> {
		return this.isvcEntityLink.getLinkedEntities<Contact>(paGroupsOrIds, paContactPrefixes, undefined, pbLive, false, poActivePageManager)
			.pipe(
				tap(
					_ => { },
					poError => console.error(`GRP.S::Erreur récupération des liens des groupes : `, poError)
				)
			);
	}

	private innerGetLinkedContactsByGroupId(
		psGroupId: string,
		paContactPrefixes?: EPrefix[],
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Contact[]> {
		return this.isvcEntityLink.getLinkedEntities<Contact>(psGroupId, paContactPrefixes, undefined, pbLive, false, poActivePageManager);
	}

	private innerGetLinkedContactsIdsByGroups(
		paGroupsOrIds: IGroup[] | string[],
		paPrefixes: EPrefix[],
		pbLive?: boolean
	): Observable<IIndexedArray<string[]>> {
		const laGroupsIds: string[] = (paGroupsOrIds as any[]).map((poGroupOrId: IGroup | string) => typeof poGroupOrId === "string" ? poGroupOrId : poGroupOrId._id);

		return this.isvcEntityLink.getLinkedEntityIds(laGroupsIds, paPrefixes, undefined, pbLive)
			.pipe(
				tap(
					_ => { },
					poError => console.error(`GRP.S::Erreur récupération identifiants des liens des groupes : `, poError)
				),
				map((poContactsIdsByGroupIds: Map<string, string[]>) => MapHelper.mapToObject(poContactsIdsByGroupIds))
			);
	}

	private innerGetLinkedContactsIdsByGroupId(psGroupId: string, paPrefixes: EPrefix[], pbLive?: boolean): Observable<string[]> {
		return this.isvcEntityLink.getLinkedEntityIds(psGroupId, paPrefixes, undefined, pbLive)
			.pipe(
				tap(
					_ => { },
					poError => console.error(`GRP.S::Erreur récupération des liens du groupe ${psGroupId} : `, poError)
				)
			);
	}

	/** Ajoute les documents-liens des groupes et membres de ces groupes en base de données.
	 * @param poGroup Groupe créé / modifié.
	 */
	private saveLinks(poGroup: Group): Observable<boolean> {
		return this.isvcEntityLink.saveEntityLinks(poGroup)
			.pipe(
				tap(
					_ => { },
					poError => console.error(`GRP.S::Erreur put de membre(s) dans le groupe "${poGroup.name}" : `, poGroup, poError)
				)
			);
	}

	/** Retourne `true` si l'identifiant est celui d'un groupe, `false` sinon.
	 * @param psId Identifiant à analyser.
	 */
	public static isGroup(psId: string): boolean;
	/** Retourne `true` si le modèle est celui d'un groupe, `false` sinon.
	 * @param poModel Modèle à analyser.
	 */
	public static isGroup(poModel: IGroupMember): boolean;
	public static isGroup(poIdOrModel: string | IGroupMember): boolean {
		return poIdOrModel ? this.isGroupMember(poIdOrModel, EPrefix.group) : false;
	}

	/** Ouvre la modale de création d'un groupe
	 * @deprecated
	*/
	public legacyOpenCreateGroupeModal(poGroup?: Group): Observable<Group> {
		const pbIsSector: boolean = poGroup?.roles?.includes(C_SECTORS_ROLE_ID);

		if (pbIsSector)
			(poGroup as ISector).siteId = UserData.currentSite._id;

		return defer(() => this.checkPermissionAsync("create"))
			.pipe(
				mergeMap((pbHasPermission: boolean) => {
					if (pbHasPermission) {
						return from(this.ioModalCtrl.create({
							component: DynamicPageComponent,
							componentProps: { pageInfo: this.getOpenCreateGroupeModalPageInfo(pbIsSector, poGroup) }
						}))
							.pipe(
								tap((poModal: HTMLIonModalElement) => poModal.present()),
								mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
								filter((poResult: OverlayEventDetail<Group>) => !!poResult.data),
								map((poResult: OverlayEventDetail<Group>) => poResult.data)
							);
					}
					else
						return throwError(() => new PermissionMissingError(ContactsService.C_NO_CREATE_PERMISSION_MESSAGE));
				})
			);
	}

	/** @deprecated */
	private getOpenCreateGroupeModalPageInfo(pbIsSector: boolean, poGroup?: Group): PageInfo {
		return new PageInfo({
			componentName: "form",
			params: {
				formDescriptorId: pbIsSector ? GroupsService.C_DEFAULT_SECTORS_FORMDESC_ID : GroupsService.C_DEFAULT_GROUPES_FORMDESC_ID,
				formDefinitionId: GroupsService.C_GROUPES_EDIT_FORMDEF_ID,
				model: poGroup,
				visuAfterCreate: false
			} as IFormParams,
			isModal: true,
			isClosable: true
		});
	}

	/** Ouvre la modale de création d'un groupe */
	public openCreateGroupeModal(poGroup?: IGroup): Observable<Group> {
		const lbIsSector: boolean = poGroup?.roles?.includes(C_SECTORS_ROLE_ID);

		if (lbIsSector)
			(poGroup as ISector).siteId = UserData.currentSite._id;

		return defer(() => this.checkPermissionAsync("create"))
			.pipe(
				mergeMap((pbHasPermission: boolean) => {
					if (pbHasPermission) {
						return this.isvcModal.open({
							component: EntityModalComponent,
							componentProps: this.getOpenCreateGroupeModalParams(lbIsSector)
						});
					}
					else
						return throwError(() => new PermissionMissingError(ContactsService.C_NO_CREATE_PERMISSION_MESSAGE));
				})
			);
	}

	private getOpenCreateGroupeModalParams(pbIsSector: boolean): IEntityModalParams {
		return {
			closeAfterSave: true,
			isEdit: true,
			entityDescGuid: pbIsSector ? GroupsService.C_DEFAULT_SECTORS_ENTITY_DESC_GUID : GroupsService.C_DEFAULT_GROUPES_ENTITY_DESC_GUID
		}
	}

	public hasRole(poGroup: IGroup, psRole: string): boolean {
		return poGroup?.roles?.some((psGroupRole: string) => psGroupRole === psRole) ?? false;
	}

	public showDeleteGroupPopup$(poGroup: IGroup): Observable<IUiResponse<boolean, any>> {
		return this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				header: `Voulez-vous supprimer définitivement le groupe "${poGroup.name}" ? Cette action est irréversible.`,
				buttons: [
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Oui, supprimer", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }
				],
				backdropDismiss: true
			})
		);
	}

	public getUserJoinDateByGroupId$(pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Map<string, Date>> {
		const lsUserContactId: string = ContactsService.getUserContactId();
		return this.isvcEntityLink.getEntityLinks(
			lsUserContactId,
			[EPrefix.group],
			undefined,
			pbLive,
			poActivePageManager
		).pipe(
			map((paEntityLinks: EntityLink[]) =>
				new Map(paEntityLinks.map(
					(poEntityLink: EntityLink) =>
						this.isvcEntityLink.getLinkTargetIds([lsUserContactId], poEntityLink).map(
							(psId: string) => [
								psId,
								poEntityLink.creationDate
							] as [string, Date]
						)
				).flat()
				)),
		);
	}

	//#endregion

}