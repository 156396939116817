import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

const route: Route = {
	path: 'todo',
	loadChildren: () => import('./todo-list/todo-list.module').then(m => m.TodoListPageModule),
};

@NgModule({
	imports: [RouterModule.forChild([route])],
	exports: [RouterModule],
})
export class TodoRoutingModule { }

export { route as todoSharedRoute };
