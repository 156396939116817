import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { CanEditEventOccurenceGuard } from '../../../modules/trade-events/guards/can-edit-event-occurence.guard';
import { CanEditEventGuard } from '../../../modules/trade-events/guards/can-edit-event.guard';
import { CanShowEventOccurenceGuard } from '../../../modules/trade-events/guards/can-show-event-occurence.guard';
import { CanShowEventGuard } from '../../../modules/trade-events/guards/can-show-event.guard';

const route: Route = {
	path: "events",
	children: [
		{
			path: ':eventGuid',
			loadChildren: () => import('./event-occurrence/event-occurrence.module').then(m => m.EventOccurrencePageModule),
			canActivate: [CanShowEventGuard]
		},
		{
			path: ':eventGuid/occurrences/:occurrenceDate',
			loadChildren: () => import('./event-occurrence/event-occurrence.module').then(m => m.EventOccurrencePageModule),
			canActivate: [CanShowEventOccurenceGuard]
		},
		{
			path: ':eventGuid/edit',
			loadChildren: () => import('./edit-event/edit-event.module').then(poModule => poModule.EditEventPageModule),
			canActivate: [CanEditEventGuard]
		},
		{
			path: ':eventGuid/occurrences/:occurrenceDate/edit',
			loadChildren: () => import('./event-occurrence-edit/event-occurrence-edit.module').then(m => m.EventOccurrenceEditPageModule),
			canActivate: [CanEditEventOccurenceGuard]
		}
	]
};

@NgModule({
	imports: [RouterModule.forChild([route])],
	exports: [RouterModule],
})
export class EventDetailRoutingModule { }

export { route as eventDetailSharedRoute };
