<ion-header mode="md">
	<ion-toolbar color="toolbar" mode="md" class="navbar">

		<ion-buttons slot="start">
			<!-- Bouton 'back'. -->
			<ion-button *ngIf="hasBackButton && canGoBack$ | async" (click)="goBack()" data-cy="header-back-button">
				<ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
			</ion-button>

			<!-- Bouton 'menu'. -->
			<ion-menu-toggle *ngIf="hasMenuButton$ | async">
				<ion-button>
					<ion-icon slot="icon-only" name="menu"></ion-icon>
				</ion-button>
			</ion-menu-toggle>

			<!-- Bouton 'accueil'. -->
			<ion-button *ngIf="hasHomeButton" (click)="goHome()">
				<ion-icon slot="icon-only" name="home"></ion-icon>
			</ion-button>
		</ion-buttons>

		<!-- Titre. -->
		<ion-title [color]="titleColor" data-cy="page-title">{{ title }}</ion-title>

		<ion-buttons *ngIf="linkInfo$ | async as linkInfo" slot="end" class="button-md">
			<calao-link [linkInfo]="linkInfo"></calao-link>
		</ion-buttons>

		<ion-buttons slot="end">
			<ng-content select="calao-header-end-buttons-start"></ng-content>
			<!-- Bouton d'état de la synchronisation -->
			<calao-database-sync-status-icon *ngIf="hasSyncButton"></calao-database-sync-status-icon>
			<!-- Bouton 'options'. -->
			<ion-button *ngIf="hasPopoverItems$ | async" slot="end" (click)="openOptions($event)" class="options-btn">
				<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
			</ion-button>
			<ion-button *ngIf="observableHasNotificationsButton.value$ | async" (click)="routeToNotifications()"
				class="options-btn">
				<ion-icon name="notifications" slot="icon-only"></ion-icon>
				<ion-badge *ngIf="nbOfNotif$ | async as nbOfNotif" color="warning">{{nbOfNotif}}</ion-badge>
			</ion-button>
			<ion-button *ngIf="hasConversationsButton$ | async" (click)="routeToConversations()" class="options-btn">
				<ion-icon name="chatbubbles" slot="icon-only"></ion-icon>
				<ion-badge *ngIf="nbOfUnreadConv$ | async as nbOfUnreadConv" color="warning">{{nbOfUnreadConv}}</ion-badge>
			</ion-button>
			<ng-content select="calao-header-end-buttons-end"></ng-content>
		</ion-buttons>

		<ng-content></ng-content>

	</ion-toolbar>
</ion-header>