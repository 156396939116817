import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from '@calaosoft/osapp/guards/authenticated.guard';
import { C_BUSINESSES_BASE_ROUTE } from '../../app.constants';

const route: Route = {
	path: C_BUSINESSES_BASE_ROUTE,
	children: [
		{
			path: 'new',
			loadChildren: () => import('@calaosoft/osapp/modules/entities/pages/entities/entity-edit/entity-edit.module').then(m => m.EntityEditPageModule),
			data: {
				entityDescGuid: "business"
			}
		},
		{
			path: ':entityGuid/edit',
			loadChildren: () => import('@calaosoft/osapp/modules/entities/pages/entities/entity-edit/entity-edit.module').then(m => m.EntityEditPageModule),
			data: {
				entityDescGuid: "business"
			}
		},
		{
			path: ':entityGuid',
			loadChildren: () => import('./business/business.module').then(m => m.BusinessPageModule)
		}
	],
	canActivate: [AuthenticatedGuard]
};

@NgModule({
	imports: [RouterModule.forChild([route])],
	exports: [RouterModule],
})
export class BusinessesSharedRoutingModule { }

export { route as businessesSharedRoute };
