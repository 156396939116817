import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { IGalleryFile } from '../../../../model/gallery/IGalleryFile';
import { GalleryService } from '../../../../services/gallery.service';
import { IGallerySelectorParams } from '../../models/IGallerySelectorParams';

@Component({
	selector: 'calao-gallery-selector',
	templateUrl: './gallery-selector.component.html',
	styleUrls: ['./gallery-selector.component.scss'],
})
export class GallerySelectorComponent extends ComponentBase implements IGallerySelectorParams {

	//#region FIELDS

	@Output("onFileSelectionChanged") private readonly moOnFileSelectionChangedEvent = new EventEmitter<IGalleryFile[]>();

	//#endregion

	//#region METHODS

	/** @implements */
	@Input() public files: IGalleryFile[];

	private maPreselectedFilesGuids: string[];
	public get preselectedFilesGuids(): string[] { return this.maPreselectedFilesGuids; }
	/** @implements */
	@Input() public set preselectedFilesGuids(paPreselectedFilesGuids: string[]) {
		if (paPreselectedFilesGuids) {
			this.maPreselectedFilesGuids = paPreselectedFilesGuids;
			this.selectedFilesGuids.push(...this.maPreselectedFilesGuids);
			this.selectedFilesGuids = ArrayHelper.unique(this.selectedFilesGuids);
			this.emitSelectedFiles();
		}
	}

	public selectedFilesGuids: string[] = [];

	//#endregion METHODS

	//#region METHODS

	constructor(private readonly isvcGallery: GalleryService) {
		super();
	}

	public openFile(poFile: IGalleryFile): void {
		this.isvcGallery.openFile(poFile)
			.pipe(
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	public toggleSelection(poFile: IGalleryFile): void {
		if (this.selectedFilesGuids.includes(poFile.guid))
			ArrayHelper.removeElement(this.selectedFilesGuids, poFile.guid);
		else
			this.selectedFilesGuids.push(poFile.guid);

		this.emitSelectedFiles();
	}


	private emitSelectedFiles(): void {
		this.moOnFileSelectionChangedEvent.emit(this.files.filter((poFile: IGalleryFile) => this.selectedFilesGuids.includes(poFile.guid)));
	}

	//#endregion METHODS

}