import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { GroupsService } from '../../services/groups.service';
import { Contact } from '../contacts/models/contact';
import { ISector } from '../sectors/models/isector';
import { Site } from './models/site';
import { SiteDetailsModel } from './models/site-details-model';
import { SitesService } from './services/sites.service';

@Injectable({
	providedIn: 'root'
})
export class SiteResolver {

	//#region METHODS

	constructor(
		private isvcSites: SitesService,
		private isvcGroups: GroupsService
	) { }

	public resolve(poRoute: ActivatedRouteSnapshot) {
		const lsSiteId: string = poRoute.params.siteId;

		return this.isvcSites.getSite(lsSiteId)
			.pipe(
				mergeMap((poSite: Site) => this.isvcGroups.getGroupContacts(poSite)
					.pipe(map((paContacts: Contact[]) => new SiteDetailsModel({ ...poSite, contacts: paContacts })))
				),
				mergeMap((poSite: SiteDetailsModel) => this.isvcSites.getSiteSectors(poSite._id)
					.pipe(
						map((paSectors: ISector[]) => new SiteDetailsModel({
							...poSite,
							sectors: paSectors?.map((poSector: ISector) => poSector._id)
						}))
					)
				),
			);
	}

	//#endregion

}