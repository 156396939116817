import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { PlatformService } from '../../../services/platform.service';

@Component({ template: "", selector: "calao-modal-base" })
export abstract class ModalComponentBase<T> extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	/** Abonnement au bouton natif 'back' Android. */
	protected moBackButtonSubscription?: Subscription;

	//#endregion

	//#region METHODS

	constructor(
		protected readonly ioModalCtrl: ModalController,
		protected readonly isvcPlatform: PlatformService,
		poChangeDetector?: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	/** Cycle de vie Angular dans lequel on initialise l'abonnement aux événements du bouton physique 'back'. */
	public ngOnInit(): void {
		// Permet de fermer des modales une à une si plusieurs sont instanciées en même temps et non pas toutes les fermer en faisant un seul back natif.
		this.moBackButtonSubscription = this.isvcPlatform.getBackButtonSubscription(() => this.close());
	}

	/** Cycle de vie Angular dans lequel on se désabonne des événements du bouton physique 'back'. */
	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moBackButtonSubscription?.unsubscribe();
	}

	/** Permet de fermer la modale. */
	public close(poData?: T): Promise<boolean> {
		return this.ioModalCtrl.dismiss(poData);
	}

	//#endregion

}