import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserData } from '../../../model/application/UserData';
import { SecurityService } from '../../../services/security.service';
import { RemoveCredentialsGuard } from './remove-credentials.guard';

@Injectable()
export class ConfigurableRemoveCredentialsGuard extends RemoveCredentialsGuard {

	//#region METHODS

	constructor(isvcSecurity: SecurityService) {
		super(isvcSecurity);
	}

	/** @implements Retourne `true` si on peut entrer sur le composant, `false` sinon. */
	public override canActivate(poRoute: ActivatedRouteSnapshot, poState: RouterStateSnapshot): Promise<boolean> {
		if (UserData.current?.session.keepLastSessionOnLogout) {
			return Promise.resolve(true);
		} else {
			return super.canActivate(poRoute, poState);
		}
	}

	//#endregion METHODS

}
