import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { PopoverService } from '@calaosoft/osapp/services/popover.service';
import { TaskOccurrence } from 'apps/trade/src/modules/trade-events/models/task-occurrence';
import { Observable, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { TradeEventsService } from '../../services/trade-events.service';
import { TaskItemComponent } from '../task-item/task-item.component';

@Component({
	selector: 'trade-delegated-task-item',
	templateUrl: './delegated-task-item.component.html',
	styleUrls: ['./delegated-task-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DelegatedTaskItemComponent extends TaskItemComponent {

	//#region PROPERTIES

	/** `true` si le libellé de l'échéance doit être affiché, sinon `false`. Est `false` par défaut. */
	public readonly observableDisplayDeadlineLabel = new ObservableProperty<boolean>(false);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		override readonly isvcTradeEvents: TradeEventsService,
		override readonly isvcPopover: PopoverService
	) {
		super(isvcTradeEvents, isvcPopover);

		this.observableDisplayDeadlineLabel.bind(this.displayDeadlineLabel$(), this);
	}

	private displayDeadlineLabel$(): Observable<boolean> {
		return this.observableTaskOccurrence.value$.pipe(
			switchMap((poTaskOccurrence?: TaskOccurrence) => {
				return poTaskOccurrence?.isLate$ ?? of(false);
			}),
			map((pbIsLate: boolean) => {
				const lbHasDeadline: boolean = !!this.taskOccurrence?.getDeadlineLabel();
				const lbHasStartDate: boolean = !!this.taskOccurrence?.startDate;
				return (pbIsLate && lbHasDeadline) || (!lbHasStartDate && lbHasDeadline);
			}),
			takeUntil(this.destroyed$)
		);

	}

	//#endregion METHODS

}
