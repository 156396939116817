import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
	selector: 'calao-readonly-wrapper',
	templateUrl: './readonly-wrapper.component.html',
	styleUrls: ['./readonly-wrapper.component.scss']
})
export class ReadOnlyWrapperComponent extends FieldWrapper {

	//#region PROPERTIES

	/** Conteneur pour le champs de formulaire. */
	@ViewChild("fieldComponent", { read: ViewContainerRef, static: true }) public override fieldComponent: ViewContainerRef;

	//#endregion

}