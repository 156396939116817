import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EMPTY, Observable, defaultIfEmpty, filter, firstValueFrom, of, switchMap, tap } from 'rxjs';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { StringHelper } from '../../../../helpers/stringHelper';
import { ShowMessageParamsToast } from '../../../../services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { ModalService } from '../../../modal/services/modal.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { Entity } from '../../models/entity';
import { IEntitySelectorParamsBase } from '../../models/ientity-selector-params-base';
import { EntitiesService } from '../../services/entities.service';
import { EntityPickerModalComponent } from '../entity-picker-modal/entity-picker-modal.component';

@Component({
	selector: 'calao-entity-selector',
	templateUrl: './entity-selector.component.html',
	styleUrls: ['./entity-selector.component.scss'],
})
export class EntitySelectorComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	private static readonly C_DEFAULT_DISABLED_MESSAGE = "Impossible de sélectionner l'entité.";

	@Output("onSelectedEntityChanged") private readonly moEntitySelectedEvent = new EventEmitter<Entity | undefined>();

	//#endregion FIELDS

	//#region PROPERTIES

	@Input() public params?: IEntitySelectorParamsBase;
	@ObserveProperty<EntitySelectorComponent>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<IEntitySelectorParamsBase>();

	public readonly obsModel = new ObservableProperty<Entity>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntities: EntitiesService,
		private readonly isvcModal: ModalService,
		private readonly isvcUiMessage: UiMessageService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		this.observableParams.value$.pipe(
			filter((poParams?: IEntitySelectorParamsBase) => !!poParams),
			switchMap((poParams: IEntitySelectorParamsBase) => !StringHelper.isBlank(poParams.preselectedEntityId) ? this.isvcEntities.getModel$(poParams.preselectedEntityId) : of(undefined)),
			tap((poEntity: Entity) => {
				this.obsModel.value = poEntity;
				this.detectChanges();
			}),
			secure(this)
		).subscribe();
	}

	public onSelectEntityClickedAsync(): Promise<Entity | undefined> {
		return firstValueFrom(this.selectEntity$().pipe(defaultIfEmpty(undefined)));
	}

	@Queue<
		EntitySelectorComponent,
		Parameters<EntitySelectorComponent["selectEntity$"]>,
		ReturnType<EntitySelectorComponent["selectEntity$"]>
	>({
		excludePendings: true
	})
	private selectEntity$(): Observable<Entity> {
		if (this.observableParams.value?.disabled) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({
				message: StringHelper.isBlank(this.observableParams.value?.disabledMessage) ?
					EntitySelectorComponent.C_DEFAULT_DISABLED_MESSAGE :
					this.observableParams.value?.disabledMessage
			}));

			return EMPTY;
		}

		return this.isvcModal.open<Entity>({
			component: EntityPickerModalComponent,
			componentProps: {
				entityDescId: this.observableParams.value?.entityDescId,
				linkedToIds: this.observableParams.value?.linkedToIds
			}
		}).pipe(
			filter((poEntity?: Entity) => !!poEntity),
			tap((poEntity: Entity) => {
				this.obsModel.value = poEntity;
				this.moEntitySelectedEvent.emit(poEntity);
			}),
		);
	}

	public getEntityName(poEntity?: Entity | null): string {
		return this.isvcEntities.getEntityName(poEntity);
	}

	public onResetEntityClicked(): void {
		this.obsModel.value = undefined;
		this.moEntitySelectedEvent.emit(undefined);
	}

	//#endregion METHODS

}
