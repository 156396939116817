import { Exclude, Expose } from "class-transformer";
import { ObservableProperty } from "../models/observable-property";

const C_LOG_ID = "OBSPROP.DECORATOR::";

interface IObservePropertyParams<T> {
	/** Nom de la propriété à observer. */
	readonly sourcePropertyKey: keyof T;
	/** Fonction qui transforme la nouvelle valeur affectée à la propriété source en valeur utilisée par la propriété observable. */
	readonly transformer?: (poNewValue: any, poThis: T) => any;
	/** Exclut la propriété observée. */
	readonly excludeSource?: boolean;
	/** Action à réaliser après qu'une modification a eu lieu. */
	readonly setAction?: (poThis: T) => void;
}

/** Observe une propriété afin de réagir à ses changements.
 * @param poParams
 */
export function ObserveProperty<T>(poParams: IObservePropertyParams<T>): PropertyDecorator {
	return function (poTarget: Object, psPropertyKey: string, poDescriptor?: TypedPropertyDescriptor<ObservableProperty<any>>) {
		Exclude()(poTarget, psPropertyKey); // On exclue la propriété observable.

		const loSourceDescriptor = Object.getOwnPropertyDescriptor(poTarget, poParams.sourcePropertyKey);
		const lfGet = function () {
			return loSourceDescriptor?.get?.apply(this) ?? this[`#obs_${psPropertyKey}`];
		};
		const lfSet = function (poNewVal: any) {
			this[`#obs_${psPropertyKey}`] = poParams.transformer ? poParams.transformer(poNewVal, this) : poNewVal;

			loSourceDescriptor?.set?.apply(this, [poNewVal]);

			const loObservableProperty: ObservableProperty<any> = this[psPropertyKey];
			if (!(loObservableProperty instanceof ObservableProperty))
				// ! Attention à la valeur par défaut de la valeur observée, elle doit être initialisée dans le constructeur.
				console.error(`${C_LOG_ID}The property '${psPropertyKey}' from '${poTarget.constructor.name}' should be an instance of ObservableProperty`);
			else {
				if (loObservableProperty.value !== this[`#obs_${psPropertyKey}`]) {
					loObservableProperty.value = this[`#obs_${psPropertyKey}`];

					if (poParams.setAction)
						poParams.setAction(this);
				}
			}
		};

		// On surcharge la propriété source avec les méthodes `get` et `set` personnalisées.
		Object.defineProperty(poTarget, poParams.sourcePropertyKey, { get: lfGet, set: lfSet });

		if (poParams.excludeSource)
			Exclude()(poTarget, poParams.sourcePropertyKey as string); // On exclut la propriété de base.
		else
			Expose()(poTarget, poParams.sourcePropertyKey as string); // On expose la propriété de base.

		return poDescriptor;
	};
}