import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { ICheckboxParams } from '../../models/ICheckboxParams';

@Component({
	selector: "calao-checkbox",
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent extends DestroyableComponentBase implements ICheckboxParams {

	//#region FIELDS

	/** Événement levé lors de la sélection de la checkbox. */
	@Output("onSelected") private readonly moOnSelectedEvent = new EventEmitter<void>();
	/** Événement levé lors de la désélection de la checkbox. */
	@Output("onDeselected") private readonly moOnDeselectedEvent = new EventEmitter<void>();
	/** Événement levé lors du changement de sélection de la checkbox. */
	@Output("selectedChange") private readonly moOnSelectionChangedEvent = new EventEmitter<boolean>();

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public color: string;
	/** @implements */
	@Input() public slot = "start";
	/** @implements */
	@Input() public selected = false;
	/** @implements */
	@Input() public disabled = false;

	//#endregion

	//#region METHODS

	constructor() {
		super();
	}

	/** Traitement après (dé)sélection de la checkbox et envoi d'événements associés. */
	public onItemClicked(): void {
		this.selected = !this.selected;

		this.selected ? this.moOnSelectedEvent.emit() : this.moOnDeselectedEvent.emit();
		this.moOnSelectionChangedEvent.emit(this.selected);
	}

	//#endregion

}