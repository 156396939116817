import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { DeviceInfo } from '@capacitor/device';
import { EMPTY, Observable, firstValueFrom, merge, of } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ContactHelper } from '../../helpers/contactHelper';
import { NumberHelper } from '../../helpers/numberHelper';
import { ObjectHelper } from '../../helpers/objectHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { EPrefix } from '../../model/EPrefix';
import { ConfigData } from '../../model/config/ConfigData';
import { IContact } from '../../model/contacts/IContact';
import { IDatabaseMeta } from '../../model/databaseDocument/IDatabaseMeta';
import { ETimetablePattern } from '../../model/date/ETimetablePattern';
import { ELifeCycleEvent } from '../../model/lifeCycle/ELifeCycleEvent';
import { ILifeCycleEvent } from '../../model/lifeCycle/ILifeCycleEvent';
import { ActivePageManager } from '../../model/navigation/ActivePageManager';
import { ENotificationFlag } from '../../model/notification/ENotificationFlag';
import { Database } from '../../model/store/Database';
import { EDatabaseRole } from '../../model/store/EDatabaseRole';
import { IChangeEvent } from '../../model/store/IChangeEvent';
import { IDataSource } from '../../model/store/IDataSource';
import { IDiagnosticsDocument } from '../../model/store/IDiagnosticsDocument';
import { IStoreDocument } from '../../model/store/IStoreDocument';
import { IUiResponse } from '../../model/uiMessage/IUiResponse';
import { IUpdate } from '../../model/update/IUpdate';
import { IDmsMeta } from '../../modules/dms/model/IDmsMeta';
import { LocalDmsService } from '../../modules/dms/services/localDms.service';
import { SyncDmsService } from '../../modules/dms/services/syncDms.service';
import { NoOnlineReliableNetworkError } from '../../modules/network/models/errors/NoOnlineReliableNetworkError';
import { ObservableArray } from '../../modules/observable/models/observable-array';
import { ObservableProperty } from '../../modules/observable/models/observable-property';
import { IPermissionSet } from '../../modules/permissions/models/IPermissionSet';
import { EContextualPermission } from '../../modules/permissions/models/econtextual-permission';
import { PermissionRulesService } from '../../modules/permissions/services/permission-rules.service';
import { C_ADMINISTRATORS_ROLE_ID, PermissionsService } from '../../modules/permissions/services/permissions.service';
import { StoreBenchmarkService } from '../../modules/store/benchmark/services/store-benchmark.service';
import { ChangeTrackingService } from '../../modules/store/change-tracking/services/change-tracking.service';
import { IDatabaseSyncMarker } from '../../modules/store/model/IDatabaseSyncMarker';
import { ELocalToServerReplicationMode } from '../../modules/store/model/elocal-to-server-replication-mode.enum';
import { secure } from '../../modules/utils/rxjs/operators/secure';
import { BytePipe } from '../../pipes/byte.pipe';
import { ApplicationService } from '../../services/application.service';
import { BackgroundTaskService } from '../../services/backgroundTask.service';
import { TaskBase } from '../../services/backgroundTask/TaskBase';
import { ContactsService } from '../../services/contacts.service';
import { GroupsService } from '../../services/groups.service';
import { ShowMessageParamsPopup } from '../../services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '../../services/loading.service';
import { NetworkService } from '../../services/network.service';
import { NotificationService } from '../../services/notification.service';
import { PlatformService } from '../../services/platform.service';
import { SecurityService } from '../../services/security.service';
import { Store } from '../../services/store.service';
import { UiMessageService } from '../../services/uiMessage.service';
import { UpdateService } from '../../services/update.service';
import { WorkspaceService } from '../../services/workspace.service';

enum ESynchroType {
	all = 0,
	checkpoint
}
interface ICanSynchronizeDatabaseSyncMarker extends IDatabaseSyncMarker {
	readonly canSynchronize: boolean;
}

@Component({
	selector: "calao-diagnostic",
	templateUrl: './diagnostics.component.html',
	styleUrls: ['./diagnostics.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagnosticsComponent extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Identifiant du document pouchDb permettant d'avoir des renseignements de diagnostique. */
	private static readonly C_DIAGNOSTICS_DOCUMENT_ID: string = "app_storage_diagnostics";
	/** Identifiant du composant dans les logs. */
	private static readonly C_LOG_ID = "DIAG.C::";

	/** Identifiant de la base de données d'application locale de l'app. */
	private readonly C_APP_STORAGE_DATABASE_ID?: string;
	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter);

	//#endregion

	//#region PROPERTIES

	/** Identifiant de l'application. */
	public readonly appId: string = ConfigData.appInfo.appId;
	/** Numéro de version de l'application. */
	public readonly appVersion: string | undefined = ConfigData.appInfo.appVersion;
	/** Identifiant de l'environnement sélectionné. */
	public readonly appSelectedEnvironment: string = ConfigData.environment.id;
	/** Login de l'utilisateur. */
	public readonly userLogin: string = ConfigData.appInfo.login;
	/** Identifiant de l'apparail utilisé. */
	public readonly systemDeviceId: string = ConfigData.appInfo.deviceId;
	/** Url utilisée pour les requêtes https. */
	public readonly dataUrl: string = ConfigData.environment.cloud_url;
	/** Objet contenant l'identifiant de la base de données de config et le document méta associé. */
	public readonly dataConfigDatabase: { id: string, databaseMeta: string } = { id: ConfigData.environment.coreRoleAppConfig, databaseMeta: "" };
	/** Indique si l'on doit afficher le boutton de mise à jour. */
	public readonly canUpdate: boolean = !ConfigData.environment.storeRelease && this.isvcPlatform.isMobileApp;
	/** Indique si l'on est sur une application mobile native. */
	public readonly isMobileApp: boolean = this.isvcPlatform.isMobileApp;

	/** Prenom NOM de l'utilisateur. */
	public userName?: string;
	/** Si aucune mise à jour '0', sinon la version de la mise à jour disponible. */
	public availableUpdateVersion?: string;
	/** WidgetId de l'app `fr.calaosoft.mobile.[nom app]`. */
	public widgetId = "";
	/** Réseau connecté */
	public networkOnline?: string;
	/** Réseau fiable */
	public networkReliable?: string;
	/** Identifiant des workspaces de l'utilisateur. */
	public workspaceIds: string[] = [];

	//#region Diagnostics document

	public readonly obsIsOneSignalDebugEnabled = new ObservableProperty<boolean>();
	public readonly obsIsPouchDbDebugEnabled = new ObservableProperty<boolean>();

	/** Document de diagnostique de l'app. */
	public diagnosticsDocument: IDiagnosticsDocument = { _id: DiagnosticsComponent.C_DIAGNOSTICS_DOCUMENT_ID } as any;

	//#endregion Diagnostics document

	//#region Notifications

	/** Indication sur l'état du service de notification. */
	public notificationState?: string;
	/** Id obtenu par le service de notifications. */
	public notificationId?: string;
	/** Token obtenu par le service de notifications. */
	public notificationPushToken?: string;
	public notificationToken?: string;

	//#endregion Notifications

	//#region DMS

	public readonly C_DATABASE_SYNC_DATE_FORMAT = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;
	public readonly obsDatabaseSyncMarkers: ObservableArray<ICanSynchronizeDatabaseSyncMarker>;

	/** Nombre de fichiers téléchargés. */
	public numberOfFiles = 0;
	/** Poids des fichiers téléchargés. */
	public fileSize?: number;
	/** Nombre de fichiers en attente d'upload. */
	public pendingUpload = 0;
	/** Nombre de fichiers en attente de téléchargement. */
	public pendingDownload = 0;
	/** Tableau regroupant les documents du DMS. */
	public files?: IDmsMeta[];
	public networkType?: string;
	public networkDownlinkMax?: string;

	//#endregion DMS

	//#region BTS

	public readonly observableTrackedCountByDatabaseId = new Map<string, ObservableProperty<number>>();

	/** Nombre de tâches en cours. */
	public numberOfRunningTasks?: number;
	/** Nombre de tâches en attente. */
	public numberOfWaitingTasks?: number;

	private readonly mbIsCurrentUserAdmin: boolean;
	/** Indique si l'utilisateur courant est administrateur */
	public get isCurrentUserAdmin(): boolean { return this.mbIsCurrentUserAdmin; }

	//#endregion BTS

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcUpdate: UpdateService,
		private readonly isvcGroups: GroupsService,
		private readonly isvcPermissions: PermissionsService,
		private readonly isvcNotifications: NotificationService,
		private readonly isvcApp: ApplicationService,
		private readonly isvcLocalDms: LocalDmsService,
		private readonly ioRouter: Router,
		private readonly ioRoute: ActivatedRoute,
		private readonly isvcNetwork: NetworkService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcBackgroundTasks: BackgroundTaskService,
		private readonly isvcSyncDms: SyncDmsService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcContact: ContactsService,
		private readonly isvcSecurity: SecurityService,
		private readonly isvcChangeTracking: ChangeTrackingService,
		private readonly isvcStoreBenchmark: StoreBenchmarkService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcPermissionRules: PermissionRulesService,
		private readonly ioBytePipe: BytePipe,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
		try {
			this.C_APP_STORAGE_DATABASE_ID = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage));
		}
		catch (poError) {
			console.warn(`${DiagnosticsComponent.C_LOG_ID}Error while getting application storage database ID.`, poError);
		}

		if (ArrayHelper.hasElements(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.dmsStorage, false)))
			this.initDmsDiagnostics();

		this.mbIsCurrentUserAdmin = this.isvcPermissions.hasRole(C_ADMINISTRATORS_ROLE_ID);
		this.obsDatabaseSyncMarkers = new ObservableArray(this.getCanSynchronizeDatabaseSyncMarkers$().pipe(secure(this)));
	}

	public ngOnInit(): void {
		if (this.C_APP_STORAGE_DATABASE_ID) {
			this.initDiagnosticsDocument().subscribe(); // Initialisation du document de diagnostique.
			this.initDatabaseMeta().subscribe(); // Initialisation du document de méta.
		}

		this.onRefreshNetworkButtonClick(); // Initialisation du widgetId de l'app.
		this.onCheckUpdatesButtonClick(); // Cherche si une mise à jour est disponible.
		this.initChangeTrackingDiagnostic();

		this.initTasks();

		if (this.isvcPlatform.isMobileApp) { // Initialisations pertinentes sur mobile uniquement
			this.initWidgetId(); // Initialisation du widgetId de l'app.
			this.waitNotificationInit(); // Récupère les données du service de notification.
		}

		this.workspaceIds = this.isvcWorkspace.getUserWorkspaceIds();

		this.initUserNameAsync();
	}

	private initChangeTrackingDiagnostic(): void {
		this.isvcStore.getDatabases().forEach((poDatabase: Database) => {
			if (poDatabase.localToServerReplicationMode === ELocalToServerReplicationMode.changeTracking) {
				let loObservableTrackedCount: ObservableProperty<number> | undefined =
					this.observableTrackedCountByDatabaseId.get(poDatabase.id);

				if (!loObservableTrackedCount) {
					loObservableTrackedCount = new ObservableProperty();
					const loChanges$: Observable<IChangeEvent<IStoreDocument>> = merge(
						this.isvcStore.localChanges({ databaseId: poDatabase.id }),
						this.isvcStore.changes({ databaseId: poDatabase.id, remoteChanges: true, activePageManager: this.moActivePageManager })
					);

					loObservableTrackedCount.bind(
						loChanges$.pipe(
							startWith(undefined),
							mergeMap(() => this.isvcChangeTracking.countTrackedAsync(poDatabase.id))
						),
						this
					);

					this.observableTrackedCountByDatabaseId.set(poDatabase.id, loObservableTrackedCount);
				}
			}
		});
	}

	public displayChangeTrackingDetail(psDatabaseId: string): Promise<boolean> {
		return this.ioRouter.navigate(["change-tracking", psDatabaseId], { relativeTo: this.ioRoute });
	}

	/** Initialise les informations relatives au DMS */
	private initDmsDiagnostics(): void {
		this.ioRouter.events.pipe(
			filter((poEvent: Event) => poEvent instanceof NavigationEnd && poEvent.url === "/diagnostics"),
			mergeMap(_ => this.initLocalFiles()),
			mergeMap(_ => this.initPendingFiles()),
			catchError(poError => {
				console.error(`${DiagnosticsComponent.C_LOG_ID}Error while initializing DMS diagnostics.`, poError);
				return EMPTY;
			}),
			takeUntil(this.destroyed$)
		)
			.subscribe();
	}

	/** Récupère les marqueurs de synchronisation des bases de données. */
	private getCanSynchronizeDatabaseSyncMarkers$(): Observable<ICanSynchronizeDatabaseSyncMarker[]> {
		return this.isvcStore.getSyncMarkers()
			.pipe(
				map((paSyncMarkers: IDatabaseSyncMarker[]) => {
					return paSyncMarkers.map((poItem: IDatabaseSyncMarker): ICanSynchronizeDatabaseSyncMarker => {
						let lbCanSynchronize: boolean;

						try { lbCanSynchronize = this.isvcStore.getDatabaseById(poItem.databaseId).hasLocalAndRemoteInstance(); }
						catch (poError) { lbCanSynchronize = false; }

						return { ...poItem, canSynchronize: lbCanSynchronize };
					});
				})
			);
	}

	public onRefreshNetworkButtonClick(): void {
		this.isvcNetwork.asyncIsNetworkReliable().pipe(
			map((pbReliable: boolean) => {
				this.networkType = this.isvcNetwork.getNetworkType();
				this.networkDownlinkMax = this.isvcNetwork.getNetworkDonwlinkMax();
				this.networkOnline = this.isvcNetwork.isNetworkOnline() ? "connecté" : "déconnecté";
				this.networkReliable = `${pbReliable ? "fiable" : "non fiable"} (${this.isvcNetwork.isNetworkReliable() ? "fiable" : "non fiable"})`;

				return true;
			}),
			catchError(poError => {
				console.error(`${DiagnosticsComponent.C_LOG_ID}Error while refreshing networks diagnostics.`, poError);
				return EMPTY;
			}))
			.subscribe();
	}

	protected onLifeCycleEvent(poEvent: ILifeCycleEvent): void {
		switch (poEvent.data.value) {

			case ELifeCycleEvent.viewWillLeave:
				this.isvcStore.put(this.diagnosticsDocument, this.C_APP_STORAGE_DATABASE_ID)
					.pipe(
						tap(
							_ => console.log(`${DiagnosticsComponent.C_LOG_ID}Diagnostics settings saved.`),
							poError => console.error(DiagnosticsComponent.C_LOG_ID, poError)
						)
					)
					.subscribe();
				break;
		}
	}

	private initDiagnosticsDocument(): Observable<IDiagnosticsDocument[]> {
		return this.isvcStore.get({
			databaseId: this.C_APP_STORAGE_DATABASE_ID,
			viewParams: { key: DiagnosticsComponent.C_DIAGNOSTICS_DOCUMENT_ID, include_docs: true }
		} as IDataSource)
			.pipe(
				tap(
					(paResults: IDiagnosticsDocument[]) => {
						if (ArrayHelper.hasElements(paResults)) {
							this.diagnosticsDocument = ArrayHelper.getFirstElement(paResults);
							this.obsIsOneSignalDebugEnabled.value = this.diagnosticsDocument.isOneSignalDebugEnabled;
							this.obsIsPouchDbDebugEnabled.value = this.diagnosticsDocument.isPouchDbDebugEnabled;
						}
						this.detectChanges();
					},
					poError => console.error(DiagnosticsComponent.C_LOG_ID, poError)
				),
				takeUntil(this.destroyed$)
			);
	}

	private initDatabaseMeta(): Observable<IDatabaseMeta | undefined> {
		return this.isvcStore.getDatabaseMeta()
			.pipe(
				tap(
					(poDatabaseMeta?: IDatabaseMeta) => {
						this.dataConfigDatabase.databaseMeta = JSON.stringify(poDatabaseMeta);
						this.detectChanges();
					},
					poError => console.error(DiagnosticsComponent.C_LOG_ID, poError)
				),
				takeUntil(this.destroyed$)
			);
	}

	private initWidgetId(): void {
		this.widgetId = this.isvcPlatform.widgetId;
		this.detectChanges();
	}

	private initLocalFiles(): Observable<IDmsMeta[]> {
		return this.isvcLocalDms.getLocalMetaDataFiles()
			.pipe(
				tap(
					(paFiles: IDmsMeta[]) => {
						this.files = [...paFiles];
						this.numberOfFiles = paFiles.length;
						paFiles.forEach((poFile: IDmsMeta) => {
							if (!ObjectHelper.isDefined(this.fileSize))
								this.fileSize = 0;
							this.fileSize += poFile.size;
						});

						this.detectChanges();
					},
					poError => console.error(DiagnosticsComponent.C_LOG_ID, poError)
				),
				takeUntil(this.destroyed$)
			);
	}

	private initPendingFiles(): Observable<IStoreDocument[]> {
		return this.isvcSyncDms.getPendingFiles()
			.pipe(
				tap(
					(paFiles: IStoreDocument[]) => {
						this.pendingDownload = 0;
						this.pendingUpload = 0;

						paFiles.forEach((poFile: IStoreDocument) => {
							if (poFile._id.includes(EPrefix.pendingDownload))
								this.pendingDownload++;
							else if (poFile._id.includes(EPrefix.pendingUpload))
								this.pendingUpload++;
						});

						this.detectChanges();
					},
					poError => console.error(`${DiagnosticsComponent.C_LOG_ID}Error while getting waiting docs.`, poError)
				),
				takeUntil(this.destroyed$)
			);
	}

	/** Initialise l'affichage du nombre de tâches en fonction de leur état d'activité. */
	private initTasks(): void {

		this.isvcBackgroundTasks.getWaitingTasks$().pipe(takeUntil(this.destroyed$)).subscribe((laTasks: TaskBase[]) => {
			this.numberOfWaitingTasks = laTasks.length;
			this.detectChanges();
		});

		this.isvcBackgroundTasks.getRunningTasks$().pipe(takeUntil(this.destroyed$)).subscribe((laTasks: TaskBase[]) => {
			this.numberOfRunningTasks = laTasks.length;
			this.detectChanges();
		});

	}

	/** Appelée lors du changement d'état du toggle associé au debug pouchDB.
	 * @param pbEvent Booléen indiquant à quel état le toggle associé est passé.
	 */
	public onPouchDbDebugChanged(pbEvent: boolean): void {
		this.obsIsPouchDbDebugEnabled.value = this.diagnosticsDocument.isPouchDbDebugEnabled = pbEvent;

		this.diagnosticsDocument.isPouchDbDebugEnabled ? Store.enablePouchDBDebug() : Store.disablePouchDBDebug();
	}

	/** Appelée lors du changement d'état du toggle associé au debug OneSignal.
	 * @param pbEvent Booléen indiquant à quel état le toggle associé est passé.
	 */
	public onOneSignalDebugChanged(pbEvent: boolean): void {
		this.obsIsOneSignalDebugEnabled.value = this.diagnosticsDocument.isOneSignalDebugEnabled = pbEvent;

		this.diagnosticsDocument.isOneSignalDebugEnabled ?
			this.isvcNotifications.enableOneSignalDebug() : this.isvcNotifications.disableOneSignalDebug();
	}

	public onCheckUpdatesButtonClick(): void {
		this.showUpdatePopup().pipe(
			catchError(poError => {
				console.error(`${DiagnosticsComponent.C_LOG_ID}Error while checking updates.`, poError);
				return EMPTY;
			}),
			takeUntil(this.destroyed$)).subscribe();
	}

	private showUpdatePopup(): Observable<any> {
		return this.isvcUpdate.popupUpdate()
			.pipe(
				tap(
					(poUpdate: IUpdate) => {
						if (!poUpdate) {	// Si aucune mise à jour.
							if (this.availableUpdateVersion === "Aucune") {
								// Affichage d'un message pour indiquer à l'utilisateur qu'une recherche de mise à jour a bien été faite.
								this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Aucune mise à jour disponible.", header: "Mise à jour" }));
							}

							this.availableUpdateVersion = "Aucune";
						}
						else
							this.availableUpdateVersion = poUpdate.version;
					},
					(poError: any) => {
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ header: "Problème lors de la recherche de mise à jour.", message: poError }));
						this.availableUpdateVersion = "Erreur";
					}
				),
				finalize(() => this.detectChanges())
			);
	}

	/** Affiche la liste des groupes auxquels appartient l'utilisateur actif. */
	public onShowUserGroupsClick(): void {
		this.isvcGroups.getUserGroupsIds()
			.pipe(
				tap((psGroupIds: string[]) =>
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: JSON.stringify(psGroupIds), header: "Groupes" }))
				),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Affiche les permissions de l'utilisateur actif. */
	public onShowUserPermissionsClick(): void {
		if (!this.isvcPermissions.hasPermissions) {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({ message: "Le contrôle des permissions n'est pas activé pour cette application.", header: "Permissions" })
			);
		}
		else
			this.isvcPermissions["getUserPermissionSet"]() // Méthode privée
				.pipe(
					switchMap(async (poPermissions: IPermissionSet) => {
						const loLoader = await this.isvcLoading.create();
						loLoader.present();
						const lsPermissionsMessage: string = JSON.stringify(poPermissions) // Formattage de l'affichage des permissions (pas parfait mais plus lisible).
							.replace(/:/g, ": ")
							.replace(/,/g, ",<br>")
							.replace(/{/g, "{<br>")
							.replace(/}/g, "<br>}<br>");

						let lsPermissionsRuleMessage = "";
						if (lsPermissionsMessage.includes(EContextualPermission.rule)) {
							lsPermissionsRuleMessage += "<br>Règle(s) de permission évaluée(s) :<br>";
							const laPermissionRules: EContextualPermission[] = this.isvcPermissionRules.getAllPermissionsRuleFromPermissionSet(poPermissions);
							for (const poPermissionRule of laPermissionRules) {
								const lsRule: string = this.isvcPermissionRules.extractRuleId(poPermissionRule);
								try {
									const poRuleDescriptor = await firstValueFrom(this.isvcPermissionRules.getRuleDescriptor(lsRule));
									const paEntityList = await firstValueFrom(this.isvcPermissionRules.getAutorizedEntitiesIdList(poRuleDescriptor));
									lsPermissionsRuleMessage += `${lsRule} -> ${JSON.stringify(paEntityList)}<br>`;
								}
								catch {
									lsPermissionsRuleMessage += `${lsRule} -> Erreur<br>`;
								}
							}
						}
						loLoader.dismiss();
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: lsPermissionsMessage + lsPermissionsRuleMessage, header: "Permissions" }));
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
	}

	/** Affiche les métadonnées des bases de données courantes. */
	public showDatabaseMeta(): void {
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: this.dataConfigDatabase.databaseMeta, header: "Métadonnées" }));
	}

	/** Evalue les performances d'accès à la base de workspace de l'utilisateur actif. */
	public async benchmarkDatabasePerformancesAsync(): Promise<void> {
		try {
			if (!ArrayHelper.hasElements(this.workspaceIds))
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Aucune base de workspace à tester.", header: "Erreur" }));
			else {
				for (const lsWorkspaceId of this.workspaceIds) {
					const loLoader = await this.isvcLoading.create(`Evaluation des performances d'accès à ${lsWorkspaceId} ...`);
					try {
						loLoader.present()
						const loResult = await this.isvcStoreBenchmark.benchmarkDatabaseAsync(this.isvcWorkspace.getDatabaseIdFromWorkspaceIdAndRole(lsWorkspaceId, EDatabaseRole.workspace));

						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: JSON.stringify(loResult), header: "Performances" }));
					} finally {
						loLoader.dismiss();
					}
				};
			}
		} catch (error) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: JSON.stringify(error), header: "Erreur" }));
		}
	}

	public changeBenchmarkParams(): void {
		try {
			const lsLoops = prompt("loops?", this.isvcStoreBenchmark.defaultBenchmarkParams.loops.toString());
			const lsLog = prompt("log?", this.isvcStoreBenchmark.defaultBenchmarkParams.log.toString());
			const lsNbDocsPerGet = prompt("nbDocsPerGet?", this.isvcStoreBenchmark.defaultBenchmarkParams.nbDocsPerGet.toString());
			const lsIncludeDocs = prompt("includeDocs?", this.isvcStoreBenchmark.defaultBenchmarkParams.includeDocs.toString());
			const lsRandomOffset = prompt("randomOffset?", this.isvcStoreBenchmark.defaultBenchmarkParams.randomOffset.toString());
			const lsRandomStartKey = prompt("randomStartKey?", this.isvcStoreBenchmark.defaultBenchmarkParams.randomStartKey.toString());
			const lsViewName = prompt("viewName?", this.isvcStoreBenchmark.defaultBenchmarkParams.viewName.toString());

			if (lsLoops && !StringHelper.isBlank(lsLoops)) this.isvcStoreBenchmark.defaultBenchmarkParams.loops = Number.parseInt(lsLoops);
			if (lsLog && !StringHelper.isBlank(lsLog)) this.isvcStoreBenchmark.defaultBenchmarkParams.log = lsLog.toLowerCase() === "true" ? true : false;
			if (lsNbDocsPerGet && lsNbDocsPerGet.length > 0) this.isvcStoreBenchmark.defaultBenchmarkParams.nbDocsPerGet = Number.parseInt(lsNbDocsPerGet);
			if (lsIncludeDocs && lsIncludeDocs.length > 0) this.isvcStoreBenchmark.defaultBenchmarkParams.includeDocs = lsIncludeDocs.toLowerCase() === "true" ? true : false;
			if (lsRandomOffset && lsRandomOffset.length > 0) this.isvcStoreBenchmark.defaultBenchmarkParams.randomOffset = lsRandomOffset?.toLowerCase() === "true" ? true : false;
			if (lsRandomStartKey && lsRandomStartKey.length > 0) this.isvcStoreBenchmark.defaultBenchmarkParams.randomStartKey = lsRandomStartKey?.toLowerCase() === "true" ? true : false;
			if (lsViewName && lsViewName.length > 0) this.isvcStoreBenchmark.defaultBenchmarkParams.viewName = lsViewName.toLowerCase();

			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: JSON.stringify(this.isvcStoreBenchmark.defaultBenchmarkParams), header: "Paramètres modifiés" }));
		} catch (error) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: JSON.stringify(error), header: "Erreur" }));
		}
	}

	// Navigation vers la page de diagnostics des fichiers du DMS.
	public navigateToDiagnosticFiles(): void {
		this.ioRouter.navigate(["files"], { relativeTo: this.ioRoute });
	}

	public navigateToDiagnosticTasks(): void {
		this.ioRouter.navigate(["tasks"], { relativeTo: this.ioRoute });
	}

	public navigateToLogsPage(): void {
		this.ioRouter.navigate(["logs"], { relativeTo: this.ioRoute });
	}

	/** Attend l'événement d'initialisation de OneSignal, puis récupère l'identifiant de l'utilisateur. */
	private waitNotificationInit(): void {
		this.notificationId = "";

		if (!ConfigData.oneSignal) {
			this.notificationState = "Pas de configuration";
			this.detectChanges();
		}
		else {
			this.notificationState = "Initialisation ...";

			this.isvcApp.observeFlag(ENotificationFlag.Initialized)
				.pipe(
					tap(() => {
						if (!StringHelper.isBlank(this.isvcNotifications.deviceId)) {
							this.notificationId = this.isvcNotifications.deviceId; // Affectation de l'identifiant de l'appareil.
							this.notificationPushToken = this.isvcNotifications.pushToken; // Affectation du pushToken.
							if (ConfigData.authentication?.token)
								this.notificationToken = ConfigData.authentication?.token; // Affectation du token de session => utilité à vérifier, non applicable dans AFH-Admin.
							this.notificationState = "Initialisé";
						}
						else
							this.notificationState = this.isvcPlatform.isMobileApp ? "Pas d'identifiant" : "Indisponible sur navigateur web";

						this.detectChanges();
					}),
					catchError(poError => {
						console.error(`${DiagnosticsComponent.C_LOG_ID}Error while initializing notifications diagnostics.`, poError);
						return EMPTY;
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	private initUserNameAsync(): Promise<void> {
		const lsContactId: string | undefined = ContactsService.getUserContactId();
		let loGetContact$: Observable<IContact | undefined>;

		if (!lsContactId)
			loGetContact$ = of(undefined);
		else
			loGetContact$ = this.isvcContact.getContact(lsContactId);

		return loGetContact$.pipe(take(1)).toPromise()
			.then(((poContact?: IContact) => {
				this.userName = poContact ? ContactHelper.getCompleteFormattedName(poContact) : "Utilisateur";
				this.detectChanges();
			}));
	}

	/** Supprime le token enreigstré et recharge l'application, permet d'éviter le bug de la session expirée pour le moment. */
	public resetTokenAsync(): Promise<void> {
		return this.isvcSecurity.clearTokenAsync()
			.then(_ => this.ioRouter.navigateByUrl("home"))
			.then(_ => ApplicationService.reloadApp());
	}

	public async showForceSynchronizePopupAsync(poDatabaseSyncMarker: IDatabaseSyncMarker): Promise<void> {
		const loResponse: IUiResponse<ESynchroType> | undefined = await this.isvcUiMessage.showPopupMessageAsync<ESynchroType>(this.createForceSynchronizeParamsPopup());

		if (loResponse) { // Si on a une réponse, on la traite.
			try {
				await this.isvcStore.forceSynchronizeAsync(
					poDatabaseSyncMarker.databaseId,
					loResponse.response === ESynchroType.all ? 0 : poDatabaseSyncMarker.localSequenceNumber
				);
			}
			catch (poError) {
				console.error(`${DiagnosticsComponent.C_LOG_ID}Error when force synchro :`, poError);
				await this.showSynchronizeErrorPopupAsync(poError);
			}
			finally { ApplicationService.reloadApp(); }
		}
	}

	private createForceSynchronizeParamsPopup(): ShowMessageParamsPopup {
		return new ShowMessageParamsPopup({
			header: "Attention",
			message: "La synchronisation forcée peut mettre un certain temps avant de se terminer et bloque l'utilisation de l'application pendant ce temps.</br>L'application redémarre automatiquement une fois la synchronisation de la base de données effectuée.",
			buttons: [
				{ text: "Dernier point de contrôle", handler: () => UiMessageService.getCustomResponse(ESynchroType.checkpoint) },
				{ text: "Complète", handler: () => UiMessageService.getCustomResponse(ESynchroType.all) }
			]
		});
	}

	private showSynchronizeErrorPopupAsync(poError: any): Promise<IUiResponse<void> | void> {
		const lsPopupMessage: string = poError instanceof NoOnlineReliableNetworkError ?
			"Une connexion internet est requise pour synchroniser les données." :
			"Une erreur est survenur lors de synchronisation forcée.<br/>L'application va maintenant redémarrer.";

		return this.isvcUiMessage.showPopupMessageAsync(
			new ShowMessageParamsPopup({ header: "Erreur", message: lsPopupMessage })
		);
	}

	public onDeviceClicked(): void {
		this.showDeviceInfoPopupAsync();
	}

	private async showDeviceInfoPopupAsync(): Promise<void> {
		const loDevice: DeviceInfo = await this.isvcPlatform.getDeviceInfoAsync();
		/** Clef dont la valeur est en octets. */
		const laByteKeys = ["memUsed", "diskFree", "diskTotal", "realDiskFree", "realDiskTotal"];

		const lsMessage: string = Object.keys(loDevice).map((psKey: string): string => {
			let lsValue: string | number | boolean = loDevice[psKey];
			if (laByteKeys.includes(psKey) && NumberHelper.isValid(lsValue))
				lsValue = this.ioBytePipe.transform(lsValue);

			return `${psKey}: ${lsValue}`;
		}).join(("<br>"));

		const loPopup = new ShowMessageParamsPopup({ header: "Appareil", message: lsMessage, buttons: [{ text: "Fermer" }] });
		this.isvcUiMessage.showPopupMessage(loPopup);
	}

	//#endregion

}