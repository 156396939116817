import { Component } from '@angular/core';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { FormsService } from '../../../../services/forms.service';

@Component({
	selector: 'deprecated-calao-toggle-field',
	templateUrl: './toggleField.component.html'
})
export class ToggleFieldComponent extends FieldBase<boolean> { //todo : typer + params.

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	public onModelChanged(): void {
		this.markAsDirty();
	}

	//#endregion
}