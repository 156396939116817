import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../../../../../helpers/ComponentBase';
import { Entity } from '../../../../../entities/models/entity';
import { FieldBase } from '../../../../models/FieldBase';
import { IActionButtonFieldParams } from '../../../../models/actionButtonFields/IActionButtonFieldParams';
import { FormsService } from '../../../../services/forms.service';

@Component({ template: "", selector: "calao-action-button-field-base" })
export abstract class ActionButtonFieldBaseComponent<T, M extends Entity = Entity> extends FieldBase<any, M> implements OnInit {

	//#region FIELDS

	/** Texte informant l'utilisateur qu'il n'y a pas de coordonnées GPS ni d'adresse de renseignées. */
	public static readonly C_UNDEFINED_COORDINATES_AND_ADDRESS_TEXT = "L'adresse et la position GPS ne sont pas définies.";

	//#endregion

	//#region PROPERTIES

	/** Paramètres que l'on peut fournir à ce composant. */
	public params: IActionButtonFieldParams<T>;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService, poChangeDetectorRef?: ChangeDetectorRef) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.params = this.to.data;

		if (!this.params.specParams)
			this.params.specParams = {} as T;

		// Chargement de la section expressionProperties (voir documentation de formly).
		this.params.disabled = this.to.disabled;
	}

	/** Définit la méthode à appeler en fonction de l'action du bouton inscrite dans le templateOptions.
	 * @param poComponent Composant avec lequel réaliser une action.
	 */
	public abstract action(poComponent?: ComponentBase): void;

	//#endregion
}