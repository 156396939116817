import { Component, OnInit } from '@angular/core';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { EAutocapitalize } from '../../../../../model/forms/EAutocapitalize';
import { FieldBase } from '../../../../../model/forms/FieldBase';
import { ITextareaFieldParams } from '../../../../../model/forms/inputs/ITextareaFieldParams';

@Component({
	selector: 'deprecated-calao-textarea-field',
	templateUrl: './textareaField.component.html',
	styleUrls: ['./inputs.component.scss']
})
export class TextareaFieldComponent extends FieldBase<string> implements OnInit {

	//#region PROPERTIES

	/** Différents paramètres possibles pour ce composant de formulaire. */
	public params: ITextareaFieldParams;

	//#endregion

	//#region METHODS


	public override ngOnInit(): void {
		super.ngOnInit();

		this.params = this.to.data;
		this.initProperties();
	}

	private initProperties(): void {
		if (this.params.autoGrow === undefined)
			this.params.autoGrow = true;

		if (StringHelper.isBlank(this.params.autocapitalize))
			this.params.autocapitalize = EAutocapitalize.sentences;
	}

	public onModelChanged(): void {
		this.markAsDirty();
	}

	//#endregion

}