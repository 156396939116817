import { ObjectHelper } from "../../../../helpers/objectHelper";
import { FilesystemErrorBase } from "./filesystem-error-base";

export class FilesystemRemoveError extends FilesystemErrorBase {

	constructor(poError: any, psPathOrUri: string) {
		super(poError, `Can not remove '${psPathOrUri}'.`);
		// Nécessaire pour que le 'instanceof FilesystemRemoveError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, FilesystemRemoveError);
	}

}