import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, combineLatest, defer, of, throwError } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, mergeMapTo, take } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { ObjectHelper } from '../helpers/objectHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { EPrefix } from '../model/EPrefix';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { IApplicationEvent } from '../model/application/IApplicationEvent';
import { Version } from '../model/application/Version';
import { ConfigData } from '../model/config/ConfigData';
import { EFormEventType } from '../model/forms/EFormEventType';
import { IFormDescriptor } from '../model/forms/IFormDescriptor';
import { IFormDescriptorDataSource } from '../model/forms/IFormDescriptorDataSource';
import { IFormEvent } from '../model/forms/IFormEvent';
import { IFormListEvent } from '../model/forms/IFormListEvent';
import { ESecurityFlag } from '../model/security/ESecurityFlag';
import { Database } from '../model/store/Database';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { EStoreEventStatus } from '../model/store/EStoreEventStatus';
import { EStoreEventType } from '../model/store/EStoreEventType';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { IStoreEvent } from '../model/store/IStoreEvent';
import { Entity } from '../modules/entities/models/entity';
import { ApplicationService } from './application.service';
import { EntityLinkService } from './entityLink.service';
import { FlagService } from './flag.service';
import { PatternResolverService } from './pattern-resolver.service';
import { Store } from './store.service';

@Injectable({ providedIn: 'root' })
export class FormsService<T extends Entity = Entity> {

	//#region FIELDS

	/** Sujet du service des FormList permettant de partager/envoyer/recevoir des données via un système d'abonnement. */
	private moFormListSubject: Subject<IFormListEvent<T>>;
	/** Sujet qui permetra d'envoyer des événements au formulaire */
	private moFormEventSubject: Subject<IFormEvent> = new Subject();

	/** URL de base pour accéder aux descripteurs de formulaires locaux.  */
	private readonly C_LOCAL_DESCRIPTORS_BASE_URL = "/forms/descriptors/";
	/** Extension des descripteurs de formulaires locaux.  */
	private readonly C_LOCAL_DESCRIPTORS_EXTENSION = ".formDesc.json";

	//#endregion

	//#region PROPERTIES

	/** Identifiant de l'action "back" lors d'un enregistrement de formulaire. */
	public static readonly C_BACK_ACTION_ID: string = "back";
	/** Nombre maximum d'entrées dans la liste de formulaire à afficher, 50 par défaut. */
	public static readonly C_MAX_DISPLAY_ENTRIES: number = 50;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en base de données. */
		private readonly isvcStore: Store,
		/** Service de l'application */
		private readonly isvcApplication: ApplicationService,
		/** Service de gestion des flags dans l'application */
		private readonly isvcFlag: FlagService,
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly ioHttpClient: HttpClient
	) {
		this.moFormListSubject = new Subject();
	}

	/** Lève un événement pour un formulaire.
	 * @param poFormEvent Événement à lever.
	 */
	public raiseFormEvent(poFormEvent: IFormEvent): void {
		this.moFormEventSubject.next(poFormEvent);
	}

	/** Récupération de l'observable écoutant les événement des formulaires. */
	public getFormEventObservable(): Observable<IFormEvent> {
		return this.moFormEventSubject.asObservable();
	}

	/** Récupération de l'observable écoutant les événement des liste de formulaires. */
	public onFormListEvent(): Observable<IFormListEvent<T>> {
		return this.moFormListSubject.asObservable();
	}

	/** Lève un événement pour une liste de formulaires.
	 * @param poEvent Événement à lever.
	 */
	public raiseFormListEvent(poEvent: IFormListEvent<T>): void {
		this.moFormListSubject.next(poEvent as unknown as IFormListEvent<IStoreDocument>);
	}

	/** Supprime de la base de données l'entrée.
	 * @param poEntry Entrée à supprimer de la base de données.
	 * @param psDatabaseId Nom de la base de données sur laquelle effectuer la requête.
	 */
	public deleteFormEntry(poEntry: T, psDatabaseId: string): Observable<IStoreDataResponse> {
		return this.isvcStore.delete(poEntry, psDatabaseId)
			.pipe(
				mergeMap((poResponse: IStoreDataResponse) => {
					if (ConfigData.appInfo.useLinks)
						return this.isvcEntityLink.deleteEntityLinksById(poEntry._id).pipe(mapTo(poResponse));
					else
						return of(poResponse);
				}),
				catchError(poError => {
					console.error(`FORM.S:: Erreur suppression document ${poEntry} de la base ${psDatabaseId}`);
					return throwError(() => poError);
				})
			);
	}

	/** Exécute la requête qui récupère des données qui pouvant être : une entry (en fonctin de son id), plusieurs entry, ou un formDescriptor.
	 * @param poDataSource paramètres du menu que l'on veut initialiser.
	 * @param psDatabaseId id de la base de données à récupérer.
	 */
	private execGetAllEntries(poDataSource: IDataSource<T>): Observable<Array<T>> {
		return this.isvcStore.get<T>(poDataSource)
			.pipe(
				catchError(poError => {
					const lsStringifiedDatabases: string = poDataSource.databaseId ? poDataSource.databaseId : JSON.stringify(poDataSource.databasesIds);
					console.error(`FORM.S:: Erreur récupération base de données ${lsStringifiedDatabases} : `, poError);
					return throwError(() => poError);
				})
			);
	}

	/** Récupère toutes les entrées possèdant le `FormDescId` en paramètre et par la vue de la datasource, dans la base donnée par la datasource.
	 * @param poDataSource Donne la base et la vue à utiliser pour trouver les entrées, mode 'live' par défaut.
	 */
	public getEntries(poDataSource: IDataSource<T>): Observable<Array<T>> {
		const laDatabases: Database[] = [];
		poDataSource.live = poDataSource.live !== false; // Si on n'a pas mis explicitement un `live === false`, on le met.

		// Si l'utilisateur n'est pas authentifié on ne doit pas faire de preparation des workspaces.
		if (this.isvcFlag.getFlagValue(ESecurityFlag.authenticated)) {
			const laDatabasesIds: string[] | undefined = poDataSource.databaseId ? [poDataSource.databaseId] : poDataSource.databasesIds;
			if (ArrayHelper.hasElements(laDatabasesIds))
				poDataSource.databasesIds = this.isvcStore.prepareWorkspaceFiltersDatabases(laDatabasesIds);
		}
		else
			poDataSource.databasesIds = poDataSource.databaseId ? [poDataSource.databaseId] : poDataSource.databasesIds; // Pour retro compat

		if (poDataSource.databasesIds)
			poDataSource.databasesIds.forEach((psDatabaseId: string) => laDatabases.push(this.isvcStore.getDatabaseById(psDatabaseId)));

		// On est dans le cas où la base de données est initialisée.
		if (laDatabases.every((loDatabase: Database) => loDatabase && !StringHelper.isBlank(loDatabase.id) && loDatabase.isReady))
			return this.execGetAllEntries(poDataSource);

		else if (ArrayHelper.hasElements(laDatabases)) {
			// On est dans le cas où la base de données n'est pas encore initialisée, 'loDatabase' ne doit pas être undefined sinon cela veut dire
			// que la base de données n'a pas été mise dans le fichier de config de l'application --> elle ne sera donc jamais initialisée.
			return this.isvcApplication.appEvent$
				.pipe(
					filter((poEvent: IApplicationEvent) => this.filterGetEntriesFromApplicationEvent(poEvent, laDatabases, poDataSource)),
					take(1),
					mergeMapTo(this.execGetAllEntries(poDataSource))
				);
		}
		else {
			const lsMessage = "La base de données demandée pour récupérer les entries n'a pas été trouvé dans la config dynamique de l'application";
			console.error(`FORM.S:: ${lsMessage}`);
			return throwError(() => lsMessage);
		}
	}

	/** Filtre les événements d'application pour ne garder que celui qui nous intéresse.
	 * @param poEvent Événement d'application reçu.
	 * @param paWantedDatabases Tableau des bases de données permettant de vérifier si l'identifiant de la base de données traitées est celui souhaité.
	 * @param poDataSource Datasource qui doit contenir l'identifiant de la base de données cible.
	 */
	private filterGetEntriesFromApplicationEvent(poEvent: IApplicationEvent, paWantedDatabases: Array<Database>, poDataSource: IDataSource<T>)
		: boolean {

		let lbIsFilterOkay = false;

		if (poEvent.type === EApplicationEventType.StoreEvent) {

			if (poDataSource.databaseId)
				paWantedDatabases.push(this.isvcStore.getDatabaseById(poDataSource.databaseId));
			else if (poDataSource.databasesIds)
				poDataSource.databasesIds.forEach((psDatabaseId: string) => paWantedDatabases.push(this.isvcStore.getDatabaseById(psDatabaseId)));

			// On attend que le statut de l'initialisation de la base de données des entries soit terminée.
			lbIsFilterOkay = paWantedDatabases.some((poDatabase: Database) =>
				poDatabase && (poEvent as IStoreEvent).data.status === EStoreEventStatus.successed &&
				(poEvent as IStoreEvent).data.databaseId === poDatabase.id && (poEvent as IStoreEvent).data.storeEventType === EStoreEventType.Init
			);
		}

		return lbIsFilterOkay;
	}

	/** Récupère l'entrée dont l'id est en paramètre sur la base de données associée aux entrées de formulaires (entriesDb).
	 * @param psEntryId Id de l'entrée souhaitée
	 */
	public getEntryFromId(psEntryId: string, psDatabaseId?: string): Observable<IStoreDocument> {
		const loParams: IDataSource = {
			databaseId: psDatabaseId,
			databasesIds: StringHelper.isBlank(psDatabaseId) ? this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsEntries) : undefined,
			viewName: Store.C_DEFAULT_VIEW_DOCS,
			viewParams: {
				key: psEntryId,
				include_docs: true
			}
		};

		return this.isvcStore.getOne<T>(loParams);
	}

	/** Récupère la description dont l'id est en paramètre sur la base de données associée aux descriptions de formulaires (descriptionsDb).
	 * @param psFormDescId id du form Descriptor.
	 */
	public getFormDescriptor(psFormDescId: string): Observable<IFormDescriptor<T>> {
		if (StringHelper.isBlank(ConfigData.appInfo.appVersion)) {
			console.error(`FORM.S::Form descriptor retrieval failed : AppVersion is missing.`);
			return of(undefined);
		}

		const loRange = Version.getDescriptorVersionRange(psFormDescId, ConfigData.appInfo.appVersion);
		const loParams: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.formsDefinitions),
			viewParams: { include_docs: true }
		};

		if (loRange.minVersion === loRange.maxVersion) // Si pas de version dans le formDescId.
			loParams.viewParams!.key = loRange.minVersion;
		else {
			loParams.viewParams!.startkey = loRange.minVersion;
			loParams.viewParams!.endkey = loRange.maxVersion;
		}

		return combineLatest([
			this.isvcStore.get(loParams),
			this.getLocalFormDescriptor(psFormDescId)
		]).pipe(
			map(([paDescriptors, poLocalDescriptor]: [IFormDescriptor<T>[], IFormDescriptor<T> | undefined]) => {
				const laWsDatabaseIds: string[] = this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace);
				const laConfigDescriptors: IFormDescriptor<T>[] = [];
				const loWsDescriptors: IFormDescriptor<T>[] = [];

				paDescriptors.forEach((poDescriptor: IFormDescriptor<T>) => {
					if (laWsDatabaseIds.includes(StoreHelper.getDocumentCacheData(poDescriptor).databaseId))
						loWsDescriptors.push(poDescriptor);
					else
						laConfigDescriptors.push(poDescriptor);
				});

				const loDescriptor: IFormDescriptor<T> | undefined = this.getHighestPriorityFormDescriptor(
					ArrayHelper.getLastElement(laConfigDescriptors),
					ArrayHelper.getLastElement(loWsDescriptors),
					poLocalDescriptor
				);

				if (loDescriptor) {
					console.debug(`FORM.S::Form descriptor ${loDescriptor._id} is selected for application version ${ConfigData.appInfo.appVersion}.`);
					return loDescriptor;
				}
				else
					throw new Error("Aucun formulaire trouvé.");
			}),
			catchError(poError => {
				console.error("FORM.S:: Erreur récupération des données :", poError);
				return throwError(() => poError);
			})
		);
	}

	/** Récupère la description dont l'id est en paramètre dans les fichiers locaux.
	 * @param psFormDescId id du formDescriptor.
	 */
	private getLocalFormDescriptor(psFormDescId: string): Observable<IFormDescriptor<T> | undefined> {
		const lsPath = `${this.C_LOCAL_DESCRIPTORS_BASE_URL}${psFormDescId}${this.C_LOCAL_DESCRIPTORS_EXTENSION}`;
		return defer(() => this.checkIfLocalFormDescriptorExistsAsync(lsPath)).pipe( // Évite de retourner l'index.html à la place d'une 404.
			mergeMap((pbExists: boolean) => {
				if (!pbExists)
					return of(undefined);

				return this.ioHttpClient.get<IFormDescriptor<T>>(lsPath).pipe(
					catchError(poError => {
						if (poError.status === 404)
							return of(undefined);
						else
							return throwError(() => poError);
					})
				);
			})
		);
	}

	private checkIfLocalFormDescriptorExistsAsync(psJsonFilePath: string): Promise<boolean> {
		return this.ioHttpClient.head(psJsonFilePath, { headers: new HttpHeaders({ accept: "application/json" }), observe: "response" }).pipe(
			map((poResponse: HttpResponse<any>) => poResponse.status === 200),
			catchError(poError => of(poError.status === 200))
		).toPromise();
	}

	/** Retourne la dernière version du descripteur voulu en fonction de la priorisation.
	 * @param poConfigDescriptor Descripteur de la base de config.
	 * @param poWsDescriptor Descripteur de la base de workspace.
	 * @param poLocalFormDescriptor Descripteur des fichiers locaux.
	 */
	private getHighestPriorityFormDescriptor(poConfigDescriptor?: IFormDescriptor<T>, poWsDescriptor?: IFormDescriptor<T>, poLocalFormDescriptor?: IFormDescriptor<T>): IFormDescriptor<T> | undefined {
		console.debug(`FORM.S::ConfigDescriptor: "${poConfigDescriptor?._id}", WsDescriptor: "${poWsDescriptor?._id}, LocalFormDescriptor: "${poLocalFormDescriptor?._id}".`);

		if (poLocalFormDescriptor) {
			poLocalFormDescriptor._id = poLocalFormDescriptor.id = `${poLocalFormDescriptor._id}_v${Version.fromString(ConfigData.appInfo.appVersion).toFormattedString()}`;
			poLocalFormDescriptor.version = Version.fromString(ConfigData.appInfo.appVersion).toFormattedString();
		}

		const laDescriptorsByPriority: (IFormDescriptor<T> | undefined)[] = [poWsDescriptor, poConfigDescriptor, poLocalFormDescriptor]; // Tri des descripteurs en fonction de la priorisation.
		const laDefineDescriptors: IFormDescriptor<T>[] = laDescriptorsByPriority.filter((poDescripteur: IFormDescriptor<T>) => ObjectHelper.isDefined(poDescripteur));

		const laSortedDescriptors: IFormDescriptor<T>[] = laDefineDescriptors.sort((poDescriptorA: IFormDescriptor<T>, poDescriptorB: IFormDescriptor<T>) => {
			const lnVersionA: Version = Version.fromDescriptorId(poDescriptorA._id);
			const lnVersionB: Version = Version.fromDescriptorId(poDescriptorB._id);

			return lnVersionB.compareTo(lnVersionA);
		});

		return ArrayHelper.getFirstElement(laSortedDescriptors);
	}

	/** Enregistre dans la base de données le document.
	 * @param poDocument Document qu'il faut enregistrer sur la base de données.
	 * @param psDatabaseId Identifiant de la base de données où enregistrer le document.
	 */
	public postFormEntry(poDocument: IStoreDocument, psDatabaseId: string): Observable<IStoreDataResponse> {
		return this.isvcStore.put(poDocument, psDatabaseId)
			.pipe(catchError(poError => { console.error(`FORM.S:: Error put entry : `, poError); return throwError(() => poError); }));
	}

	/** Transforme les paramètres de la source de données en données tenant compte de l'environnement de l'application.
	 * @param poDataSource Source de données à transformer.
	 * ### Example
	 *
	 * ```javascript
	 * {
	 *  "id": "ngapActesDataSource",
	 *  "type": "couchdb",
	 *  "db": "ngap_core_common_forms_entries",
	 *  "view": "entry/by_profession?key={{app.profession}}"
	 * }
	 * //DEVIENT
	 * {
	 *  "id": "ngapActesDataSource",
	 *  "type": "couchdb",
	 *  "db": "ngap_core_common_forms_entries",
	 *  "view": "entry/by_profession?key=42"
	 * }
	 * ```
	 */
	private resolveDynDataSourceParams(poDataSource: IDataSource): IDataSource {
		const loResolvedDataSource: IDataSource = poDataSource;

		if (poDataSource && poDataSource.viewParams) {
			if (poDataSource.viewParams.key)
				poDataSource.viewParams.key = this.isvcPatternResolver.replaceDynParams(poDataSource.viewParams.key as string);

			if (typeof poDataSource.viewParams.startkey === "string")
				poDataSource.viewParams.startkey = this.isvcPatternResolver.replaceDynParams(poDataSource.viewParams.startkey);

			if (typeof poDataSource.viewParams.endkey === "string")
				poDataSource.viewParams.endkey = this.isvcPatternResolver.replaceDynParams(poDataSource.viewParams.endkey);
		}

		return loResolvedDataSource;
	}

	/** Enregistre le modèle sur la bdd.
	 * @param poModel Modèle à enregistrer.
	 * @param psDatabaseId Identifiant de la base de données où enregistrer le document.
	 * ### À renseigner uniquement lors d'une création.
	 */
	public saveModel(poModel: Entity, psDatabaseId?: string): Observable<IStoreDataResponse> {
		const lsDatabaseId: string | undefined = psDatabaseId ?? StoreHelper.getDatabaseIdFromCacheData(poModel, ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)));

		if (!lsDatabaseId) {
			console.error(`FORM.S:: Error when saving document ${poModel._id}, the database ID is missing.`);
			return throwError(() => new Error(`Database ID is missing.`));
		}

		return this.isvcStore.put(poModel, psDatabaseId)
			.pipe(catchError(poError => { console.error(`FORM.S:: Erreur lors du put du modèle : `, poError); return throwError(() => poError); }))
			.pipe(
				mergeMap((poStoreDataResponse: IStoreDataResponse) => {
					if (ConfigData.appInfo.useLinks)
						return this.isvcEntityLink.saveEntityLinks(poModel).pipe(mapTo(poStoreDataResponse));
					else
						return of(poStoreDataResponse);
				})
			);
	}

	/** Permet d'attendre l'arrivée de l'événement de formulaire voulu.
	 * @param psModelId Identifiant du modèle du formulaire.
	 * @param peFormEventType Type d'événement de formulaire.
	 */
	public waitFormEvent(psModelId: string, peFormEventType: EFormEventType): Observable<IFormEvent> {
		return this.isvcApplication.appEvent$
			.pipe(
				filter((poEvent: IApplicationEvent) => poEvent.type === EApplicationEventType.formEvent && (poEvent as IFormEvent).data.eventType === peFormEventType &&
					(poEvent as IFormEvent).data.model._id === psModelId)
			);
	}

	/** Permet d'attendre l'arrivée de l'événement avant soumission du formulaire.
	 * @param psModelId Identifiant du modèle du formulaire.
	 */
	public waitBeforeSubmitEvent(psModelId: string): Observable<IFormEvent> {
		return this.waitFormEvent(psModelId, EFormEventType.beforeSubmit);
	}

	/** Permet d'attendre l'arrivée de l'événement après soumission du formulaire.
	 * @param psModelId Identifiant du modèle du formulaire.
	 */
	public waitAfterSubmitEvent(psModelId: string): Observable<IFormEvent> {
		return this.waitFormEvent(psModelId, EFormEventType.afterSubmit);
	}

	/** Configure une source de données conformément aux exigences du descripteur en appliquant notamment les valeurs dynamiques.
	 * @param poDescriptorDataSource DataSource telle qu'elle est décrite dans le descripteur.
	 * @returns Une DataSource dans laquelle les templates sont remplacés par les valeurs évaluées dynamiquement.
	 */
	public prepareFormDataSource(poDescriptorDataSource?: IFormDescriptorDataSource): IDataSource {
		let loPreparedDataSource: IDataSource;

		if (poDescriptorDataSource) {
			loPreparedDataSource = {
				id: poDescriptorDataSource.id,
				type: poDescriptorDataSource.type,
				databaseId: poDescriptorDataSource.db, // Écart de nommage NoSQL/TypeScript.
				databasesIds: [],
				viewName: poDescriptorDataSource.view, // Écart de nommage NoSQL/TypeScript.
				viewParams: poDescriptorDataSource.viewParams,
				live: poDescriptorDataSource.live
			};

			if (poDescriptorDataSource.databases) // Écart de nommage NoSQL/TypeScript.
				loPreparedDataSource.databasesIds!.push(...poDescriptorDataSource.databases);

			if (poDescriptorDataSource.role)
				loPreparedDataSource.databasesIds!.push(...this.isvcStore.getDatabasesIdsByRole(poDescriptorDataSource.role));
		}
		else {
			loPreparedDataSource = {
				id: undefined,
				type: undefined,
				databaseId: undefined,
				databasesIds: [],
				viewName: undefined,
				viewParams: undefined
			};
		}

		return this.resolveDynDataSourceParams(loPreparedDataSource);
	}

	public getFormDescriptors(psDatabaseId: string): Observable<IFormDescriptor[]> {
		return this.isvcStore.get({
			databaseId: psDatabaseId,
			viewParams: {
				startkey: EPrefix.formDesc,
				endkey: EPrefix.formDesc + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			}
		} as IDataSource<IFormDescriptor>);
	}

	//#endregion
}