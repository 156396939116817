import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { ObserveArray } from '@calaosoft/osapp/modules/observable/decorators/observe-array.decorator';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp/modules/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { secure } from '@calaosoft/osapp/modules/utils/rxjs/operators/secure';
import { ItemSizeCalculator } from '@calaosoft/osapp/modules/virtual-scroll/models/item-size-calculator';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ETradeEventType } from '../../models/etrade-event-type';
import { IOccurrenceListItem } from '../../models/ioccurrence-list-item';
import { TradeEventsService } from '../../services/trade-events.service';

@Component({
	selector: 'trade-activities-list',
	templateUrl: './activities-list.component.html',
	styleUrls: ['./activities-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'",
		"[style.height]": "'100%'"
	}
})
export class ActivitiesListComponent extends DestroyableComponentBase {

	//#region FIELDS

	private readonly mdNow = new Date;

	//#endregion

	//#region PROPERTIES

	/** Items de la liste. */
	@Input() public items?: BaseEventOccurrence[];
	@ObserveArray<ActivitiesListComponent>("items")
	public readonly observableItems = new ObservableArray<BaseEventOccurrence>();

	/** Tableau des éléments de la liste. */
	@Input() public listItems?: IOccurrenceListItem[];
	@ObserveArray<ActivitiesListComponent>("listItems")
	public readonly observableListItems = new ObservableArray<IOccurrenceListItem>();

	public readonly items$: Observable<IOccurrenceListItem[]> =
		combineLatest([this.observableItems.changes$, this.observableListItems.changes$])
			.pipe(
				map(([paOccurrences, paListItems]: [BaseEventOccurrence[], IOccurrenceListItem[]]) => {
					if (ArrayHelper.hasElements(paOccurrences))
						return this.isvcTradeEvents.prepareOccurrenceListItems(paOccurrences);
					if (ArrayHelper.hasElements(paListItems))
						return paListItems;
					return [];
				}),
				secure(this)
			);

	/** Événement lors clic sur le bouton 'charger plus'. */
	@Output("onLoadMoreClicked") private readonly moLoadMoreClickedEvent = new EventEmitter<void>();

	public readonly tradeEventTypes = ETradeEventType;

	public readonly itemSizeCalculator = new ItemSizeCalculator<IOccurrenceListItem>((poItem: IOccurrenceListItem) => {
		if (poItem.dateLabel)
			return 22.4;
		else if (poItem.occurrence)
			return 100;
		else
			return 0;
	});

	/** Titre à afficher si pas d'item. */
	@Input() public blankInformationTitle?: string;
	@ObserveProperty<ActivitiesListComponent>({ sourcePropertyKey: "blankInformationTitle" })
	public readonly observableBlankInformationTitle = new ObservableProperty<string>("Aucune activité");

	/** Sous-titre à afficher si pas d'item. */
	@Input() public blankInformationSubtitle?: string;
	@ObserveProperty<ActivitiesListComponent>({ sourcePropertyKey: "blankInformationSubtitle" })
	public readonly observableBlankInformationSubtitle = new ObservableProperty<string>();

	/** Force l'utilisation du composant delegatedTaskItem si `true`. (le composant delegatedItem affiche les info de l'état de la tâche) */
	@Input() public useDelegatedItem?: boolean;
	@ObserveProperty<ActivitiesListComponent>({ sourcePropertyKey: "useDelegatedItem" })
	public readonly observableUseDelegatedItem = new ObservableProperty<boolean>();

	@Input() public virtualScrollStartTemplate?: TemplateRef<any>;

	/** `Masque le bloc composant blankInformation si `true`. Est à `false` par défaut. */
	@Input() public hideBlankInformation?: boolean;
	@ObserveProperty<ActivitiesListComponent>({ sourcePropertyKey: "hideBlankInformation" })
	public readonly observableHideBlankInformation = new ObservableProperty<boolean>(false);

	@Input() public hideLinkedBusiness?: boolean;
	@ObserveProperty<ActivitiesListComponent>({ sourcePropertyKey: "hideLinkedBusiness" })
	public readonly observableHideLinkedBusiness = new ObservableProperty<boolean>(false);

	//#endregion

	//#region METHODS

	constructor(private readonly isvcTradeEvents: TradeEventsService) {
		super();
	}

	public onLoadMoreClicked(): void {
		this.moLoadMoreClickedEvent.emit();
	}

	public isToday(pdDate: Date): boolean {
		return DateHelper.areDayEqual(pdDate, this.mdNow);
	}

	//#endregion

}