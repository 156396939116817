<ion-modal [isOpen]="observableIsModalOpen.value$ | async" (didDismiss)="close()"
	[class]="observableModalSize.value$ | async" data-cy="filter-bar-modal">
	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button color="dark" shape="round" (click)="close()">
						<ion-icon slot="icon-only" name="close-circle"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title>{{observableTitle.value$ | async}}</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content *ngIf="observableIsModalOpen.value$ | async" class="ion-padding">
			<ng-content></ng-content>
		</ion-content>
		<ion-footer>
			<ion-toolbar>
				<ion-button slot="start" color="medium" fill="clear" (click)="reset()" class="button-link"
					data-cy="filter-bar-modal-reset-filter-button">
					{{observableCancelText.value$ | async}}
				</ion-button>
				<ion-button slot="end" shape="round" [disabled]="!(observableCanValidate.value$ | async)"
					(click)="validate(observableFilterValues.value)"
					data-cy="filter-bar-validation-button">{{validateDisplayedText$ |
					async}}</ion-button>
			</ion-toolbar>
		</ion-footer>
	</ng-template>
</ion-modal>