import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IPopoverItemParams } from '@calaosoft/osapp/model/popover/IPopoverItemParams';
import { ILinkedEntityIcon } from '@calaosoft/osapp/modules/calendar-events/models/ilinked-entity-icon';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp/modules/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { Queue } from '@calaosoft/osapp/modules/utils/queue/decorators/queue.decorator';
import { secure } from '@calaosoft/osapp/modules/utils/rxjs/operators/secure';
import { PopoverService } from '@calaosoft/osapp/services/popover.service';
import { EMPTY, Observable, combineLatest, defer, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { C_COLOR_TASK_ITEM, C_PREFIX_BUSINESS, C_PREFIX_OPPORTUNITY } from '../../../../app/app.constants';
import { Business } from '../../../businesses/model/business';
import { ETaskStatus } from '../../models/etask-status';
import { TaskOccurrence } from '../../models/task-occurrence';
import { TradeEventsService } from '../../services/trade-events.service';

@Component({
	selector: 'trade-task-item',
	templateUrl: './task-item.component.html',
	styleUrls: ['./task-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskItemComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "TSK.ITM.C::";
	public readonly moObservablePopOverItemsParams = new ObservableArray<IPopoverItemParams>(this.getPopOverItemsParams());

	//#endregion

	//#region PROPERTIES

	public readonly taskItemColor = C_COLOR_TASK_ITEM;

	@Input() public taskOccurrence?: TaskOccurrence;
	@ObserveProperty<TaskItemComponent>({ sourcePropertyKey: "taskOccurrence" })
	public readonly observableTaskOccurrence = new ObservableProperty<TaskOccurrence>();

	@Input() public hideLinkedBusiness?: boolean;
	@ObserveProperty<TaskItemComponent>({ sourcePropertyKey: "hideLinkedBusiness" })
	public readonly observableHideLinkedBusiness = new ObservableProperty<boolean>(false);

	public readonly hasOptions$: Observable<boolean> = this.observableTaskOccurrence.value$.pipe(
		switchMap((poOccurrence?: TaskOccurrence) => {
			if (poOccurrence) {
				return combineLatest([poOccurrence?.canBeEdited$, poOccurrence.canBeEnded$]).pipe(
					map((paResults: boolean[]) => !poOccurrence.done && poOccurrence.canBeDeleted && paResults.some((pbResult: boolean) => pbResult))
				);
			}
			else
				return of(false);
		}),
		secure(this)
	);

	public readonly observablePlaceIconColor = new ObservableProperty<string>("var(--ion-color-warning)");

	public readonly linkedBusiness$: Observable<Business | undefined> = this.observableTaskOccurrence.value$.pipe(
		switchMap((poOccurrence?: TaskOccurrence) => this.isvcTradeEvents.getEventLinkedEntity$<Business>(C_PREFIX_BUSINESS, poOccurrence?.event)),
		secure(this)
	);

	public readonly linkedEntitiesIcons$: Observable<ILinkedEntityIcon[] | undefined> = this.observableTaskOccurrence.value$.pipe(
		switchMap((poOccurrence?: TaskOccurrence) => this.isvcTradeEvents.getEventLinkedEntitiesIcons$(C_PREFIX_OPPORTUNITY, poOccurrence?.event)),
		secure(this)
	);

	//#endregion

	//#region METHODS

	public constructor(
		protected readonly isvcTradeEvents: TradeEventsService,
		protected readonly isvcPopover: PopoverService
	) {
		super();
	}

	public ngOnInit(): void {
		this.observableTaskOccurrence.value$.pipe(
			tap((poTaskOccurrence: TaskOccurrence) => {
				const loValidationPopOverParams: IPopoverItemParams = {
					action: () => this.onFinishClicked$(),
					id: "validation",
					color: "success",
					icon: "checkmark",
					title: "Terminer"
				};
				const lbHasValidationPopOverParams: boolean = this.moObservablePopOverItemsParams
					.some((poPopOverParam: IPopoverItemParams) => poPopOverParam.id === loValidationPopOverParams.id);

				if (!lbHasValidationPopOverParams && poTaskOccurrence.status !== ETaskStatus.done)
					this.moObservablePopOverItemsParams.resetArray([...this.moObservablePopOverItemsParams, loValidationPopOverParams]);
			}),
			secure(this)
		).subscribe();
	}

	public onTaskClicked(): void {
		if (this.taskOccurrence)
			this.isvcTradeEvents.routeToViewEventAsync(this.taskOccurrence);
	}

	@Queue<TaskItemComponent, Parameters<TaskItemComponent["endTask$"]>, ReturnType<TaskItemComponent["endTask$"]>>({
		excludePendings: true
	})
	private endTask$(): Observable<void> {
		return defer(() => this.isvcTradeEvents.showEndtaskPopupAsync())
			.pipe(
				takeUntil(this.destroyed$),
				filter((poResponse: boolean) => !!poResponse),
				mergeMap(() => {
					if (this.taskOccurrence)
						return this.isvcTradeEvents.endOccurrenceAsync(this.taskOccurrence);
					else {
						console.warn(`${TaskItemComponent.C_LOG_ID}taskOccurrence is not defined !`);
						return EMPTY;
					}
				})
			);
	}

	@Queue<TaskItemComponent, Parameters<TaskItemComponent["deleteTask$"]>, ReturnType<TaskItemComponent["deleteTask$"]>>({
		excludePendings: true
	})
	private deleteTask$(): Observable<void> {
		if (this.taskOccurrence) {
			return defer(() => this.isvcTradeEvents.askForDeletetionAsync(this.taskOccurrence!.event))
				.pipe(
					takeUntil(this.destroyed$),
					filter((poResponse: boolean) => !!poResponse),
					mergeMap(() => this.isvcTradeEvents.deleteTaskAsync(this.taskOccurrence!.event))
				);
		}
		else {
			console.warn(`${TaskItemComponent.C_LOG_ID}taskOccurrence is not defined !`);
			return EMPTY;
		}
	}

	private navigateToEditAsync(poTaskOccurrence: TaskOccurrence): Promise<boolean> {
		return this.isvcTradeEvents.routeToEditTaskAsync(poTaskOccurrence);
	}

	public async presentPopOverAsync(poEvent: MouseEvent): Promise<void> {
		this.presentPopOver$(poEvent).subscribe();
	}

	@Queue<TaskItemComponent, Parameters<TaskItemComponent["presentPopOver$"]>, ReturnType<TaskItemComponent["presentPopOver$"]>>({
		excludePendings: true
	})
	private presentPopOver$(poEvent: MouseEvent): Observable<HTMLIonPopoverElement> {
		return defer(() => this.isvcPopover.showPopoverAsync(this.moObservablePopOverItemsParams, poEvent));
	}

	private getPopOverItemsParams(): IPopoverItemParams[] {
		return [
			{
				action: () => this.navigateToEdit$(),
				id: "creation",
				color: "primary",
				icon: "create",
				title: "Éditer"
			},
			{
				action: () => this.onDeleteClicked$(),
				id: "deletion",
				color: "danger",
				icon: "trash",
				title: "Supprimer définitivement"
			}
		];
	}

	private navigateToEdit$(): Observable<boolean> {
		return this.observableTaskOccurrence.value ? defer(() => this.navigateToEditAsync(this.observableTaskOccurrence.value)) : of(false);
	}

	private onDeleteClicked$(): Observable<void> {
		return this.observableTaskOccurrence.value ? this.deleteTask$() : of();
	}

	private onFinishClicked$(): Observable<void> {
		return this.observableTaskOccurrence.value ? this.endTask$() : of();
	}

	//#endregion

}