import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ConversationResolver } from '@calaosoft/osapp/components/conversation/conversation.resolver';
import { DynamicPageComponent } from '@calaosoft/osapp/components/dynamicPage/dynamicPage.component';
import { AuthenticatedGuard } from '@calaosoft/osapp/guards/authenticated.guard';
import { CanDeactivateGuard } from '@calaosoft/osapp/guards/canDeactivate.guard';
import { GuestGuard } from '@calaosoft/osapp/guards/guest.guard';
import { PageInfo } from '@calaosoft/osapp/model/PageInfo';
import { HasPermissionGuard } from '@calaosoft/osapp/modules/permissions/guards/has-permission.guard';
import { EPermissionScopes } from '@calaosoft/osapp/modules/permissions/models/epermission-scopes';
import { IPermission } from '@calaosoft/osapp/modules/permissions/models/ipermission';
import { C_CONVERSATIONS_BASE_ROUTE, C_SMALL_SCREEN_DISPLAY_MAX_WIDTH } from '../../app.constants';

const C_CONVERSATIONS_EDIT_PERMISSIONS: IPermission[] = [{ permission: EPermissionScopes.conversations, type: "edit" }];
const CONVERSATION_PAGE_INFO = new PageInfo({
	hasConversationsButton: true,
	conversationsButtonDisappearAtWidthPx: C_SMALL_SCREEN_DISPLAY_MAX_WIDTH,
	menuButtonDisappearAtWidthPx: C_SMALL_SCREEN_DISPLAY_MAX_WIDTH,
	defaultHref: "conversations",
	title: "Conversation",
	componentName: "conversation",
	params: {
		galleryCameraButtonVisible: false,
		galleryFilePickerVisible: false,
		galleryAcceptFiles: "image"
	},
	hasMenuButton: true,
	hasSettingButton: false,
	hasSyncButton: true,
	hasHomeButton: false,
	tabBarStyle: true
});

const CONVERSATION_EDIT_PAGE_INFO = new PageInfo({
	hasConversationsButton: true,
	conversationsButtonDisappearAtWidthPx: C_SMALL_SCREEN_DISPLAY_MAX_WIDTH,
	menuButtonDisappearAtWidthPx: C_SMALL_SCREEN_DISPLAY_MAX_WIDTH,
	defaultHref: "conversations",
	title: "Conversation",
	componentName: "conversationEdit",
	hasMenuButton: true,
	hasSyncButton: true,
	hasHomeButton: false,
	tabBarStyle: true
});

const route: Route = {
	path: C_CONVERSATIONS_BASE_ROUTE,
	children: [
		{
			path: ':conversationId',
			component: DynamicPageComponent,
			data: {
				pageInfo: CONVERSATION_PAGE_INFO
			},
			resolve: { conversation: ConversationResolver },
			canActivate: [GuestGuard],
			canDeactivate: [CanDeactivateGuard]
		},
		{
			path: ':conversationId/edit',
			component: DynamicPageComponent,
			data: {
				pageInfo: CONVERSATION_EDIT_PAGE_INFO,
				permissions: C_CONVERSATIONS_EDIT_PERMISSIONS
			},
			resolve: { conversation: ConversationResolver },
			canActivate: [AuthenticatedGuard, HasPermissionGuard],
			canDeactivate: [CanDeactivateGuard]
		},
	]
};

@NgModule({
	imports: [RouterModule.forChild([route])],
	exports: [RouterModule]
})
export class ConversationsSharedRoutingModule { }

export { route as conversationsSharedRoute };
