import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IGroup } from '@calaosoft/osapp/model/contacts/IGroup';
import { EConversationStatus } from '@calaosoft/osapp/model/conversation/EConversationStatus';
import { IConversationListParams } from '@calaosoft/osapp/model/conversation/IConversationListParams';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { C_SECTORS_ROLE_ID } from '@calaosoft/osapp/modules/permissions/services/permissions.service';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { GroupsService } from '@calaosoft/osapp/services/groups.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ITradeConversationsListParams } from '../../model/ITradeConversationsListParams';

@Component({
	selector: 'trade-conversations-list',
	templateUrl: './tradeConversationsList.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.height]": "'100%'"
	}
})
export class TradeConversationsListComponent extends DestroyableComponentBase implements OnInit {

	//#region PROPERTIES

	/** Paramètres pour le composant. */
	@Input() public params?: ITradeConversationsListParams;
	@ObserveProperty<TradeConversationsListComponent>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<ITradeConversationsListParams>();

	@Input() public conversationsStatusFilter: EConversationStatus;

	@Input() public hideFabButton?: boolean | string;
	@ObserveProperty<TradeConversationsListComponent>({
		sourcePropertyKey: "hideFabButton",
		transformer: (poNewVal: any) => coerceBooleanProperty(poNewVal)
	})
	public readonly observableHideFabButton = new ObservableProperty<boolean>();

	/** Paramètres pour le composant osapp `conversationList`. */
	public readonly observableConvListParams = new ObservableProperty<IConversationListParams>();

	//#endregion

	//#region METHODS

	constructor(private readonly isvcGroups: GroupsService) {
		super();
	}

	public ngOnInit(): void {
		this.isvcGroups.getGroupsByRoles([C_SECTORS_ROLE_ID], true)
			.pipe(
				switchMap((paSectors: IGroup[]) => {
					return this.observableParams.value$.pipe(
						tap((poParams?: ITradeConversationsListParams) => {
							if (poParams) {
								this.observableConvListParams.value = {
									galleryAcceptFiles: poParams.galleryAcceptFiles !== undefined ? poParams.galleryAcceptFiles : "",
									linkedEntities: poParams.linkedEntities !== undefined ? poParams.linkedEntities : poParams.model ? [poParams.model] : undefined,
									swipeDisabled: poParams.swipeDisabled !== undefined ? poParams.swipeDisabled : true,
									contactSelectorParams: {
										groupFilterParams: {
											options: paSectors.map((poSector: IGroup) => ({ label: poSector.name, value: poSector }))
										}
									},
									filterConversationsByWorkspace: poParams.filterConversationsByWorkspace
								};
							}
						})
					)
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	//#endregion

}