import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { CanEditGroupGuard } from '@calaosoft/osapp/components/contacts/can-edit-group.guard';
import { GoogleMapComponent } from '@calaosoft/osapp/components/geolocation/googleMap.component';
import { AuthenticatedGuard } from '@calaosoft/osapp/guards/authenticated.guard';
import { PageInfo } from '@calaosoft/osapp/model/PageInfo';
import { HasPermissionGuard } from '@calaosoft/osapp/modules/permissions/guards/has-permission.guard';
import { EPermissionScopes } from '@calaosoft/osapp/modules/permissions/models/epermission-scopes';
import { IPermission } from '@calaosoft/osapp/modules/permissions/models/ipermission';
import { C_CONTACTS_BASE_ROUTE, C_SMALL_SCREEN_DISPLAY_MAX_WIDTH } from '../../app.constants';
import { CanEditContactGuard } from './guards/can-edit-contact.guard';

const C_CONTACTS_CREATE_PERMISSIONS: IPermission[] = [{ permission: EPermissionScopes.contacts, type: "create" }];
const C_CONTACTS_READ_PERMISSIONS: IPermission[] = [{ permission: EPermissionScopes.contacts, type: "read" }];
const C_GROUPS_READ_PERMISSIONS: IPermission[] = [
	{ permission: EPermissionScopes.contacts, type: "read" }, { permission: EPermissionScopes.groups, type: "read" }
];
const C_GROUPS_CREATE_PERMISSIONS: IPermission[] = [
	{ permission: EPermissionScopes.contacts, type: "create" }, { permission: EPermissionScopes.groups, type: "create" }
];

const GOOGLE_MAP_PAGE_INFO = new PageInfo({
	menuButtonDisappearAtWidthPx: C_SMALL_SCREEN_DISPLAY_MAX_WIDTH,
	hasMenuButton: true,
	hasHomeButton: false,
	hasSettingButton: false,
	hasBackButton: true,
	hasSyncButton: true,
	hasConversationsButton: true,
	hasNotificationsButton: true,
	componentName: "",
	tabBarStyle: true
});

const route: Route = {
	path: C_CONTACTS_BASE_ROUTE,
	children: [
		{
			path: 'new',
			loadChildren: () => import('./contact-edit/contact-edit.module').then(m => m.ContactEditPageModule),
			data: {
				permissions: C_CONTACTS_CREATE_PERMISSIONS,
			},
			canActivate: [HasPermissionGuard]
		},
		{
			path: ':contactId',
			loadChildren: () => import('./contact/contact.module').then(m => m.ContactPageModule),
			data: {
				permissions: C_CONTACTS_READ_PERMISSIONS
			},
			canActivate: [HasPermissionGuard],
		},
		{
			path: ':contactId/edit',
			loadChildren: () => import('./contact-edit/contact-edit.module').then(m => m.ContactEditPageModule),
			canActivate: [CanEditContactGuard]
		},
		{
			path: ':contactId/googleMap',
			component: GoogleMapComponent,
			data: {
				pageInfo: GOOGLE_MAP_PAGE_INFO
			}
		},
		{
			path: 'groupes/new',
			loadChildren: () => import('./group-edit/group-edit.module').then(m => m.GroupEditPageModule),
			data: {
				permissions: C_GROUPS_CREATE_PERMISSIONS
			},
			canActivate: [HasPermissionGuard],
		},
		{
			path: 'groupes/:groupId',
			loadChildren: () => import('./group/group.module').then(m => m.GroupPageModule),
			data: {
				permissions: C_GROUPS_READ_PERMISSIONS
			},
			canActivate: [HasPermissionGuard],
		},
		{
			path: 'groupes/:groupId/edit',
			loadChildren: () => import('./group-edit/group-edit.module').then(m => m.GroupEditPageModule),
			canActivate: [CanEditGroupGuard],
		}
	],
	canActivate: [AuthenticatedGuard]
};

@NgModule({
	imports: [RouterModule.forChild([route])],
	exports: [RouterModule],
})
export class ContactsSharedRoutingModule { }

export { route as contactsSharedRoute };
