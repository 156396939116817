import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { IonicModule } from '@ionic/angular';
import { CalaoAvatarModule } from '../../modules/avatar/calao-avatar.module';
import { BrowserModule } from '../../modules/browser/browser.module';
import { GesturesModule } from '../../modules/gestures/gestures.module';
import { LiveModule } from "../../modules/live/live.module";
import { ModalModule } from "../../modules/modal/modal.module";
import { RoutingModule } from '../../modules/routing/routing.module';
import { SpinnerModule } from '../../modules/spinner/spinner.module';
import { TabsModule } from '../../modules/tabs/tabs.module';
import { VirtualScrollModule } from '../../modules/virtual-scroll/virtual-scroll.module';
import { PipeModule } from '../../pipes/pipes.module';
import { ContactsService } from '../../services/contacts.service';
import { GalleryModule } from '../gallery/gallery.module';
import { SearchModule } from '../search/search.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { AiConversationSummaryModalComponent } from "./aiConversationSummaryModal/ai-conversation-summary-modal.component";
import { AttachmentsPopoverComponent } from './attachmentsPopover/attachmentsPopover.component';
import { ConversationComponent } from './conversation.component';
import { ConversationTypeToggleComponent } from './conversationTypeToggle/conversation-type-toggle.component';
import { ConversationEditComponent } from './edit/conversationEdit.component';
import { CanLeaveConversationEdit } from './edit/guards/canLeaveConversationEdit.guard';
import { LinkifyDirective } from './linkify/linkify.directive';
import { RedirectLinksDirective } from './linkify/redirectLinks.directive';
import { ConversationsListComponent } from './list/conversations-list.component';
import { ConversationTabComponent } from './tab/conversation-tab.component';
import { MailboxModalComponent } from './mailboxModal/mailbox-modal.component';

const modules: (Type<any> | ModuleWithProviders<any>)[] = [
	IonicModule,
	CommonModule,
	ToolbarModule,
	PipeModule,
	CalaoAvatarModule,
	GalleryModule,
	FormsModule,
	SearchModule,
	MatBadgeModule,
	GesturesModule.forRoot({ longPress: { time: 1000 } }),
	SpinnerModule,
	TabsModule,
	VirtualScrollModule,
	LiveModule,
	ModalModule,
	RoutingModule.forRoot({ defaultRoute: "conversations" }),
	BrowserModule
];
const components: Type<any>[] = [
	ConversationComponent,
	ConversationsListComponent,
	ConversationEditComponent,
	ConversationTabComponent,
	AttachmentsPopoverComponent,
	AiConversationSummaryModalComponent,
	MailboxModalComponent,
	ConversationTypeToggleComponent,
	LinkifyDirective,
	RedirectLinksDirective
];
const providers: Provider[] = [ContactsService, CanLeaveConversationEdit];

/** Module de gestion des conversations. */
@NgModule({
	imports: modules,
	exports: components,
	declarations: components,
	providers: providers,
})
export class ConversationModule { }