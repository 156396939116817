<div class="marg-browser wrapper">
	<div class="header">
		<search class="flx-grow1" [data]="conversations" [options]="searchOptions"
			(filteredEntriesChanged)="onFilteredConversationsChanged($event)" [(searchValue)]="searchValue"></search>
		<div class="advanced-options" *ngIf="(observableAdvancedOptions.length$| async) !== 0">
			<ion-icon size="large" name="ellipsis-vertical" (click)="presentAdvancedOptions($event)"></ion-icon>
		</div>
	</div>

	<div *ngIf="conversationTypeEnabled.length !== 1">
		<calao-conversation-sub-type-toggle *ngFor="let conversationType of conversationTypeEnabled"
			[conversationType]="conversationType" [isFill]="isConversationTypeFiltered(conversationType)"
			(click)="switchConversationTypeEnabled(conversationType)">
		</calao-conversation-sub-type-toggle>
	</div>

	<div class="synchro-container">
		<ng-container *ngIf="isDownloading">
			<ion-note class="synchro-label">Synchronisation des données</ion-note>
			<ion-spinner color="medium"></ion-spinner>
		</ng-container>
		<ng-container *ngIf="!isDownloading && filteredConversations.length === 0">
			<ion-note class="synchro-label">Aucune conversation.</ion-note>
		</ng-container>
	</div>

	<div *ngIf="!isDownloading && filteredConversations.length > 0" class="marg-container container">
		<ng-container *ngIf="orderConversationsByDate(filteredConversations) as conversations">
			<calao-virtual-scroll autosize margeLastItem [itemSize]="80" [items]="conversations">
				<ng-template let-conversation="$implicit">
					<ion-item-sliding #itemSliding [disabled]="params.swipeDisabled"
						[ngClass]="getConversationClass(conversation)">
						<ion-item lines="full" [ngClass]="isActiveConversation(conversation) ? '' : 'archived'">
							<div *ngIf="!isRead(conversation)" class="ctn-marker">
								<div class="marker"></div>
							</div>
							<div *ngIf="!isActiveConversation(conversation)" class="ctn-archive">
								<div class="archive-icon flex-justify-center flex-col">
									<ion-icon name="archive"></ion-icon>
								</div>
							</div>
							<!-- Gestion de l'avatar de la conversation. -->
							<ion-avatar (click)="onAvatarClicked(conversation, $event)" class="roundavatar">
								<ng-container [ngSwitch]="conversation.conversationAvatar">
									<ion-icon *ngSwitchCase="'contacts'" name="people" class="centeravatar"></ion-icon>
									<ion-icon *ngSwitchCase="'groups'" name="group" class="centeravatar"></ion-icon>
									<avatar *ngSwitchDefault [src]="conversation.conversationAvatar" cssClass="round">
									</avatar>
								</ng-container>
							</ion-avatar>

							<div class="flx-grow1 dis-flex flex-row div-content" (click)="onItemClicked(conversation)" item-content>
								<div class="flex-col title conv-list">
									<ion-label *ngIf="conversation?.title" class="conversation-title">{{ conversation.title }}
									</ion-label>
									<ion-label class="noms-participants">
										{{ conversation ? conversation.subtitle : "" }}
									</ion-label>
									<div class="flx-grow1 dis-flex flex-row last-message-area" *ngIf="conversation?.lastMessage">
										<avatar
											*ngIf="conversation.lastMessageAvatar && conversation.lastMessage.senderContactPath !== userContactPath"
											[src]="conversation.lastMessageAvatar" class="last-message-avatar" cssClass="round">
										</avatar>
										<ion-note
											[ngClass]="conversation.lastMessageAvatar && conversation.lastMessage.senderContactPath !== userContactPath ? 'last-msg-body' : ''">
											{{ conversation.lastMessage.deleted ? deletedMessageBody : conversation.lastMessage.body }}
										</ion-note>
									</div>
								</div>
							</div>

							<div class="row-center flex-no-wrap" slot="end">
								<div class="container-conversation-sub-type">
									<ion-icon *ngIf="conversationTypeEnabled.length !== 1" class="icon-conversation-sub-type"
										[name]="getLogoByConversationType(conversation)"></ion-icon>
									<ion-note class="txt10px" *ngIf="conversation.lastMessage || conversation.createDate">
										{{ (conversation.lastMessage ? conversation.lastMessage.createDate : conversation.createDate) |
										adaptiveDate
										}}
									</ion-note>
								</div>
								<!-- Bouton d'options. -->
								<ion-button *ngIf="hasOptionButtons(conversation)" class="btn-no-margin btn-more" fill="clear"
									slot="end" (click)="openItemSlidingOptions(itemSliding)">
									<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
								</ion-button>
							</div>
						</ion-item>

						<!-- Zone des boutons cachée derrière le slide de l'item. -->
						<ion-item-options side="end" (ionSwipe)="onSwipingEvent(itemSliding)">
							<ion-button *ngIf="canDelete" class="btn-supp" color="danger"
								(click)="onConversationDelete(conversation)">
								<ion-icon name="trash"></ion-icon>
								Supprimer
							</ion-button>
							<ion-button *ngIf="canArchive(conversation)" class="btn-supp" color="medium"
								(click)="onConversationArchiveClicked(conversation)">
								<ion-icon name="archive"></ion-icon>
								Archiver
							</ion-button>
							<ion-button *ngIf="canRestore(conversation)" class="btn-supp" color="success"
								(click)="onConversationRestoreClicked(conversation)">
								<ion-icon name="refresh"></ion-icon>
								Restaurer
							</ion-button>
						</ion-item-options>
					</ion-item-sliding>
				</ng-template>
			</calao-virtual-scroll>
		</ng-container>
	</div>
</div>

<ion-fab *ngIf="!hideFabButton" vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button size="large" color="primary" (click)="onCreateConversationClicked()">
		<ion-icon name="add"></ion-icon>
	</ion-fab-button>
</ion-fab>