import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { IFormParams } from '../../../../model/forms/IFormParams';
import { FormsService } from '../../../../services/forms.service';

/** Champs contenant un formulaire. */
@Component({
	selector: 'deprecated-calao-form-field',
	templateUrl: './formField.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldComponent extends FieldBase implements OnInit {

	//#region PROPERTIES

	public params: IFormParams;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;
	}

	//#endregion
}